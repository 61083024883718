import { useState, useEffect } from "react";
import { Box, Button, Stack } from "@mui/material";
import { Formik, FastField, Form } from "formik";

import CircularProgress from "@mui/material/CircularProgress";

import { ModalNoAction } from "src/components/common";
import { TitleText } from "src/libs/AppStyle";
import { getListTeamByPage } from "src/libs/axios/api/team";
import { Input, Select } from "src/components/common";
import { IProject } from "src/libs/models/project";
import { createNewProjectByTeamSlug } from "src/libs/axios/api/project";

interface AddNewProjectProps {
  open: boolean;
  setOpen: Function;
  newTeamId: number;
  refreshGetListGroupProject?: Function;
}

const AddNewProject: React.FC<AddNewProjectProps> = ({ open, setOpen, newTeamId, refreshGetListGroupProject }) => {
  // state
  const [teams, setTeams] = useState([]);
  const [data, setData] = useState({
    id: 0,
    name: "",
    background_description: "",
    objective: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // function
  const getListTeam = async () => {
    const res = await getListTeamByPage();
    if (res?.results) {
      const newRes = res.results.map((r) => ({
        id: r.id,
        name: r.name,
      }));
      setTeams(newRes);
    }
  };

  const createNewProject = async (payload: IProject) => {
    setIsLoading(true);
    const res = await createNewProjectByTeamSlug(payload, newTeamId);
    setIsLoading(false);
    if (res) {
      setOpen(false);

      if (refreshGetListGroupProject) {
        refreshGetListGroupProject(res?.id, "new_project");
      }
    }
  };

  useEffect(() => {
    if (!open) return;
    getListTeam();
  }, [open]);

  useEffect(() => {
    if (teams?.length > 0) {
      const currentProject = localStorage.getItem("current_project");
      if (newTeamId) {
        setData((prev) => ({
          ...prev,
          id: newTeamId,
        }));
      } else if (currentProject) {
        const project = JSON.parse(currentProject);
        setData((prev) => ({
          ...prev,
          id: project?.teamId,
        }));
      }
    }
  }, [teams]);

  return (
    <ModalNoAction
      open={open}
      setOpen={setOpen}
    >
      <Box
        component="div"
        sx={{
          minWidth: "500px",
        }}
      >
        <TitleText
          variant="h3"
          sx={{
            marginBottom: "20px",
          }}
        >
          Add new project
        </TitleText>
        <Formik
          initialValues={data}
          onSubmit={(values) => {
            createNewProject({
              id: 0,
              ...values,
              team: data.id,
            });
          }}
          enableReinitialize
        >
          {() => {
            return (
              <Form>
                <Stack
                  component="div"
                  sx={{ mt: 1 }}
                  gap={3}
                >
                  <FastField
                    name="id"
                    component={Select}
                    options={teams}
                    label="Team"
                  />
                  <FastField
                    name="name"
                    component={Input}
                    type="text"
                    placeHolder="Name *"
                    isFocus={true}
                  />
                  <FastField
                    name="background_description"
                    component={Input}
                    type="text"
                    placeHolder="The problem the project will address"
                    isMultiple={true}
                    row={3}
                  />
                  <FastField
                    name="objective"
                    component={Input}
                    type="text"
                    placeHolder="Objectives to be achieved by end of the project"
                    isMultiple={true}
                    row={3}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mb: 1, textTransform: "capitalize" }}
                    disabled={isLoading}
                    startIcon={
                      !isLoading ? (
                        ""
                      ) : (
                        <CircularProgress
                          color="inherit"
                          size="15px"
                        />
                      )
                    }
                  >
                    Save
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ModalNoAction>
  );
};

export default AddNewProject;
