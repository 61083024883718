import * as React from "react";

const LinkedinCallback = () => {
  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    localStorage.setItem('linkedin_login_code', code)
    window.close()
  }, [])

  return <></>
}

export default LinkedinCallback