import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import PlanCard from "src/components/Subscription/PlanCard";
import { ModalNoAction, SelectBase } from "src/components/common";
import { TitleText } from "src/libs/AppStyle";
import { getPaymentByTeam } from "src/libs/axios/api/payment";
import { getSubscriptionPlans, getTeamSubscription } from "src/libs/axios/api/subscription";
import { getListTeamByPage } from "src/libs/axios/api/team";
import { IPlan } from "src/libs/models/subscription";
import { ITeam } from "src/libs/models/team";

interface UpgradePlansModalProps {
  open: boolean;
  setOpen: Function;
  teamId: string;
  setTeamId: Function;
  setPlanId: Function;
  setOpenPaymentModal: Function;
  setOpenSubscribeModal: Function;
}

const UpgradePlansModal: React.FC<UpgradePlansModalProps> = ({
  open,
  setOpen,
  teamId,
  setTeamId,
  setPlanId,
  setOpenPaymentModal,
  setOpenSubscribeModal,
}) => {
  // state
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [payment, setPayment] = useState(null);
  const [teamSubscription, setTeamSubscription] = useState<any>(null);

  // function
  const getAllSubscriptionPlans = async () => {
    const res = await getSubscriptionPlans();
    setPlans(res);
  };

  const handleSubscribe = (planId, type) => {
    if (type === "enterprise") {
      window.open("https://neuralpit.com/contact-us/", "_blank").focus();
      return;
    }
    setPlanId(planId);
    if (payment) {
      setOpenSubscribeModal(true);
      setOpenPaymentModal(false);
    } else {
      setOpenSubscribeModal(false);
      setOpenPaymentModal(true);
    }
  };

  const getTeamPayment = async () => {
    const res: any = await getPaymentByTeam(teamId);
    setPayment(res);
  };

  const getSubscription = async () => {
    const res: Object = await getTeamSubscription(teamId);
    setTeamSubscription(res);
  };

  const getTeams = async () => {
    const res = await getListTeamByPage();
    if (res?.results?.length > 0) {
      setTeams(res.results);
    }
  };

  // useEffect
  useEffect(() => {
    if (!teamId || !open) return;
    getAllSubscriptionPlans();
    getSubscription();
    getTeamPayment();
    getTeams();
  }, [teamId, open]);

  return (
    <ModalNoAction
      open={open}
      setOpen={setOpen}
      cssString={{
        zIndex: 1000,
      }}
    >
      <Box component="div">
        <TitleText
          variant="h3"
          sx={{
            marginBottom: "20px",
          }}
        >
          Upgrade plans (price per team)
        </TitleText>
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            maxWidth: "350px",
            "& .MuiSelect-select": {
              padding: "0 14px",
              height: "47px !important",
              lineHeight: "47px",
              fontSize: "14px",
            },
          }}
        >
          <TitleText
            variant="h3"
            sx={{
              lineHeight: "47px !important",
            }}
          >
            Team:
          </TitleText>
          <SelectBase
            data={teams}
            value={teamId}
            id="select-team"
            labelId="select-team-modal"
            label="Select team"
            setValue={setTeamId}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            gap: 1,
          }}
        >
          {plans
            .filter((plan) => plan.id > 1)
            .map((plan) => (
              <Box
                key={plan.id}
                className="default-boxshadow"
              >
                <PlanCard
                  teamPlanId={teamSubscription ? teamSubscription.subscription_plan.id : 0}
                  plan={plan}
                  handleSubscribe={handleSubscribe}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </ModalNoAction>
  );
};

export default UpgradePlansModal;
