import { Scrollbars } from "react-custom-scrollbars-2";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState, useEffect, useRef, useMemo } from "react";
import { Box, Tooltip, Stack, Button, TextField } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

import { BodyText } from "src/libs/AppStyle";
import { ISession } from "src/libs/models/chat";
import { SelectModel } from "src/libs/models";
import { EVENTS_SESSION } from "src/data";
import { AddPlusSessionIcon } from "src/assest/icons";
import { createNewChat, getListChats } from "src/libs/axios/api/chat";
import { renderCurrentURLBusinessByToolURL } from "src/libs/hooks";

import EditSession from "./EditSession";
import DeleteSession from "./DeleteSession";
import UpdateInSession from "./UpdateInSession";

interface ListSessionProps {
  currentProject: SelectModel | null;
  currentSession: ISession | null;
  setCurrentSession: React.Dispatch<React.SetStateAction<ISession | null>>;
}

const ListSession: React.FC<ListSessionProps> = ({ currentProject, currentSession, setCurrentSession }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const sessionWS = useRef<WebSocket | null>(null);
  const scrollbarsRef = useRef<Scrollbars>(null);

  // state
  const [value, setValue] = useState("");
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const getListSession = async () => {
    const res = await getListChats(1, currentProject?.teamId, currentProject?.projectId);
    if (res?.length >= 0) {
      setSessions(res);

      const usingSession = res?.find(
        (s) =>
          s?.query_document_external_id === id ||
          s?.query_2_web_external_id === id ||
          s?.visualization_external_id === id ||
          s?.flowchart_external_id === id ||
          s?.generate_idea_external_id === id,
      );

      if (usingSession) {
        setCurrentSession({ ...usingSession });
      } else {
        setCurrentSession(null);
      }
    } else {
      setSessions([]);
    }
  };

  const handleChangeSession = (value: any) => {
    setCurrentSession((prev) => ({
      ...(prev ? prev : {}),
      ...value,
    }));

    const URL = renderCurrentURLBusinessByToolURL(location.pathname, value);
    navigate(URL);
  };

  const handleAddNewSession = async () => {
    setIsLoading(true);
    const res = await createNewChat(currentProject?.teamId, currentProject?.projectId, {
      id: 0,
      name: "New session",
      topic: "General",
      objective: "",
      content: "",
    });
    setIsLoading(false);
    if (res) {
      const URL = renderCurrentURLBusinessByToolURL(location.pathname, res);

      navigate(URL);
    }
  };

  const connectToSessionSocket = () => {
    sessionWS.current = new WebSocket(
      `wss://${process.env.REACT_APP_BASE_DOMAIN_BE}/ws/chat/team/${currentProject?.teamId}/project/${currentProject?.projectId}/assistant/0/`,
    );

    sessionWS.current.onopen = () => {
      console.info("connected");
    };

    sessionWS.current.onmessage = (e) => {
      try {
        const dataWS = JSON.parse(e.data);
        if (EVENTS_SESSION.includes(dataWS?.message?.type)) {
          getListSession();
        }
      } catch (error) {
        console.error(error);
      }
    };
  };

  // useEffect
  useEffect(() => {
    let isMounted = true;
    if (currentProject?.id && isMounted) {
      connectToSessionSocket();
      getListSession();
    }

    return () => {
      isMounted = false;
      sessionWS.current?.close();
    };
  }, [currentProject?.id, id]);

  // memo
  const listSessionFilter = useMemo(() => {
    if (value) {
      return sessions.filter((s) => s.name?.toLowerCase().includes(value.toLowerCase()));
    }
    return sessions;
  }, [value, sessions]);

  return (
    <Box
      component="div"
      p="10px 5px"
      sx={{
        height: "calc(100vh - 150px)",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{
          gap: "8px",
          flexShrink: 0,
          marginBottom: 1,
        }}
        flexShrink={0}
      >
        <Tooltip title="Add new session">
          <Button
            sx={{
              padding: 0,
              minWidth: "auto",
              color: "#737373",
              "&:hover": {
                color: "#1672c9",
              },
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? 0.5 : 1,
              transform: "translateX(-2px)",
            }}
            onClick={() => {
              handleAddNewSession();
            }}
          >
            <AddPlusSessionIcon />
          </Button>
        </Tooltip>
        <BodyText
          variant="h3"
          sx={{
            fontWeight: "700 !important",
            marginRight: 1,
            cursor: isLoading ? "not-allowed" : "default",
            opacity: isLoading ? 0.5 : 1,
          }}
        >
          New session
        </BodyText>
        {isLoading && (
          <CircularProgress
            color="inherit"
            size="15px"
          />
        )}
      </Stack>
      {sessions?.length >= 20 && (
        <Box
          flexShrink={0}
          marginY={1}
        >
          <TextField
            id="search-session"
            value={value}
            label="Search previous session"
            type="text"
            fullWidth
            margin="normal"
            sx={{
              marginBottom: "0",
              marginTop: "0",
              "& .MuiInputBase-input": {
                padding: "10px 14px",
                fontSize: "14px",
              },
              "& label": {
                fontSize: "12px",
                top: "-5px",
                fontStyle: "italic",
              },
              "& label.Mui-focused": {
                lineHeight: "2.4375em",
                left: "5px",
              },
              "& label.MuiInputLabel-shrink": {
                lineHeight: "2.4375em",
              },
              backgroundColor: "white",
            }}
            name="search-session"
            aria-invalid
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
          />
        </Box>
      )}
      <Box
        component="div"
        sx={{
          flexGrow: 1,
        }}
        className="bottom-shadow-header"
      >
        <Scrollbars
          ref={scrollbarsRef}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          {listSessionFilter?.map((s) => (
            <Box
              key={`${s?.id}-${s.query_document_external_id}`}
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                backgroundColor: `${s?.id === currentSession?.id ? "#d9d9d9" : ""}`,
                padding: "5px",
                borderRadius: "5px",
                transition: "all 0.2s",
                marginRight: "15px",
              }}
            >
              <Box
                component="div"
                sx={{
                  flexGrow: 1,
                  paddingY: "5px",
                }}
                onClick={() => handleChangeSession(s)}
              >
                <BodyText
                  variant="caption"
                  sx={{
                    fontWeight: `${s?.id === currentSession?.id ? "600 !important" : "400 !important"}`,
                    transition: "all 0.2s",
                  }}
                  className="line-clamp-1"
                >
                  {s?.name ? s.name : `New Session ${s?.id}`}
                </BodyText>
              </Box>
              <Stack>
                <Stack
                  flexDirection="row"
                  gap={1}
                >
                  <EditSession
                    sessionId={s?.id}
                    projectId={currentProject?.projectId}
                    teamId={currentProject?.teamId}
                    callback={getListSession}
                  />
                  <DeleteSession
                    sessionId={s?.id}
                    projectId={currentProject?.projectId}
                    teamId={currentProject?.teamId}
                    currentSession={currentSession}
                    setCurrentSession={setCurrentSession}
                    callback={getListSession}
                  />
                </Stack>
              </Stack>
            </Box>
          ))}
        </Scrollbars>
      </Box>
      <Box
        component="div"
        sx={{
          flexShrink: 0,
          paddingTop: "15px",
          marginTop: 0,
          height: "120px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <UpdateInSession currentProject={currentProject} />
      </Box>
    </Box>
  );
};

export default ListSession;
