import { createSlice } from "@reduxjs/toolkit"

import type { PayloadAction } from "@reduxjs/toolkit"

interface CurrentTeamState {
    teamId: number | null;
    projectId: number | null;
}

const initialState: CurrentTeamState = {
    teamId: null,
    projectId: null,
}

export const currentTeamSlice = createSlice({
    name: "current_team",
    initialState,
    reducers: {
        setCurrentTeam: (state, action: PayloadAction<CurrentTeamState>) => {
            state.teamId = action.payload.teamId
            state.projectId = action.payload.projectId
        },
    }
})

export const { setCurrentTeam } = currentTeamSlice.actions
export default currentTeamSlice.reducer