import { useState } from "react";
import { Button, Stack, Menu, MenuItem, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ProjectIcon } from "src/assest/icons";
import { BodyText } from "src/libs/AppStyle";
import { AddNewProject } from "src/components/Header";

interface ProjectItemProps {
  pathName: string;
  newTeamId: number;
  refreshGetListGroupProject?: Function;
}

const ProjectItem: React.FC<ProjectItemProps> = ({ pathName, newTeamId, refreshGetListGroupProject }) => {
  const navigate = useNavigate();

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newProjectModal, setNewProjectModal] = useState(false);

  // function
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, url: string) => {
    if (!pathName?.includes("workstation")) {
      navigate(url);
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefresh = (id: number, type: "new_team" | "new_project") => {
    if (refreshGetListGroupProject) {
      refreshGetListGroupProject(id, type);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="Create and manage team projects">
        <Button
          id="my-project"
          aria-controls={open ? "project-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => handleClick(e, "/project")}
          sx={{
            padding: 0,
            paddingX: "5px",
            color: `${pathName === "/project" ? "#1672c9" : "black"}`,
            "&:hover": {
              backgroundColor: "transparent",
              color: "#1672c9",
              "& svg": {
                color: "#1672c9",
              },
            },
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
          >
            <ProjectIcon className={pathName === "/project" ? "activate-svg" : "default-svg"} />
            <BodyText
              variant="caption"
              sx={{
                fontSize: "12px !important",
                textTransform: "capitalize",
                color: "inherit !important",
              }}
            >
              Projects
            </BodyText>
          </Stack>
        </Button>
      </Tooltip>
      <Menu
        id="project-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Button
            sx={{
              textTransform: "none",
              color: "black",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              setNewProjectModal(true);
            }}
          >
            Add project
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            sx={{
              textTransform: "none",
              color: "black",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              navigate("/project");
            }}
          >
            View projects
          </Button>
        </MenuItem>
      </Menu>
      <AddNewProject
        open={newProjectModal}
        setOpen={setNewProjectModal}
        newTeamId={newTeamId}
        refreshGetListGroupProject={handleRefresh}
      />
    </>
  );
};

export default ProjectItem;
