import { createSlice } from "@reduxjs/toolkit"

import type { PayloadAction } from "@reduxjs/toolkit"

interface ProjectState {
  projectId: number;
}

const initialState: ProjectState = {
  projectId: null,
}

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    addNewProjectId: (state, action: PayloadAction<number>) => {
      state.projectId = action.payload
    },
    removeProjectId: (state) => {
      state.projectId = null
    }
  }
})

export const { addNewProjectId, removeProjectId } = projectSlice.actions

export default projectSlice.reducer