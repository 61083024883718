import { Box, Typography } from "@mui/material";

const AnnualReports = () => {
  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      <Box
        component="div"
        sx={{}}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: 1,
          }}
        >
          Annual Report Analysis
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Use this tool to upload and analyze Annual Reports.
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Upload an Annual Report (in pdf, docx, docs or csv), and ask questions.
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Sample questions for analyzing Annual Reports (
          <a
            href="https://www.youtube.com/watch?v=0DUh6XLau9w"
            style={{ color: "#1672c9" }}
            target="_blank"
            rel="noreferrer"
          >
            See sample
          </a>
          )
        </Typography>

        <Box
          component="ul"
          mb={2}
          mt={1}
          padding={0}
          paddingLeft={2}
        >
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              How did the company perform in 2022 compared to previous years?
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              What risks is the company facing? How do they manage these risks?
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              What growth strategies does the company have?
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Analyze trends in the company's profitability ratio based on its revenue and profits in 2022 and previous
              years.
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Analyze trends in the company's Return on Equity (ROE) based on its Net Profit After Tax and Total Equity
              in 2022 and previous years.
            </Typography>
          </Box>
        </Box>

        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Apply a similar questions for Return on Invested Capital (ROIC), Return on Assets (ROA), Liquidity Ratio, et
        </Typography>
      </Box>
    </Box>
  );
};

export default AnnualReports;
