import { useRef, useEffect } from "react";
import { Box, Stack, Typography, TextField, Button } from "@mui/material";

import { CloseIcon } from "src/assest/icons";
import toast from "react-hot-toast";

interface InputTagsProps {
  listContent: string[];
  value: string;
  maxLength: number;
  setListContent: Function;
  setValue: Function;
}

const Tags = ({ value, onDelete }: { value: string; onDelete: Function }) => {
  return (
    <Box
      sx={{
        background: "#f5f5f5",
        height: "100%",
        display: "flex",
        padding: "0.4rem",
        justifyContent: "center",
        alignContent: "center",
        borderRadius: "4px",
      }}
    >
      <Stack
        direction="row"
        gap={1}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {value}
        </Typography>
        <Button
          sx={{
            minWidth: 0,
            padding: "2px",
          }}
          onClick={() => onDelete(value)}
        >
          <CloseIcon />
        </Button>
      </Stack>
    </Box>
  );
};

export default function InputTags({ listContent, setListContent, value, setValue }: InputTagsProps) {
  // ref
  const tagRef = useRef<any>();

  // functions
  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (listContent.length > 10) {
      toast.error("Exceed 10 email limit");
      return;
    }

    if (!tagRef.current.value) {
      return;
    }

    if (tagRef.current) {
      setListContent([...listContent, tagRef.current.value]);
      tagRef.current.value = "";
    }
  };

  const handleDelete = (value: string) => {
    const newTags = listContent.filter((val) => val !== value);
    setListContent(newTags);
  };

  // effect
  useEffect(() => {
    const currentUser = localStorage.getItem("userInfo");
    if (currentUser) {
      const userInfo = JSON.parse(currentUser);
      if (listContent.includes(userInfo.email)) {
        return;
      }
      setListContent((prev) => [...prev, userInfo.email]);
    }
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={handleOnSubmit}>
        <TextField
          fullWidth
          inputRef={tagRef}
          size="small"
          sx={{
            margin: "1rem 0",
            "& input": {
              fontSize: "14px",
            },
            marginTop: 0,
          }}
          margin="none"
          placeholder="Enter email here"
          InputProps={{
            startAdornment: (
              <Box
                sx={{
                  gap: "4px",
                  display: "flex",
                  flexWrap: "wrap",
                  flexShrink: 0,
                  maxWidth: "70%",
                  paddingY: "10px",
                }}
              >
                {listContent.map((data, index) => {
                  return (
                    <Tags
                      value={data}
                      key={index}
                      onDelete={handleDelete}
                    />
                  );
                })}
              </Box>
            ),
          }}
          autoComplete="off"
        />
      </form>
      <TextField
        id="subject-session"
        value={value}
        label="Subject"
        type="text"
        fullWidth
        margin="normal"
        sx={{
          marginBottom: 2,
          marginTop: "0",
          "& .MuiInputBase-input": {
            padding: "10px 14px",
            fontSize: "14px",
          },
          "& label": {
            fontSize: "12px",
            top: "-5px",
          },
          "& label.Mui-focused": {
            lineHeight: "2.4375em",
            left: "5px",
          },
          "& label.MuiInputLabel-shrink": {
            lineHeight: "2.4375em",
          },
          backgroundColor: "white",
        }}
        name="subject-session"
        aria-invalid
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
      />
    </Box>
  );
}
