import { Box, Button, Container } from '@mui/material'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

const CompanyPage = () => {
  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Typography variant="h4">Company Information </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField margin="normal" required fullWidth id="name" label="Name" name="name" autoFocus />
          <TextField margin="normal" fullWidth id="address" label="Address" name="address" />
          <TextField margin="normal" fullWidth id="phone_number" label="Phone Number" name="phone_number" />
          <TextField margin="normal" fullWidth id="website" label="Website" name="website" />
          <TextField margin="normal" fullWidth id="email" label="Contact Email" name="email" />
          <TextField margin="normal" fullWidth id="linkedin" label="LinkedIn Profile" name="linkedin" />
          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 1 }}>
            Save
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default CompanyPage
