import * as React from "react";

const PayPalCallback = () => {
  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('subscription_id');
    localStorage.setItem('paypal_subscription_id', code)
    window.close()
  }, [])

  return <></>
}

export default PayPalCallback