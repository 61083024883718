import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

interface LoadingState {
  is_loading: boolean;
}

const initialState: LoadingState = {
  is_loading: false,
};

export const loadingSlide = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.is_loading = action.payload;
    },
  },
});

export const { setLoading } = loadingSlide.actions;

export default loadingSlide.reducer;
