import { useState } from "react";
import { Box, Stack, Drawer } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

import Logo from "src/assest/images/logo.svg";
import { AccountItem, FolderItem, ProjectItem, TeamItem, WorkstationItem, SubscriptionItem } from "./ListItem";
import Notifications from "./Notifications";

interface HeaderProps {
  refreshGetListGroupProject?: Function;
}

const Header: React.FC<HeaderProps> = ({ refreshGetListGroupProject }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;

  // state
  const [open, setOpen] = useState(false);

  // functions
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const closeSidebar = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: "10px",
      }}
    >
      <Box
        component="div"
        onClick={() => {
          navigate("/middle-workstation");
        }}
      >
        <img
          src={Logo}
          alt="neuralpit"
          style={{
            height: "45px",
            width: "auto",
            cursor: "pointer",
          }}
        />
      </Box>
      <Stack
        flexDirection="row"
        gap="5px"
        sx={{
          display: { lg: "flex", xs: "none" },
        }}
      >
        <WorkstationItem pathName={pathName} />
        <TeamItem
          pathName={pathName}
          refreshGetListGroupProject={refreshGetListGroupProject}
        />
        <ProjectItem
          pathName={pathName}
          newTeamId={0}
          refreshGetListGroupProject={refreshGetListGroupProject}
        />
        <FolderItem />
        <SubscriptionItem pathName={pathName} />
        <Notifications />
        <AccountItem pathName={pathName} />
      </Stack>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{
          display: { lg: "none", xs: "block" },
        }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        anchor="right"
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
        open={open}
      >
        <Stack
          sx={{
            gap: "30px",
            marginTop: "50px",
          }}
        >
          <WorkstationItem pathName={pathName} />
          <TeamItem pathName={pathName} />
          <ProjectItem
            pathName={pathName}
            newTeamId={0}
          />

          <FolderItem />
          <SubscriptionItem pathName={pathName} />
          <Notifications />
          <AccountItem pathName={pathName} />
        </Stack>
      </Drawer>
    </Box>
  );
};

export default Header;
