import { handleError } from "src/libs/hooks";
import api from "../api"

export const getPayments = async () => {
  try {
    const res = await api.get("/payment")
    return res.data
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}


export const getPaymentByTeam = async (teamId: string): Promise<Object | undefined> => {
  try {
    const res = await api.get(`/payment/${teamId}`)
    if (res.data) return res.data
    else return null
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}

export const addPaymentMethod = async (teamId: string, data: any) => {
  try {
    const res = await api.post(`/payment/${teamId}/add`, data)
    return res.data
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}

export const getPaymentLogByTeamId = async (teamId: string) => {
  try {
    const res = await api.get(`/payment/${teamId}/history`)
    return res.data
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}