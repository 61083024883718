import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

type IErrorResponse = {
  content: string;
  teamId: number;
}

interface ErrorResponseState {
  error: IErrorResponse
}

const initialState: ErrorResponseState = {
  error: null,
};

export const errorResponseSlide = createSlice({
  name: "error_response",
  initialState,
  reducers: {
    addErrorResponse: (state, action: PayloadAction<IErrorResponse>) => {
      state.error = action.payload
    },
  },
});

export const { addErrorResponse } = errorResponseSlide.actions;

export default errorResponseSlide.reducer;
