import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

const WebInsights = () => {
  // state
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      {currentPage === 0 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1,
            }}
          >
            Web Insights Explorer
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            Use this tool to extract insights from website and Youtube videos.
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            To summarize and extract insights from website and Youtube videos:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  display: "inline-block",
                }}
              >
                Step 1:
              </Typography>
              &nbsp;Add:
              <Box
                component="ul"
                sx={{}}
              >
                <Box
                  component="li"
                  sx={{
                    mb: 1,
                  }}
                >
                  ONE website URL (e.g.,{" "}
                  <a
                    href="https://neuralpit.com/"
                    style={{ color: "#1672c9" }}
                  >
                    https://neuralpit.com
                  </a>
                  ). Content from all internal links and pages of the entire website will be included in the
                  conversation.
                </Box>
                <Box
                  component="li"
                  sx={{
                    mb: 1,
                  }}
                >
                  OR a specific website link (e.g.&nbsp;
                  <a
                    style={{ color: "#1672c9" }}
                    href="https://neuralpit.com/use-case/"
                  >
                    https://neuralpit.com/use-case/
                  </a>
                  ).
                </Box>
                <Box
                  component="li"
                  sx={{}}
                >
                  OR add one or multiple YouTube video links.
                </Box>
              </Box>
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mt: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  display: "inline-block",
                }}
              >
                Step 2:
              </Typography>
              &nbsp;Ask questions
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setCurrentPage(1)}
          >
            Sample questions for summarizing Youtube videos
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 0.5,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setCurrentPage(2)}
          >
            Sample questions for extracting insights from customer reviews for products or services
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 0.5,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setCurrentPage(3)}
          >
            Get insights from a company or regulator website
          </Typography>
        </Box>
      )}
      {currentPage === 1 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1.5,
            }}
          >
            Sample questions for summarizing Youtube video:
          </Typography>

          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 1,
                fontStyle: "italic",
              }}
            >
              Summarize main content of the video
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Give me steps to do [your action here] given in the video
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              What are PPEs mentioned in the safety training video and how to use them appropriately
            </Box>
          </Box>
        </Box>
      )}
      {currentPage === 2 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1.5,
            }}
          >
            Sample questions for extracting insights from customer reviews for products or services (
            <a
              href="https://www.youtube.com/watch?v=0DUh6XLau9w"
              style={{
                textDecoration: "underline",
                color: "#1976d2",
              }}
              target="_blank"
              rel="noreferrer"
            >
              see sample video
            </a>
            ):
          </Typography>

          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 1,
                fontStyle: "italic",
              }}
            >
              Analyze consumer sentiments and provide insights, detailing the percentages of positive, neutral, and
              negative feedback on the product.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Which features are most liked by customers, and why?
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              What are the most frequent suggestions for improvement?
            </Box>
          </Box>
        </Box>
      )}
      {currentPage === 3 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1.5,
            }}
          >
            Get insights from a company or regulator website:
          </Typography>

          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 1,
                fontStyle: "italic",
              }}
            >
              Provide a summary of the products or services offered by the company.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              From the company’s website, what are features most liked by customers, and why?
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Which features are least liked by customers, and why?
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              What are the most frequent suggestions for improvement?
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Summarize key insights from regulatory updates mentioned in the regulator website.
            </Box>
          </Box>
        </Box>
      )}
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          paddingY: 2,
          justifyContent: "space-between",
        }}
      >
        {currentPage !== 0 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => setCurrentPage(0)}
          >
            Home
          </Button>
        )}
        {currentPage > 1 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              if (currentPage === 0) {
                return;
              }
              setCurrentPage(currentPage - 1);
            }}
          >
            Previous
          </Button>
        )}
        {currentPage !== 0 && currentPage !== 3 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              if (currentPage === 3) {
                return;
              }
              setCurrentPage(currentPage + 1);
            }}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default WebInsights;
