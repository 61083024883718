import { Box, Typography } from "@mui/material";

const PerformanceAnalytics = () => {
  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      <Box
        component="div"
        sx={{}}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: 1,
          }}
        >
          Performance Analytics Assistant
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Use this tool to analyze and visualize HR data performance data.
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Upload One HR performance data file (in Excel or CSV), then ask questions.
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Sample questions for analyzing HR data (e.g.,&nbsp;
          <a
            href="https://neuralpit.com/"
            style={{ color: "#1672c9" }}
          >
            https://neuralpit.com
          </a>
          ).
        </Typography>

        <Box
          component="ul"
          mb={2}
          mt={1}
          padding={0}
          paddingLeft={2}
        >
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Plot total incidents by Department
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Plot total incidents by Department and by Gender
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Plot Injury locations within the Maintenance Department for males in the 25-34 age group
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Plot employee seniority distribution
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Plot Training Completion status by Department
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              List top 10 employees with highest Performance Rating and completed Training
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PerformanceAnalytics;
