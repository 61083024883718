import { useState } from "react";
import { Button, Tooltip, Box } from "@mui/material";

interface CopyIconProps {
  children: React.ReactNode;
  text: string;
}

const CopyText: React.FC<CopyIconProps> = ({ children, text }) => {
  // state
  const [copySuccess, setCopySuccess] = useState(false);

  // function
  const copyToClipboard = () => {
    const textField = document.createElement("textarea");

    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };
  const title = copySuccess ? "Copied" : "Copy";

  return (
    <Box component="div">
      <Tooltip title={title}>
        <Button
          onClick={copyToClipboard}
          sx={{
            padding: "0",
            minWidth: "auto",
            color: "#737373",
            "&:hover": {
              color: "#1672c9",
            },
          }}
        >
          {children}
        </Button>
      </Tooltip>
    </Box>
  );
};

export default CopyText;
