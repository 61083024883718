import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Menu, MenuItem } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

// import { AIIcon } from "src/assest/icons";

import AssistantIcon from "src/assest/images/assistant-icon.svg";
import { MdAssistant } from "react-icons/md";

const DATA = [
  {
    id: 0,
    title: "Insight Assistants",
    value: "business_analysis",
  },
  {
    id: 1,
    title: "Marketing Assistants",
    value: "marketing_assistants",
  },
  {
    id: 2,
    title: "HR Assistants",
    value: "hr_assistants",
  },
  {
    id: 3,
    title: "Supply Chain Assistants",
    value: "supply_chain_assistants",
  },
  {
    id: 4,
    title: "Financial Assistants",
    value: "financial_assistants",
  },
];

const Assistant = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentAI, setCurrentAI] = useState("business_analysis");

  // functions
  const handleSelectAssistant = (value: string, id: number) => {
    if (value === currentAI) {
      return;
    }
    setAnchorEl(null);
    if (id === 2) {
      navigate("/hr-assistant/chat-hiring/0");
    }
    if (id === 0) {
      navigate("/workstation/chat-document/0");
    }
    if (id === 1) {
      navigate("/marketing-assistant/market-intelligence/0");
    }
    if (id === 3) {
      navigate("/supply-chain-assistant/vendor-assessment/0");
    }
    if (id === 4) {
      navigate("/financial-assistant/financial-analytics/0");
    }
  };

  // useEffect
  useEffect(() => {
    const currentURL = location.pathname;
    const currentAssistant = currentURL.split("/")[1];

    if (!currentAssistant) {
      setCurrentAI("business_analysis");
    }
    if (currentAssistant === "hr-assistant") {
      setCurrentAI("hr_assistants");
    }
    if (currentAssistant === "marketing-assistant") {
      setCurrentAI("marketing_assistants");
    }
    if (currentAssistant === "supply-chain-assistant") {
      setCurrentAI("supply_chain_assistants");
    }
    if (currentAssistant === "financial-assistant") {
      setCurrentAI("financial_assistants");
    }
  }, []);

  return (
    <React.Fragment>
      <Button
        component="div"
        sx={{
          padding: "0",
          display: "flex",
          alignItems: "center",
          gap: 1,
          textTransform: "capitalize",
          justifyContent: "flex-start",
          "&:hover": {
            bgcolor: "transparent",
          },
          "&:active": {
            bgcolor: "transparent",
          },
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <img
          src={AssistantIcon}
          alt="assistant"
          style={{
            width: "35px",
            height: "35px",
          }}
        />
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              lineHeight: 1,
              mb: 0.5,
            }}
            className="custom-text-responsive"
          >
            {DATA.find((item) => item.value === currentAI)?.title || "Business Analysis"}
          </Typography>
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: "500",
              lineHeight: 1,
              color: "#000000",
              fontStyle: "italic",
            }}
          >
            Choose Your Assistants Here
          </Typography>
        </Box>
      </Button>
      <Menu
        id="assistant-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "assistant-menu",
        }}
      >
        {DATA.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleSelectAssistant(item.value, item.id)}
            sx={{
              padding: "5px 10px",
              // "&:hover": {
              //   bgcolor: "transparent",
              // },
              backgroundColor: `${currentAI === item.value ? "rgba(0, 0, 0, 0.04)" : "transparent"}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <MdAssistant />
              <Typography
                variant="h3"
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "30px",
                }}
              >
                {item.title}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default Assistant;
