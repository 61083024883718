import { Box, Button, Stack, Typography } from "@mui/material";
import { FastField, Form, Formik } from "formik";

import { Input } from "src/components/common";

const MyCompany = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "400px",
        margin: "0 auto",
      }}
    >
      <Typography variant="h5"> Company Information </Typography>
      <Formik
        initialValues={{}}
        onSubmit={() => {}}
      >
        {() => {
          return (
            <Form>
              <Stack
                sx={{ mt: 3 }}
                gap={2}
              >
                <FastField
                  name="name"
                  component={Input}
                  type="text"
                  placeHolder="Name *"
                  isFocus={true}
                />
                <FastField
                  name="address"
                  component={Input}
                  type="text"
                  placeHolder="Address"
                />
                <FastField
                  name="phoneNumber"
                  component={Input}
                  type="text"
                  placeHolder="Phone Number"
                />
                <FastField
                  name="website"
                  component={Input}
                  type="text"
                  placeHolder="Website"
                />
                <FastField
                  name="contactEmail"
                  component={Input}
                  type="text"
                  placeHolder="Contact Email"
                />
                <FastField
                  name="linkedInProfile"
                  component={Input}
                  type="text"
                  placeHolder="LinkedIn Profile"
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 1 }}
                >
                  Save
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default MyCompany;
