import api from "../api"
import { IAssistantModel } from "src/libs/models/assistant"
import { handleError, handleErrorNotify } from "src/libs/hooks"

export const fetchListAssistant = async (): Promise<IAssistantModel[] | undefined> => {
    try {
        const res = await api.get("/a/1/ver2/assistants")
        return res.data
    } catch (error) {
        return undefined
    }
}

export const fetchListToolByAssistant = async (assistantID: number) => {
    try {
        const res = await api.get(`/a/1/ver2/agents?assistant_id=${assistantID}`)
        return res.data
    } catch (error) {
        return undefined
    }
}

export const uploadDocumentToConversationID = async (id: string, projectId: number, teamId: number, key: any, chat_id: string): Promise<any | undefined> => {
    let payload: any
    if (chat_id !== "0" && chat_id) {
        payload = { key, chat_id: Number(chat_id) }
    } else {
        payload = { key }
    }
    try {
        const res = await api.post(`/a/${teamId}/project/${projectId}/conversations/${id}/upload_document`, payload)
        return res.data?.message || "Documents uploaded successfully."
    } catch (error) {
        handleError(error)
        return undefined
    }
}

export const uploadFileAnalytics = async (teamId: number, projectId: number, folder: string, file: File, external_id: string, chat_id: number, assistant_id: string, agent_id: string): Promise<any | undefined> => {
    const formData = new FormData()

    formData.append("file", file)
    formData.append("folder", folder)
    if (external_id && external_id !== "0") {
        formData.append("external_id", external_id)
    }
    if (chat_id) {
        formData.append("chat_id", `${chat_id}`)
    }
    formData.append("assistant_id", assistant_id)
    formData.append("agent_id", agent_id)

    try {
        const res = await api.post(`/a/${teamId}/project/${projectId}/conversations-v2/visualization_upload_file`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return res.data
    } catch (error) {
        handleError(error)
        return undefined
    }
}

export const uploadFileChat2File = async (teamId: number, projectId: number, folder: string, files: File[], external_id: string, chat_id: string, assistant_id: string, agent_id: string): Promise<any | undefined> => {
    const formData = new FormData()

    for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i])
    }
    if (external_id && external_id !== "0") {
        formData.append("external_id", external_id)
    }
    if (chat_id) {
        formData.append("chat_id", chat_id)
    }
    formData.append("folder", folder)
    formData.append("assistant_id", assistant_id)
    formData.append("agent_id", agent_id)

    try {
        const res = await api.post(`/a/${teamId}/project/${projectId}/conversations-v2/upload_file`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return res.data
    } catch (error) {
        handleErrorNotify(error, teamId)
        return undefined
    }
}

export const addFileFromS3 = async (teamId: number, projectId: number, key: string[], chat_id: string, agent_id: string): Promise<any | undefined> => {
    try {
        const res = await api.post(`/a/${teamId}/project/${projectId}/conversations-v2/upload_document`, { key, chat_id, agent_id })
        return res.data
    } catch (error) {
        handleErrorNotify(error, teamId)
        return undefined
    }
}

export const uploadLinkToConversation = async (teamId: number, projectId: number, sessionId: string, conversation_id: string, url: string, mode: string, assistant_id: string, agent_id: string) => {
    let payload: any

    if (conversation_id && conversation_id !== "0") {
        payload = {
            url,
            mode,
            external_id: conversation_id,
            chat_id: sessionId,
            assistant_id,
            agent_id
        }
    } else {
        payload = {
            url,
            mode,
            assistant_id,
            agent_id
        }
    }

    try {
        const res = await api.post(`/a/${teamId}/project/${projectId}/conversations-v2/website_document`, payload)
        return res.data
    } catch (error) {
        handleErrorNotify(error, teamId)
        return undefined
    }
}

export const deleteSessionById = async (teamId: number, projectId: number, id: number) => {
    try {
        await api.delete(`/a/${teamId}/project/${projectId}/chats-v2/${id}`)
        return "Delete session successfully!"
    } catch (error) {
        handleError(error)
        return undefined
    }
}