import { IPlan } from "src/libs/models/subscription";
import { handleError } from "src/libs/hooks";
import api from "../api"

export const getSubscriptions = async () => {
  try {
    const res = await api.get("/subscription/")
    return res.data
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}

export const getSubscriptionPlans = async (): Promise<IPlan[] | undefined> => {
  try {
    const res = await api.get("/subscription/plans")
    return res.data
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}

export const getTeamSubscription = async (teamId: string): Promise<Object | undefined> => {
  try {
    const res = await api.get(`/subscription/plans/${teamId}`)
    if (res.data) return res.data
    else return null
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}

// custom this end-point
export const subscribePlan = async (slug: string, planId: number, callbackUrl: string) => {
  try {
    const res = await api.post(`/subscription/${slug}/subscribe`, { plan_id: planId, callback_url: callbackUrl })
    return res.data
  } catch (err) {
    console.warn(err)
    return err
  }
}

export const cancelSubscriptionById = async (slug: string) => {
  try {
    const res = await api.get(`/subscription/${slug}/cancel`)
    return res.data
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}

export const resumeSubscriptionById = async (slug: string) => {
  try {
    const res = await api.get(`/subscription/${slug}/resume`)
    return res.data
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}

export const approvePayPalSubscription = async (slug: string, subscriptionId: string) => {
  try {
    const res = await api.post(`/subscription/${slug}/paypal-approve`, { subscription_id: subscriptionId })
    return res.data
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}

export const getTeamQuotasStatus = async (teamId: number) => {
  try {
    const res = await api.get(`/teams/${teamId}/teamquotas/status`)
    return res.data
  } catch (error) {
    console.warn(error)
    return "error"
  }
}

export const fetchCheckFreeTrial = async (teamId: string) => {
  try {
    const res = await api.get(`/teams/${teamId}/check_free_trial`)
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}