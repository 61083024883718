import * as React from "react";
import * as Yup from "yup";
import { Formik, Form, FastField } from "formik";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { ModalNoAction, Input } from "src/components/common";
import { createTeam, getTeamById, patchTeamById } from "src/libs/axios/api/team";
import { ITeam } from "src/libs/models/team";

interface TeamTemplateProps {
  teamId: number;
  open: boolean;
  setOpen: Function;
  callback?: Function;
  refreshGetListGroupProject?: Function;
}

const TeamTemplate = ({ open, setOpen, teamId, callback, refreshGetListGroupProject }: TeamTemplateProps) => {
  // state
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<ITeam>({
    id: 0,
    name: "",
  });

  // schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });

  // function
  const createNewTeam = async (payload: ITeam) => {
    setIsLoading(true);
    const res = await createTeam(payload);
    setIsLoading(false);
    if (res) {
      setOpen(false);
      if (callback) {
        callback();
      }
      if (refreshGetListGroupProject) {
        refreshGetListGroupProject(res?.id, "new_team");
      }
    }
  };

  const getTeam = async () => {
    const res = await getTeamById(Number(teamId));
    if (res) {
      setData((prev) => ({
        ...prev,
        ...res,
      }));
    }
  };

  const updateTeam = async (payload: ITeam) => {
    setIsLoading(true);
    const res = await patchTeamById(payload);
    setIsLoading(false);
    if (res) {
      toast.success("Update a Team Successful!");
      setOpen(false);
      if (callback) {
        callback();
      }
    }
  };

  // useEffect
  React.useEffect(() => {
    if (teamId && open) {
      getTeam();
    }
  }, [teamId, open]);

  return (
    <ModalNoAction
      open={open}
      setOpen={setOpen}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          minWidth: "500px",
        }}
      >
        <Typography
          variant="h4"
          mb={2}
        >
          {!teamId ? "Add New Team" : "Edit Team"}
        </Typography>
        <Formik
          initialValues={data}
          onSubmit={(values) => {
            if (teamId === 0) {
              createNewTeam(values);
            } else {
              updateTeam({
                id: teamId,
                ...values,
              });
            }
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {(formikProps) => {
            return (
              <Form>
                <Stack
                  component="div"
                  sx={{ mt: 1 }}
                  gap="35px"
                >
                  <FastField
                    name="name"
                    component={Input}
                    type="text"
                    placeHolder="Name *"
                    isFocus={true}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mb: 1, textTransform: "capitalize" }}
                    disabled={!(formikProps.isValid && formikProps.dirty) || isLoading}
                    startIcon={
                      !isLoading ? (
                        ""
                      ) : (
                        <CircularProgress
                          color="inherit"
                          size="15px"
                        />
                      )
                    }
                  >
                    Submit
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ModalNoAction>
  );
};

export default TeamTemplate;
