import { Box, Typography } from "@mui/material";

const MarketingAnalytics = () => {
  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      <Box
        component="div"
        sx={{}}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: 1,
          }}
        >
          Marketing Analytics Assistant
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Use this tool to analyze and visualize marketing and sales data.
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Upload One data file (in Excel or CSV) and ask AI assistants to analyze.
        </Typography>

        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 2,
            textDecoration: "underline",
          }}
        >
          Sample questions:
        </Typography>
        <Box
          component="ul"
          mb={2}
          mt={1}
          padding={0}
          paddingLeft={2}
        >
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Plot Sales by region
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              List top 10 products with highest sales in Region X
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Plot bottom 10 products with lowest sales
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Plot Sales by year
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Plot correlation between Sales and Marketing expense
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Plot total engagements and number of posts by month
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MarketingAnalytics;
