import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { FastField, Form, Formik } from "formik";
import * as React from "react";
import toast from "react-hot-toast";

import { Input, Select } from "src/components/common";
import { createNewInvitationsByTeamSlug } from "src/libs/axios/api/invitations";
import { ISelectModel } from "src/libs/models";
import { IMember } from "src/libs/models/team";

interface MembershipTemplateProps {
  teamId: number;
  userId: number;
  onClick: Function;
  callback: Function;
}

const ROLES: ISelectModel[] = [
  {
    id: "admin",
    name: "admin",
  },
  {
    id: "member",
    name: "member",
  },
];

const MembershipTemplate = ({ teamId, onClick, userId, callback }: MembershipTemplateProps) => {
  // state
  const [isLoading, setIsLoading] = React.useState(false);

  // function
  const createInvitationsToMember = async (payload: IMember) => {
    setIsLoading(true);
    const res = await createNewInvitationsByTeamSlug(payload);
    setIsLoading(false);
    if (res) {
      toast.success("Invitations Successful!");
      onClick(false);
      callback();
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{
          id: 0,
          team: 0,
          email: "",
          role: "",
          invited_by: 0,
          is_accepted: true,
        }}
        onSubmit={(values) => {
          createInvitationsToMember({
            id: 0,
            team: Number(teamId),
            email: values?.email,
            role: values?.role,
            invited_by: userId,
            is_accepted: true,
          });
        }}
        enableReinitialize
      >
        {() => {
          return (
            <Form>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "25px",
                  marginTop: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <FastField
                    name="email"
                    component={Input}
                    type="text"
                    placeHolder="Email *"
                  />
                </Box>
                <FastField
                  name="role"
                  component={Select}
                  options={ROLES}
                  label="Role"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoading}
                  startIcon={
                    !isLoading ? (
                      ""
                    ) : (
                      <CircularProgress
                        color="inherit"
                        size="15px"
                      />
                    )
                  }
                  sx={{
                    height: "47px",
                    textTransform: "capitalize",
                  }}
                >
                  Add
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default MembershipTemplate;
