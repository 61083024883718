import { combineReducers, configureStore } from "@reduxjs/toolkit"

import authReducer from "./features/auth"
import projectReducer from "./features/project"
import sessionReducer from "./features/session"
import loadingReducer from "./features/loading"
import permissionReducer from "./features/permission"
import errorResponse from "./features/error_response"
// import invitationMember from "./features/invitation_member"
import currentTeamReducer from "./features/current-project"

export const rootReducer = combineReducers({
    auth: authReducer,
    project: projectReducer,
    session: sessionReducer,
    loading: loadingReducer,
    permission: permissionReducer,
    errorResponse: errorResponse,
    currentTeam: currentTeamReducer
})

export const store = configureStore({
    reducer: rootReducer
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch