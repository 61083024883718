import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

interface PermissionState {
  is_permission: boolean;
}

const initialState: PermissionState = {
  is_permission: false,
};

export const permissionSlide = createSlice({
  name: "permission",
  initialState,
  reducers: {
    checkPermissionTeam: (state, action: PayloadAction<boolean>) => {
      state.is_permission = action.payload;
    },
  },
});

export const { checkPermissionTeam } = permissionSlide.actions;

export default permissionSlide.reducer;