import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

const ContentCreation = () => {
  // state
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      {currentPage === 1 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1,
            }}
          >
            Content Creation Assistant
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            Use this tool to craft marketing content such as: social media post, images, logos, copywriting, and share.
          </Typography>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 2,
              textDecoration: "underline",
            }}
          >
            Social media post:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Write a [social media name] post about [topic] that includes a call to action for [target audience],
                include suitable emoji
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 2,
              textDecoration: "underline",
            }}
          >
            Website copywriting:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Write a web copy for [product/service] using the [PAS, BAB, AIDA copywriting framework, etc.]
                copywriting formula
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 2,
              textDecoration: "underline",
            }}
          >
            Product/service description:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Write a product description for [product name and category] in [specify tone] highlighting its unique
                features. [List features]
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 2,
              textDecoration: "underline",
            }}
          >
            SEO copy:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                You are an SEO copy editor. Craft a concise web page copy within [X] words, showcasing the distinctive
                features of [product / service]. Emphasize [features] for maximum appeal to [target audience].
                Seamlessly incorporate the keyword [keyword 1, keyword 2] throughout the content
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {currentPage === 2 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1,
            }}
          >
            Content Creation Assistant
          </Typography>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 2,
              textDecoration: "underline",
            }}
          >
            Create logos:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 0.5,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Create a logo for a company in [AI] with a [lettermark of a letter “A”], vector, simple.
              </Typography>
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 0.5,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Create a [mascot] logo of a [cat], simple, vector, white background.
              </Typography>
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Create an [emblem] for a race car club, vector, simple, black and white.
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 2,
              textDecoration: "underline",
            }}
          >
            Book covers:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 0.5,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Craft a comic strip featuring characters [of your choice].
              </Typography>
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 0.5,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Develop a book cover for a novel in [a genre you prefer].
              </Typography>
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Create an album cover for a band in [a genre you like].
              </Typography>
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Illustrate a scene or characters for a children’s book, like ‘a cow eating grass.
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 2,
              textDecoration: "underline",
            }}
          >
            Architectural Design Concept:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 0.5,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Create an image for a Conceptualized architectural design for a urban house [or a building type of your
                preference].
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 2,
              textDecoration: "underline",
            }}
          >
            Advertisement Poster Design:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 0.5,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Design an advertisement poster for a luxurious electric sedan vehicle [or product of your choice or add
                more features here].
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          paddingY: 2,
          justifyContent: "space-between",
        }}
      >
        {currentPage === 2 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              setCurrentPage(1);
            }}
          >
            Previous
          </Button>
        )}
        {currentPage === 1 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              setCurrentPage(2);
            }}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ContentCreation;
