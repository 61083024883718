import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes, BrowserRouter as UserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { privateRoutes, publicRoutes } from "./routes";
import { gapi } from "gapi-script";
import { useEffect } from "react";
import PrivateRoute from "./routes/PrivateRoute";

import "./styles/global.css";
import "./styles/resize.css";
import NotFoundPage from "./pages/404";

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["Inter", , "sans-serif"].join(","),
    fontSize: 16,
    h1: {
      fontSize: "30px",
      lineHeight: "50px",
      fontWeight: 700,
      color: "black",
    },
    h2: {
      fontSize: "30px",
      lineHeight: "50px",
      fontWeight: 700,
      color: "black",
    },
    h3: {
      fontSize: "30px",
      lineHeight: "50px",
      fontWeight: 700,
      color: "black",
    },
    h4: {
      fontSize: "30px",
      lineHeight: "50px",
      fontWeight: 700,
      color: "black",
    },
    h5: {
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: 600,
      color: "black",
    },
    h6: {
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: 600,
      color: "black",
    },
    caption: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 400,
      color: "black",
    },
  },
});

function App() {
  useEffect(() => {
    gapi.load("client", () => {
      // gapi loaded
      // can now init auth, make API calls, etc
    });
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <UserRouter>
            <Routes>
              {publicRoutes.map((route) => {
                const Page = route.page;
                const Layout = route.layout;

                return (
                  <Route
                    key={`route-${route.id}`}
                    path={route.path}
                    element={
                      <Layout>
                        <Page />
                      </Layout>
                    }
                  />
                );
              })}
              {privateRoutes.map((route) => {
                const Page = route.page;
                const Layout = route.layout;

                return (
                  <Route
                    key={`route-${route.id}`}
                    path={route.path}
                    element={
                      <PrivateRoute>
                        <Layout>
                          <Page key={route.id} />
                        </Layout>
                      </PrivateRoute>
                    }
                  />
                );
              })}

              <Route
                path="*"
                element={<NotFoundPage />}
              />
            </Routes>
          </UserRouter>
          <div id="chatbot-feedback"></div>
          <Toaster
            position="top-right"
            toastOptions={{
              className: "",
              style: {
                fontSize: "14px",
              },
            }}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}
export default App;
