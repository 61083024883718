import * as React from "react";
import * as Yup from "yup";
import { Box, Button, Stack } from "@mui/material";
import { FastField, Form, Formik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";

import { Input } from "src/components/common";
import { changePassword } from "src/libs/axios/api/auth";
import { setToken } from "src/libs/axios/api";
import { login, addRefreshToken } from "src/store/features/auth";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Old password is required"),
    new_password: Yup.string().required("New password is required"),
    confirm_password: Yup.string()
      .required()
      .oneOf([Yup.ref("new_password")], "Confirm password must match with new password"),
  });

  const changePasswordAccount = async (info: {
    old_password: string;
    new_password: string;
    confirm_password: string;
  }) => {
    setIsLoading(true);
    const res = await changePassword(info);
    setIsLoading(false);
    if (res) {
      toast.success("Change password successfully!");
      setToken(res.access_token);
      dispatch(login(res.access_token));
      dispatch(addRefreshToken(res.refresh_token));
      localStorage.removeItem("current_project");
      setTimeout(() => {
        navigate("/middle-workstation");
      }, 1000);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        maxWidth: "400px",
        margin: "0 auto",
      }}
    >
      <Typography variant="h5">Change Password </Typography>
      <Formik
        initialValues={{
          old_password: "",
          new_password: "",
          confirm_password: "",
        }}
        onSubmit={(values) => {
          changePasswordAccount(values);
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          return (
            <Form>
              <Stack
                sx={{ mt: 3 }}
                gap={2}
              >
                <FastField
                  name="old_password"
                  component={Input}
                  type="password"
                  placeHolder="Old Password"
                  isFocus={true}
                />
                <FastField
                  name="new_password"
                  component={Input}
                  type="password"
                  placeHolder="New Password"
                />
                <FastField
                  name="confirm_password"
                  component={Input}
                  type="password"
                  placeHolder="Confirm Password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1 }}
                  disabled={!(formikProps.isValid && formikProps.dirty) || isLoading}
                  startIcon={
                    !isLoading ? (
                      ""
                    ) : (
                      <CircularProgress
                        color="inherit"
                        size="15px"
                      />
                    )
                  }
                >
                  Submit
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ChangePassword;
