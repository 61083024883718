import * as React from "react";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";

import { Box, Button, Container } from "@mui/material";

import { ITeam } from "src/libs/models/team";
import { getMyProfile } from "src/libs/axios/api/user";
import { getListTeamByPage } from "src/libs/axios/api/team";
import { setLoading } from "src/store/features/loading";
import { CustomWidthTooltip, LiText } from "src/libs/AppStyle";
import TeamTemplate from "src/components/Team/TeamTemplate";
import { UseGuideIcon } from "src/assest/icons";
import TeamMembersTable from "./TeamMembersTable";

const TeamPage = () => {
  const dispatch = useDispatch();

  // state
  const [teams, setTeams] = React.useState<ITeam[]>([]);
  const [openTeamModal, setOpenTeamModal] = React.useState(false);
  const [userId, setUserId] = React.useState(0);

  // function
  const getListAllTeam = async () => {
    dispatch(setLoading(true));
    const res = await getListTeamByPage();
    if (res?.results?.length > 0) {
      setTeams(res.results);
    }
    dispatch(setLoading(false));
  };

  const getUser = async () => {
    const res = await getMyProfile();
    if (res) {
      setUserId(res?.id);
    }
  };

  // useEffect => call api
  React.useEffect(() => {
    getListAllTeam();
    getUser();
  }, []);

  return (
    <Container
      component="main"
      maxWidth="lg"
    >
      <Box
        mb={2}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Typography
          component="h1"
          mb={0}
          sx={{
            fontWeight: "700",
            fontSize: "30px",
          }}
        >
          My Teams
        </Typography>
        <CustomWidthTooltip
          title={
            <Box component="div">
              <Box
                component="ul"
                sx={{
                  paddingLeft: "15px",
                }}
              >
                <LiText component="li">Each NeuralPit subscription is for one team.</LiText>
                <LiText component="li">
                  Teams, limited by their plan quota, can be just you or include multiple member.
                </LiText>
                <LiText component="li">You can create and own several teams or join many as a member.</LiText>
                <LiText component="li">
                  As a team owner, you can assign roles (Member or Admin) to team members. Admins can subscribe,
                  upgrade, and handle payments for the team.
                </LiText>
                <LiText component="li">
                  By default, your personal team is private, containing only you and your files. Unless you decide to
                  invite others to your personal team.
                </LiText>
                <LiText component="li">Remove active members or cancel invites to reduce team size.</LiText>
              </Box>
            </Box>
          }
        >
          <Box component="div">
            <UseGuideIcon />
          </Box>
        </CustomWidthTooltip>
      </Box>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="info"
          sx={{ textTransform: "capitalize", fontSize: "14px" }}
          onClick={() => {
            setOpenTeamModal(true);
          }}
        >
          Add New Team
        </Button>
      </Box>

      {teams &&
        teams.length > 0 &&
        teams.map((team) => (
          <Box
            key={team.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              mt: 2,
            }}
          >
            <TeamMembersTable
              team={team}
              callback={getListAllTeam}
              userId={userId}
            />
          </Box>
        ))}
      <TeamTemplate
        open={openTeamModal}
        setOpen={setOpenTeamModal}
        teamId={0}
        callback={getListAllTeam}
      />
    </Container>
  );
};

export default TeamPage;
