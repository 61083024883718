import { Box, Button, Card, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import BankCardSvg from "src/assest/images/bank-card-icon.svg";
import PayPalSvg from "src/assest/images/paypal-icon.svg";
import { approvePayPalSubscription, subscribePlan } from "src/libs/axios/api/subscription";
import { normalizeStr } from "src/libs/hooks";

interface ModalProps {
  open: boolean;
  setOpen: Function;
  payment_data: any;
  teamId: string;
  payment_channel: string;
  planId: number;
  callback?: Function;
}

type ErrorStripeBodyProps = {
  content: string;
  closeModal: Function;
  teamId: string;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const ErrorStripeBody: React.FC<ErrorStripeBodyProps> = ({ content, closeModal, teamId }) => {
  const isShowBtn = React.useMemo(() => {
    return normalizeStr(content)?.includes("please_re-enter_your_payment_details");
  }, [content]);

  return (
    <Box
      component="div"
      sx={{
        minHeight: "150px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontSize: "14px",
        }}
      >
        {content}
      </Typography>
      {isShowBtn && (
        <Box
          component="div"
          sx={{
            textAlign: "right",
          }}
        >
          <Button
            sx={{
              minWidth: "auto",
              textTransform: "capitalize",
              fontSize: "14px",
            }}
            variant="contained"
          >
            <a href={`/payment/${teamId}/method`}>Update</a>
          </Button>
          <Button
            sx={{
              minWidth: "auto",
              textTransform: "capitalize",
              marginLeft: "15px",
              fontSize: "14px",
            }}
            variant="outlined"
            color="error"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
};

const SubscriptionModal = ({ open, setOpen, planId, teamId, payment_data, payment_channel, callback }: ModalProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState({
    open: false,
    content: "",
  });

  const handleClose = () => {
    setOpen(false);
    setErrorModal((prev) => ({
      ...prev,
      open: false,
      content: "",
    }));
  };

  const handleClicK = async () => {
    setIsLoading(true);
    const res: any = await subscribePlan(teamId, planId, `${window.location.origin}/paypal-callback`);
    if (res?.success) {
      setIsLoading(false);
      handleClose();
      if (res?.approvalUrl) {
        const width = 500;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        const params = `width=${width},height=${height},left=${left},top=${top}`;

        window.open(res?.approvalUrl, "Paypal Approve", params);
      } else {
        if (callback) {
          callback();
          return;
        }
        navigate(`/subscription/${teamId}/plans`);
      }
    }
    if (res?.response?.status === 500 || res?.response?.status === 400) {
      setErrorModal((prev) => ({
        ...prev,
        open: true,
        content: res?.response?.data?.detail,
      }));
    }
  };

  React.useEffect(() => {
    const handleInvalidToken = async (e) => {
      if (e.key === "paypal_subscription_id") {
        const subscription_id = e.newValue;
        if (subscription_id) {
          const res = await approvePayPalSubscription(teamId, subscription_id);
          if (res?.detail) {
            if (callback) {
              callback();
              return;
            }
            navigate(`/subscription/${teamId}/plans`);
            localStorage.removeItem("paypal_subscription_id");
          }
        }
      }
    };
    window.addEventListener("storage", handleInvalidToken);

    return () => {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, [teamId]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {!errorModal.open ? (
        <>
          <Box
            m={2}
            minWidth={400}
          >
            <Typography variant="h6">Subscribe Plan</Typography>
            {payment_channel === "Stripe" ? (
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: 1,
                  px: 3,
                  mt: 2,
                }}
              >
                <img
                  src={BankCardSvg}
                  alt="Card"
                />
                <Typography>
                  <strong>{payment_data.holder}</strong>
                </Typography>
                <Typography>
                  <strong>
                    {payment_data.exp_month.toString().padStart(2, "0")}/{payment_data.exp_year}
                  </strong>
                </Typography>
              </Card>
            ) : (
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: 1,
                  px: 3,
                  mt: 2,
                }}
              >
                <img
                  src={PayPalSvg}
                  alt="Card"
                />
                <Typography>
                  <strong>{payment_data.email_address}</strong>
                </Typography>
              </Card>
            )}
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleClicK}
              sx={{
                textTransform: "capitalize",
              }}
              startIcon={
                !isLoading ? (
                  ""
                ) : (
                  <CircularProgress
                    color="inherit"
                    size="15px"
                  />
                )
              }
            >
              Subscribe
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleClose}
              sx={{ textTransform: "capitalize" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </>
      ) : (
        <ErrorStripeBody
          content={errorModal.content}
          teamId={teamId}
          closeModal={handleClose}
        />
      )}
    </Dialog>
  );
};

export default SubscriptionModal;
