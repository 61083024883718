import { useState } from "react";
import { Button, Stack, Menu, MenuItem, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { TeamMemberIcon } from "src/assest/icons";
import { BodyText } from "src/libs/AppStyle";
import { AddNewMember } from "src/components/Header";
import TeamTemplate from "src/components/Team/TeamTemplate";

interface TeamItemProps {
  pathName: string;
  refreshGetListGroupProject?: Function;
}

const TeamItem: React.FC<TeamItemProps> = ({ pathName, refreshGetListGroupProject }) => {
  const navigate = useNavigate();

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [teamModal, setTeamModal] = useState(false);
  const [newMemberModal, setNewMemberModal] = useState(false);

  // function
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, url: string) => {
    if (!pathName?.includes("workstation")) {
      navigate(url);
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleNewTeam = () => {
    setTeamModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddNewMember = () => {
    setNewMemberModal(true);
  };

  const open = Boolean(anchorEl);

  const handleRefresh = (id: number, type: "new_team" | "new_project") => {
    if (refreshGetListGroupProject) {
      refreshGetListGroupProject(id, type);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Create and manage teams">
        <Button
          id="my-teams"
          aria-controls={open ? "team-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => handleClick(e, "/team")}
          sx={{
            padding: 0,
            paddingX: "5px",
            color: `${pathName === "/team" ? "#1672c9" : "black"}`,
            "&:hover": {
              backgroundColor: "transparent",
              color: "#1672c9",
              "& svg": {
                color: "#1672c9",
              },
            },
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
          >
            <TeamMemberIcon className={pathName === "/team" ? "activate-svg" : "default-svg"} />
            <BodyText
              variant="caption"
              sx={{
                fontSize: "12px !important",
                textTransform: "capitalize",
                color: "inherit !important",
              }}
            >
              Teams
            </BodyText>
          </Stack>
        </Button>
      </Tooltip>
      <Menu
        id="team-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Button
            sx={{
              textTransform: "none",
              color: "black",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={handleAddNewMember}
          >
            Add new member
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            sx={{
              textTransform: "none",
              color: "black",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={handleNewTeam}
          >
            Add new team
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            sx={{
              textTransform: "none",
              color: "black",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              navigate("/team");
            }}
          >
            View teams
          </Button>
        </MenuItem>
      </Menu>
      <TeamTemplate
        open={teamModal}
        setOpen={setTeamModal}
        teamId={0}
        refreshGetListGroupProject={handleRefresh}
      />
      <AddNewMember
        open={newMemberModal}
        setOpen={setNewMemberModal}
        teamId={0}
      />
    </>
  );
};

export default TeamItem;
