import { useState, useEffect } from "react";
import { Formik, Form, FastField } from "formik";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { Input, Select } from "src/components/common";
import { ISession } from "src/libs/models/chat";
import { createNewChat, getChatSessionById, updateSessionById } from "src/libs/axios/api/chat";
import { LIST_TOPIC } from "src/data";

interface SessionInformationProps {
  teamId: number;
  projectId: number;
  sessionId: number;
  setOpen?: Function;
  callback?: Function;
}

const SessionInformation = ({ teamId, projectId, sessionId, setOpen, callback }: SessionInformationProps) => {
  // state
  const [session, setSession] = useState<ISession>({
    id: 0,
    name: "New session",
    topic: "General",
    objective: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // function
  const createChat = async (payload: ISession) => {
    setIsLoading(true);
    const res = await createNewChat(teamId, projectId, payload);
    setIsLoading(false);
    if (res) {
      setOpen(false);
    }
  };

  const updateSession = async (payload: ISession) => {
    setIsLoading(true);
    const res = await updateSessionById(teamId, projectId, sessionId, payload);
    setIsLoading(false);
    if (res) {
      toast.success("Update session successful.");
      setOpen(false);
      callback();
    }
  };

  const getSession = async () => {
    const res = await getChatSessionById(sessionId, teamId, projectId);
    if (res) {
      setSession((prev) => ({
        ...prev,
        ...res,
      }));
    }
  };

  // useEffect
  useEffect(() => {
    if (sessionId) {
      getSession();
    }
  }, [sessionId]);

  return (
    <Box
      component="div"
      height="100%"
    >
      <Formik
        initialValues={session}
        onSubmit={(values) => {
          if (sessionId) {
            updateSession({
              id: sessionId,
              ...values,
            });
          } else {
            createChat({
              id: 0,
              ...values,
            });
          }
        }}
        enableReinitialize
      >
        {() => {
          return (
            <>
              <Form
                style={{
                  height: "100%",
                }}
              >
                <Stack
                  height="100%"
                  justifyContent="space-between"
                >
                  <Box>
                    <Stack gap={3}>
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Box
                          sx={{
                            width: "150px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            New Session
                          </Typography>
                        </Box>
                        <Box flexGrow={1}>
                          <FastField
                            name="name"
                            component={Input}
                            type="text"
                            placeHolder="Enter Name Here"
                          />
                        </Box>
                      </Stack>
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Box
                          sx={{
                            width: "150px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            Topic
                          </Typography>
                        </Box>
                        <Box flexGrow={1}>
                          <FastField
                            name="topic"
                            component={Select}
                            options={LIST_TOPIC}
                            label="Topic"
                          />
                        </Box>
                      </Stack>

                      <Stack flexDirection="row">
                        <Typography
                          width="150px"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Objective
                        </Typography>
                        <Box flexGrow={1}>
                          <FastField
                            name="objective"
                            component={Input}
                            type="text"
                            placeHolder="optional - a brief description about the chat session objectives"
                            isMultiple={true}
                            row={3}
                          />
                        </Box>
                      </Stack>
                      {/* <Stack flexDirection="row" alignItems="baseline">
                            <Typography width="150px">Websites</Typography>
                            <Box flexGrow={1}>
                              <FieldArray
                                name="links"
                                render={(arrayHelper) => {
                                  return (
                                    <>
                                      <Stack gap="15px">
                                        {formikProps.values.links.map(
                                          (link, index) => (
                                            <Stack
                                              key={`link-${index}`}
                                              flexDirection="row"
                                              justifyContent="space-between"
                                              gap="15px"
                                            >
                                              <Box
                                                sx={{
                                                  flexGrow: 1,
                                                }}
                                              >
                                                <FastField
                                                  name={`links.${index}`}
                                                  component={Input}
                                                  type="text"
                                                  placeHolder="Web links or youtube links"
                                                />
                                              </Box>
                                              <Button
                                                sx={{
                                                  paddingLeft: 0,
                                                  minWidth: "auto",
                                                }}
                                                onClick={() => {
                                                  arrayHelper.remove(index);
                                                }}
                                              >
                                                <IconWrapper>
                                                  <RemoveCircleOutlineIcon />
                                                </IconWrapper>
                                              </Button>
                                            </Stack>
                                          )
                                        )}
                                      </Stack>
                                      <Box>
                                        <Button
                                          sx={{
                                            paddingLeft: 0,
                                            minWidth: "auto",
                                          }}
                                          onClick={() => {
                                            arrayHelper.push("");
                                          }}
                                        >
                                          <IconWrapper>
                                            <AddCircleOutlineIcon />
                                          </IconWrapper>
                                        </Button>
                                      </Box>
                                    </>
                                  );
                                }}
                              />
                            </Box>
                          </Stack> */}
                    </Stack>
                  </Box>
                  <Box textAlign="right">
                    <Button
                      sx={{
                        textTransform: "capitalize",
                      }}
                      type="submit"
                      variant="contained"
                      startIcon={
                        !isLoading ? (
                          ""
                        ) : (
                          <CircularProgress
                            color="inherit"
                            size="15px"
                          />
                        )
                      }
                      disabled={isLoading}
                    >
                      {sessionId ? "Update" : "Add"}
                    </Button>
                  </Box>
                </Stack>
              </Form>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default SessionInformation;
