import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

const StrategyAssistant = () => {
  // state
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      {currentPage === 1 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1,
            }}
          >
            Strategy Assistant
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            This tool is your thought partnership in commercial negotiation and business strategy.
          </Typography>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 2,
              textDecoration: "underline",
            }}
          >
            Social media post:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Business Strategy: Utilize strategy frameworks such as Blue Ocean, Game Theory, and Scenario Planning.
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
              fontStyle: "italic",
            }}
          >
            “Using the Blue Ocean Strategy, how can we strategize our entry into the sustainable fashion market
            considering the following factors:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                listStyle: "none",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                - Context: Overcrowded fast fashion market with growing ethical concerns.
              </Typography>
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                listStyle: "none",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                - Product: Sustainable, biodegradable clothing.
              </Typography>
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                listStyle: "none",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                - Market: Eco-conscious urbanites, aged 18-35.
              </Typography>
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                listStyle: "none",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                - USP: Tech-integrated clothing for real-time design changes.
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
              fontStyle: "italic",
            }}
          >
            How can we uniquely position ourselves, capture a distinctive market space, and eclipse competitors?
          </Typography>
        </Box>
      )}
      {currentPage === 2 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1.5,
            }}
          >
            Sample questions for commercial negotiations and strategies
          </Typography>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 2,
            }}
          >
            Commercial negotiations:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={1}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 0.5,
                fontStyle: "italic",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                  mb: 0.5,
                }}
              >
                Please provide a negotiation strategy for this vendor. Below are the details of the situation:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                  mb: 0.5,
                }}
              >
                Our Goals: We aim to secure a vendor for critical equipment, supporting our $500M capital project. The
                vendor must be reliable and within budget to ensure on-time delivery.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                  mb: 0.5,
                }}
              >
                Minimum Acceptable Terms: The vendor should at least match 95% of our technical requirements and be
                within 5% of our budget.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                  mb: 0.5,
                }}
              >
                Current Vendor Profile: The vendor is the largest manufacturer in this industry, holding a 60% market
                share. However, they are currently supporting many large projects, leading to substantial delays in
                delivery in the past. Their pricing is 15% higher than the next highest vendor.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                  mb: 0.5,
                }}
              >
                Next best alternatives: We have two alternative vendors, but they present higher risks in terms of
                delivery time.
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          paddingY: 2,
          justifyContent: "space-between",
        }}
      >
        {currentPage === 2 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              setCurrentPage(1);
            }}
          >
            Previous
          </Button>
        )}
        {currentPage === 1 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              setCurrentPage(2);
            }}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default StrategyAssistant;
