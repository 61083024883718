import * as React from "react";
import { Card, Typography, Box, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { normalizeStr } from "src/libs/hooks";

const styles = {
  typographyStyle: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    mt: 1,
  },
  iconStyle: { fontSize: 18, color: "green", mr: 1 },
};

const PlanCard = ({ teamPlanId, plan, handleSubscribe }) => {
  const planType = normalizeStr(plan?.type);

  return (
    <Card sx={{ width: 270, p: 1, boxShadow: "none" }}>
      <Box
        height="120px"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box textAlign="center">
          <Box>
            <Typography variant="h6">{plan.name}</Typography>
          </Box>
          <Box mt={2}>
            {planType !== "enterprise" && (
              <Typography
                variant="h6"
                textAlign="center"
              >
                ${plan.monthly_cost} /month
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          mt={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            variant={teamPlanId === plan.id ? "outlined" : "contained"}
            style={{ textTransform: "none" }}
            onClick={() => handleSubscribe(plan.id, planType)}
          >
            {teamPlanId === plan.id ? "View Current Plan" : planType === "enterprise" ? "Contact Us" : "Subscribe Now"}
          </Button>
        </Box>
      </Box>
      <Box mt={2}>
        <Box className="card-plan-detail">
          <Typography
            variant="h6"
            sx={styles.typographyStyle}
          >
            <CheckCircleIcon sx={styles.iconStyle} />
            {planType === "enterprise" ? (
              <strong>Unlimited data</strong>
            ) : (
              <React.Fragment>
                <strong>{plan?.characters?.toLocaleString()}</strong>
                &nbsp;characters
              </React.Fragment>
            )}
          </Typography>
          <Typography
            variant="h6"
            sx={styles.typographyStyle}
          >
            <CheckCircleIcon sx={styles.iconStyle} />
            {planType === "enterprise" ? (
              <strong>Significantly large files</strong>
            ) : (
              <React.Fragment>
                <strong>{plan.max_file_size}</strong>&nbsp;MB/file (e.g. PDF)
              </React.Fragment>
            )}
          </Typography>
          <Typography
            variant="h6"
            sx={styles.typographyStyle}
          >
            <CheckCircleIcon sx={styles.iconStyle} />
            {planType === "enterprise" ? (
              <strong>Unlimited queries</strong>
            ) : (
              <React.Fragment>
                <strong>{plan.query_per_month.toLocaleString()}</strong>
                &nbsp;queries/month
              </React.Fragment>
            )}
          </Typography>
          <Typography
            variant="h6"
            sx={styles.typographyStyle}
          >
            <CheckCircleIcon sx={styles.iconStyle} />
            {planType === "enterprise" ? (
              <strong>Custom chat history retention</strong>
            ) : (
              <React.Fragment>
                Chat history up to&nbsp;
                <strong>{plan.chat_history_duration}</strong>&nbsp;days
              </React.Fragment>
            )}
          </Typography>
          <Typography
            variant="h6"
            sx={styles.typographyStyle}
          >
            <CheckCircleIcon sx={styles.iconStyle} />
            {planType === "enterprise" ? (
              <strong>Unlimited storage</strong>
            ) : (
              <React.Fragment>
                <strong>{plan.file_storage}</strong>&nbsp;GB storage
              </React.Fragment>
            )}
          </Typography>
          <Typography
            variant="h6"
            sx={styles.typographyStyle}
          >
            <CheckCircleIcon sx={styles.iconStyle} />
            {planType === "enterprise" ? (
              <strong>Unlimited members</strong>
            ) : (
              <React.Fragment>
                {planType === "individual" ? `1 team member` : `Up to ${plan.max_member} team members`}
              </React.Fragment>
            )}
          </Typography>
          <Typography
            variant="h6"
            sx={styles.typographyStyle}
          >
            <CheckCircleIcon sx={styles.iconStyle} />
            Unlimited projects
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default PlanCard;
