import { handleError } from "src/libs/hooks"
import api from "../api"
import { IFolder } from "src/libs/models"

export const createFolderFile = async (projectId: number, teamId: number, folderName: string) => {
  try {
    const res = await api.post(`/a/${teamId}/project/${projectId}/create_folder`, { folder_name: folderName })
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const uploadFileByFolder = async (projectId: number, teamId: number, files: File[], folder: string) => {
  const formData = new FormData();
  formData.append("folder", folder)

  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i])
  }

  try {
    const res = await api.post(`/a/${teamId}/project/${projectId}/upload_file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const getAllFolder = async (teamId: number, projectId: number): Promise<IFolder | undefined> => {
  try {
    const res = await api.get(`/a/${teamId}/project/${projectId}/get_folders`)
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const getAllFolderByFolderId = async (folderId: number, teamId: number) => {
  try {
    const res = await api.get(`/a/${teamId}/project/folder/${folderId}`)
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const deleteFileOrFolder = async (teamId: number, projectId: number, path: string) => {
  try {
    const res = await api.post(`/a/${teamId}/project/${projectId}/delete_file`, { path })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}