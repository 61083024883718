import api from "../api"
import { IAccept, ILogin } from "src/libs/models"
import { handleError } from "src/libs/hooks"
import axiosClient from "../axiosClient"


export const register = async (credentials: { email: string, password: string, invitation_id: string, terms_agreement: boolean }): Promise<string | undefined> => {
  // eslint-disable-next-line no-unused-vars
  const truthyValues = Object.fromEntries(Object.entries(credentials).filter(([_, value]) => value));
  try {
    await axiosClient.post("/signup", truthyValues)
    return "Register successful"
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const validateEmail = async (code: string, email: string, invitation_id: string): Promise<ILogin | undefined> => {
  let payload: any
  if (invitation_id) {
    payload = {
      email,
      code,
      invitation_id
    }
  } else {
    payload = {
      email,
      code
    }
  }

  try {
    const res = await axiosClient.post("/email-confirmation", payload)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const loginApi = async (credentials: { email: string, password: string }): Promise<ILogin | undefined> => {
  try {
    const res = await axiosClient.post("/login", credentials)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const forgotPassword = async (email: string): Promise<string | undefined> => {
  try {
    const res = await axiosClient.post("/forgot-password", { email })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const confirmForgotPassword = async (reset_email: string, code: string, password: string): Promise<string | undefined> => {
  try {
    const res = await axiosClient.post("/reset-password", { reset_email, code, password })
    return res.data?.detail || "Change Password Successful"
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const changePassword = async (account: { old_password: string, new_password: string, confirm_password: string }): Promise<ILogin | undefined> => {
  try {
    const res = await api.post("/users/change-password", { oldpassword: account.old_password, password1: account.new_password, password2: account.confirm_password })
    return res?.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const loginGoogle = async (userData: { sub: string, email: string, firstName: string, lastName: string, avatar: string, invitation_id: string }): Promise<ILogin | undefined> => {

  let payload: any

  if (userData.invitation_id) {
    payload = {
      sub: userData.sub, email: userData.email, firstName: userData.firstName, lastName: userData.lastName, avatar: userData.avatar, provider: 'google', invitation_id: userData.invitation_id
    }
  } else {
    payload = {
      sub: userData.sub, email: userData.email, firstName: userData.firstName, lastName: userData.lastName, avatar: userData.avatar, provider: 'google'
    }
  }

  try {
    const res = await axiosClient.post("/google-auth", payload)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const loginLinkedIn = async (code: string, callbackUrl: string, invitation_id: string): Promise<ILogin | undefined> => {
  let payload: any
  if (invitation_id) {
    payload = {
      code,
      callback: callbackUrl,
      invitation_id
    }
  } else {
    payload = {
      code,
      callback: callbackUrl,
    }
  }
  try {
    const res = await axiosClient.post("/linkedin-auth", payload)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const resendConfirmEmail = async (email: string): Promise<string | undefined> => {
  try {
    const res = await axiosClient.post("/resend-email-confirmation", { email })
    return res.data?.detail
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const getAccessTokenByRefreshToken = async (refresh: string): Promise<string | undefined> => {
  try {
    const res = await axiosClient.post("/api/token/refresh", { refresh })
    return res.data?.access
  } catch (error) {
    console.warn(error)
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
  }
  return undefined
}

export const logoutAccount = async () => {
  try {
    await api.post("/logout")
  } catch (error) {
    console.warn(error)
  }
}

export const redirectPage = async (code: string): Promise<IAccept | undefined> => {
  try {
    const res = await api.post(`/teams/invitations/accept`, { code })
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}


export const tokenGmail = async (teamId: number) => {
  try {
    const res = await api.get(`/a/${teamId}/gmail`)
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const uploadDocumentCloud = async (teamId: number, projectId: number, conversationId: string, documents: { name: string, file_id: string }[], provider: string) => {
  try {
    const res = await api.post(`/a/${teamId}/project/${projectId}/conversations/${conversationId}/upload_document_cloud`, { documents, provider })
    return res.data
  } catch (error) {
    handleError(error)
    console.warn(error)
    return undefined
  }
}

export const fetchGmailAuthUrl = async () => {
  try {
    const res = await api.get(`/a/0/token/get_gmail_auth_url`)
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const fetchOutlookAuthUrl = async () => {
  try {
    const res = await api.get(`/a/0/token/get_outlook_auth_url`)
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const checkIntegrationStatus = async (integration_type: string, provider: string, teamId: number) => {
  try {
    const res = await api.get(`/a/${teamId}/integration-status?integration_type=${integration_type}&provider=${provider}`)
    return res.data
  } catch (error) {
    console.error(error)
    return undefined
  }
}

// linkedin
export const fetchLinkedInAuthURL = async () => {
  try {
    const res = await api.get("/a/0/token/get_linkedin_social_auth_url")
    return res.data
  } catch (error) {
    handleError(error)
    console.error(error)
    return undefined
  }
}

export const fetchTokenLinkedIn = async (code: string, state: string) => {
  try {
    const res = await api.post("/a/0/linkedin-token", { code, state })
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

// facebook
export const fetchFacebookAuthURL = async () => {
  try {
    const res = await api.get("/a/0/token/get_facebook_social_auth_url")
    return res.data
  } catch (error) {
    handleError(error)
    console.error(error)
    return undefined
  }
}

export const fetchTokenFacebook = async (code: string, state: string) => {
  try {
    const res = await api.post("/a/0/facebook-token", { code, state })
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

// all

export const fetchSocialAuthURL = async (integration_type: string, provider: string) => {
  try {
    const res = await api.get(`/a/0/token/get_integration_auth_url?integration_type=${integration_type}&provider=${provider}`)
    return res.data
  } catch (error) {
    handleError(error)
    console.error(error)
    return undefined
  }
}

export const fetchTokenSocial = async (code: string, state: string) => {
  try {
    const res = await api.post("/a/0/facebook-token", { code, state })
    return res.data
  } catch (error) {
    handleError(error)
    console.error(error)
    return undefined
  }
}