import { useState } from "react";
import { Box, Button } from "@mui/material";

import { SampleQuestionIcon } from "src/assest/icons/AssistantIcon";
import { getAssistantNameByCurrentURL } from "src/libs/hooks";

import Hiring from "./Question/Hiring";
import WebInsights from "./Question/WebInsights";
import DataAnalytics from "./Question/DataAnalytics";
import AnnualReports from "./Question/AnnualReports";
import VisualizeIdeas from "./Question/VisualizeIdeas";
import GenerateCreate from "./Question/GenerateCreate";
import ContentCreation from "./Question/ContentCreation";
import DocumentAnalysis from "./Question/DocumentAnalysis";
import TalentSelection from "./Question/TalentSelection";
import VendorAssessment from "./Question/VendorAssessment";
import AboutHrAssistant from "./AboutHrAssistant";
import ContractAnalysis from "./Question/ContractAnalysis";
import StrategyAssistant from "./Question/StrategyAssistant";
import MarketingAnalytics from "./Question/MarketingAnalytics";
import MarketIntelligence from "./Question/MarketIntelligence";
import FinancialAnalytics from "./Question/FinancialAnalytics";
import PerformanceAnalytics from "./Question/PerformanceAnalytics";
import AboutBusinessAssistant from "./AboutBusinessAssistant";
import AboutMarketingAssistant from "./AboutMarketingAssistant";
import AboutFinancialAssistant from "./AboutFinancialAssistant";
import AboutSupplyChainAssistant from "./AboutSupplyChainAssistant";
import DataAnalyticsSupply from "./Question/DataAnalyticsSupply";

interface SampleQuestionProps {
  currentAssistant: string;
}

const SampleQuestion: React.FC<SampleQuestionProps> = ({ currentAssistant }) => {
  // state
  // sample_question - assistant_name
  const [currentButton, setCurrentButton] = useState<string>("assistant_name");

  const currentToolName = getAssistantNameByCurrentURL(window.location.pathname);

  return (
    <Box
      component="div"
      sx={{
        height: "calc(100vh - 170px)",
        width: "400px",
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "0px",
      }}
      className="hide-on-mobile"
    >
      <Box
        component="div"
        sx={{
          flexGrow: currentButton === "assistant_name" ? 1 : 0,
          height: currentButton === "assistant_name" ? "100%" : "0px",
          overflow: currentButton === "assistant_name" ? "auto" : "hidden",
          transition: "height 0.5s",
        }}
      >
        {currentAssistant === "hr_assistant" && <AboutHrAssistant />}
        {currentAssistant === "marketing_assistant" && <AboutMarketingAssistant />}
        {currentAssistant === "supply_chain_assistant" && <AboutSupplyChainAssistant />}
        {currentAssistant === "financial_assistant" && <AboutFinancialAssistant />}
        {currentAssistant === "business_assistant" && <AboutBusinessAssistant />}
      </Box>
      <Box
        component="div"
        sx={{
          flexGrow: currentButton === "sample_question" ? 1 : 0,
          height: currentButton === "sample_question" ? "100%" : "0px",
          transition: "height 0.5s",
          overflow: currentButton === "sample_question" ? "auto" : "hidden",
        }}
      >
        {currentToolName.id === "document_analysis" && <DocumentAnalysis />}
        {currentToolName.id === "web_insights" && <WebInsights />}
        {currentToolName.id === "data_analytics" && <DataAnalytics />}
        {currentToolName.id === "visualize_ideas" && <VisualizeIdeas />}
        {currentToolName.id === "generate_create" && <GenerateCreate />}
        {currentToolName.id === "market_intelligence" && <MarketIntelligence />}
        {currentToolName.id === "content_creation" && <ContentCreation />}
        {currentToolName.id === "marketing_analytics" && <MarketingAnalytics />}
        {currentToolName.id === "hiring" && <Hiring />}
        {currentToolName.id === "talent_selection" && <TalentSelection />}
        {currentToolName.id === "performance_analytics" && <PerformanceAnalytics />}
        {currentToolName.id === "vendor_assessment" && <VendorAssessment />}
        {currentToolName.id === "contract_analysis" && <ContractAnalysis />}
        {currentToolName.id === "data_analytics_supply" && <DataAnalyticsSupply />}
        {currentToolName.id === "strategy_assistant" && <StrategyAssistant />}
        {currentToolName.id === "financial_analytics" && <FinancialAnalytics />}
        {currentToolName.id === "annual_reports" && <AnnualReports />}
      </Box>
      <Box
        component="div"
        sx={{
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Button
          sx={{
            width: "100%",
            fontSize: "12px",
            textTransform: "capitalize",
            border: currentButton === "assistant_name" ? "1px solid transparent" : "1px solid #cccccc",
            backgroundColor: currentButton === "assistant_name" ? "#cccccc" : "transparent",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#cccccc",
            },
          }}
          onClick={() => setCurrentButton("assistant_name")}
          // variant={currentButton === "assistant_name" ? "contained" : "outlined"}
        >
          {currentToolName.name}
        </Button>
        <Button
          sx={{
            width: "100%",
            fontSize: "12px",
            textTransform: "capitalize",
            border: currentButton === "sample_question" ? "1px solid transparent" : "1px solid #cccccc",
            color: "#000000",
            backgroundColor: currentButton === "sample_question" ? "#cccccc" : "transparent",
          }}
          onClick={() => setCurrentButton("sample_question")}
        >
          <SampleQuestionIcon />
          Sample Questions
        </Button>
      </Box>
    </Box>
  );
};

export default SampleQuestion;
