// AboutSupplyChainAssistant

import React from "react";
import { Typography, Box } from "@mui/material";
import { FaRegShareFromSquare } from "react-icons/fa6";

const ABOUT_HR_ASSISTANT = [
  {
    id: 1,
    content:
      "Create a new project for managing extensive marketing campaign or market intelligence initiatives (optional).",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Create a new project for large scale sourcing events or supply chain initiatives. (optional).
      </Typography>
    ),
  },
  {
    id: 2,
    content: "Start a new chat session for a project component or smaller tasks.",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Initiate separate chat sessions for each sourcing phases or smaller events.
      </Typography>
    ),
  },
  {
    id: 3,
    content: "Team members can all join in shared chat sessions.",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Team members can all join in shared chat sessions.
      </Typography>
    ),
  },
  // {
  //   id: 4,
  //   content: "Team members can all join in shared chat sessions.",
  //   contentHTML: (
  //     <Typography
  //       sx={{
  //         fontSize: "12px",
  //         fontWeight: "100",
  //         mt: 1,
  //       }}
  //     >
  //       I need some ideas for negotiation strategies.
  //     </Typography>
  //   ),
  // },
  // {
  //   id: 5,
  //   content: "Team members can all join in shared chat sessions.",
  //   contentHTML: (
  //     <Typography
  //       sx={{
  //         fontSize: "12px",
  //         fontWeight: "100",
  //         mt: 1,
  //       }}
  //     >
  //       I am looking for ideas on developing business strategies.
  //     </Typography>
  //   ),
  // },
  {
    id: 6,
    content: "",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Click on (<FaRegShareFromSquare fontSize="12px" />
        &nbsp;share) Share in each response to Edit and Share via email, or posted on LinkedIn, Facebook Page or X
        (Twitter).
      </Typography>
    ),
  },
];

const AboutSupplyChainAssistant = () => {
  return (
    <React.Fragment>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: 1,
        }}
      >
        Supply Chain Assistants
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Supply Chain Assistants provide support in evaluating vendor proposals, analyzing contracts, conducting supply
        chain analytics, and developing commercial and negotiation strategies.
      </Typography>

      <Box
        component="ul"
        sx={{
          paddingLeft: 2,
        }}
      >
        {ABOUT_HR_ASSISTANT.map((a) => (
          <Box
            key={`about-hr-assistant-${a.id}`}
            component="li"
            sx={{}}
          >
            {a.contentHTML}
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default AboutSupplyChainAssistant;
