import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

import PaymentModal from "src/components/Payment/PaymentModal";
import SubscriptionModal from "src/components/Subscription/SubscriptionModal";
import { UpgradePlansModal } from "src/components/Header";
import { getPaymentByTeam } from "src/libs/axios/api/payment";
import { addErrorResponse } from "src/store/features/error_response";

const UpgradeSubscriptionPage = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const urlCallback = searchParams.get("url_callback");
  const teamIdUrl = searchParams.get("teamId");

  // state
  const [open, setOpen] = useState(true);
  const [teamId, setTeamId] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [payment, setPayment] = useState(null);

  // function
  const handleAddPayMethod = async () => {
    if (!teamId) return;
    const res: any = await getPaymentByTeam(teamId.toString());
    if (res) {
      setPayment(res);
      setOpenSubscribeModal(true);
    }
  };

  const handleSetPlan = (planId) => {
    setPlanId(planId);
  };

  const handleCallback = () => {
    toast.success("Upgrade successful!", {
      position: "bottom-right",
    });

    setOpenSubscribeModal(false);
    setOpen(false);
    navigate(`${urlCallback}`);
  };

  const handleClose = (value: boolean) => {
    setOpen(value);
    dispatch(addErrorResponse(null));
    navigate(`${urlCallback}`);
  };

  // useEffect
  useEffect(() => {
    if (teamIdUrl) {
      setTeamId(Number(teamIdUrl));
    }
  }, [teamIdUrl]);

  return (
    <>
      <UpgradePlansModal
        open={open}
        setOpen={handleClose}
        teamId={teamId ? teamId.toString() : ""}
        setTeamId={setTeamId}
        setPlanId={handleSetPlan}
        setOpenPaymentModal={setOpenPaymentModal}
        setOpenSubscribeModal={setOpenSubscribeModal}
      />

      <PaymentModal
        onClick={() => {}}
        teamId={teamId ? teamId.toString() : ""}
        open={openPaymentModal}
        setOpen={setOpenPaymentModal}
        callback={handleAddPayMethod}
      />

      <SubscriptionModal
        planId={planId}
        teamId={teamId ? teamId.toString() : ""}
        payment_channel={payment ? payment.payment_channel : ""}
        payment_data={payment ? payment.payment_data : ""}
        open={openSubscribeModal}
        setOpen={setOpenSubscribeModal}
        callback={handleCallback}
      />
    </>
  );
};

export default UpgradeSubscriptionPage;
