import { FaBell } from "react-icons/fa6";
import { useState, useRef, useEffect } from "react";
import { Tooltip, Button, Stack, Menu, Box, Typography, Switch } from "@mui/material";

import { INotificationModel, IUser } from "src/libs/models";
import { BodyText } from "src/libs/AppStyle";
import { getMyProfile } from "src/libs/axios/api/user";
import { getListNotification, markAsReadNotification } from "src/libs/axios/api/notifications";

type NotificationType = {
  id: number | null;
  event: string;
  message: string;
  subject: string;
  read: boolean;
};

const Notifications = () => {
  const notificationWS = useRef<WebSocket | null>(null);
  const notificationTeamWS = useRef<WebSocket | null>(null);

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [listNotification, setListNotification] = useState<NotificationType[]>([]);

  // functions
  const handleGetSubjectAndMessageByEvent = (event: INotificationModel) => {
    switch (event?.message) {
      case "EVENT_CREATE_NEW_CHAT":
        return {
          message: "New session added by other team member",
          subject: event?.data?.message?.data?.team_name,
        };
      case "change_subscription_plan":
        return {
          message: "Changes in subscription plan",
          subject: event?.data?.message?.data?.team_name,
        };
      case "new_team_member_join":
        return {
          message: "New team member joined",
          subject: event?.data?.message?.data?.team_name,
        };
      case "remain_low_credit":
        return {
          message: "Your credit is running low. Please upgrade your plan to continue using the service.",
          subject: event?.data?.message?.data?.team_name,
        };
      default:
        return {
          message: "N/A",
          subject: event?.data?.message?.data?.team_name,
        };
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchListNotifications = async () => {
    const res = await getListNotification();

    if (res) {
      let newNotification: NotificationType[] = [];

      for (let i = 0; i < res.length; i++) {
        const r = res[i];

        if (r.message !== "EVENT_UPDATE_CHAT" && r.message !== "EVENT_DELETE_CHAT") {
          const e = handleGetSubjectAndMessageByEvent(r);

          newNotification.push({
            id: r.id,
            event: r.message,
            message: e.message,
            subject: e.subject,
            read: r.is_read,
          });
        }
      }

      setListNotification([...newNotification]);
    }
  };

  const updateMarkAsRead = async (id: number) => {
    setListNotification((prev) => prev.map((item) => (item.id === id ? { ...item, read: true } : item)));

    await markAsReadNotification(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeMarkAsRead = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (e.target.checked) {
      updateMarkAsRead(id);
    }
  };

  const getUerInfo = async () => {
    const res = await getMyProfile();
    if (res) {
      const { first_name, email, id } = res;
      localStorage.setItem("userInfo", JSON.stringify({ name: first_name, email, id }));
      setCurrentUser({ ...res });
    }
  };

  const connectToNotificationWS = () => {
    notificationWS.current = new WebSocket("wss://api.platform.neuralpit.com/ws/platform/");
    notificationTeamWS.current = new WebSocket(`wss://api.platform.neuralpit.com/ws/team/user/${currentUser.id}/`);

    notificationWS.current.onopen = () => {};

    notificationTeamWS.current.onopen = () => {
      console.log("connected to team notification");
    };

    notificationWS.current.onmessage = (e) => {
      try {
        const dataWS = JSON.parse(e.data);

        setListNotification((prev) => [
          ...prev,
          {
            ...dataWS?.message,
            read: false,
          },
        ]);
      } catch (error) {
        console.error(error);
      }
    };

    notificationTeamWS.current.onmessage = (e) => {
      try {
        const dataWS = JSON.parse(e.data);
        console.log(dataWS);

        if (dataWS?.message?.event === "EVENT_CREATE_NEW_CHAT") {
          const message = {
            id: null,
            event: "EVENT_CREATE_NEW_CHAT",
            message: "New session added by other team member",
            subject: dataWS?.message?.data?.team_name,
            read: false,
          };

          setListNotification((prev) => [...prev, message]);
        } else if (dataWS?.message?.event === "change_subscription_plan") {
          const message = {
            id: null,
            event: "change_subscription_plan",
            message: "Changes in subscription plan",
            subject: dataWS?.message?.data?.team_name,
            read: false,
          };

          setListNotification((prev) => [...prev, message]);
        } else if (dataWS?.message?.event === "new_team_member_join") {
          const message = {
            id: null,
            event: "new_team_member_join",
            message: "New team member joined",
            subject: dataWS?.message?.data?.team_name,
            read: false,
          };

          setListNotification((prev) => [...prev, message]);
        } else if (dataWS?.message?.event === "remain_low_credit") {
          const message = {
            id: null,
            event: "remain_low_credit",
            message: "Your credit is running low. Please upgrade your plan to continue using the service.",
            subject: dataWS?.message?.data?.team_name,
            read: false,
          };

          setListNotification((prev) => [...prev, message]);
        }
      } catch (error) {
        console.error(error);
      }
    };
  };

  const open = Boolean(anchorEl);
  const countUnread = listNotification?.filter((item) => !item.read)?.length || 0;

  // effect
  useEffect(() => {
    fetchListNotifications();
    getUerInfo();
  }, []);

  useEffect(() => {
    if (currentUser) {
      connectToNotificationWS();
    }
  }, [currentUser]);

  return (
    <>
      <Tooltip title="Notification on platform">
        <Button
          sx={{
            padding: 0,
            paddingX: "5px",
            color: "#737373",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#1672c9",
              "& svg": {
                color: "#1672c9",
              },
            },
          }}
          onClick={handleClick}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "relative",
            }}
          >
            <FaBell size={25} />
            <BodyText
              variant="caption"
              sx={{
                fontSize: "12px !important",
                textTransform: "capitalize",
                color: "black",
                "&:hover": {
                  color: "#1672c9",
                },
              }}
            >
              Notifications
            </BodyText>
            {countUnread > 0 && (
              <Box
                component="div"
                sx={{
                  position: "absolute",
                  top: "-10px",
                  right: "15px",
                  fontSize: "14px",
                  color: "#1672c9",
                  fontWeight: "bold",
                }}
              >
                {countUnread}
              </Box>
            )}
          </Stack>
        </Button>
      </Tooltip>

      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          component="div"
          sx={{
            paddingX: "8px",
            width: "300px",
            height: "400px",
            overflowY: "auto",
          }}
        >
          <Typography
            fontSize="24px"
            fontWeight="600"
            mb="10px"
          >
            Notifications
          </Typography>
          <Box
            component="ul"
            sx={{
              padding: 0,
            }}
          >
            {listNotification?.length > 0 &&
              listNotification.map((notification, index) => (
                <Box
                  key={`item-notification-${index}`}
                  component="li"
                  sx={{
                    mb: "6px",
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {notification?.subject}
                    </Typography>
                    {notification?.id && (
                      <Switch
                        size="small"
                        checked={notification.read}
                        disabled={false}
                        onChange={(e) => handleChangeMarkAsRead(e, notification.id)}
                      />
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {notification?.message}
                  </Typography>
                </Box>
              ))}
            {listNotification?.length === 0 && (
              <Box
                component="li"
                sx={{
                  textAlign: "center",
                  paddingY: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  No notification
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default Notifications;
