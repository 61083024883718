import * as React from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from "@mui/material/Tooltip";
import toast from "react-hot-toast";

import { IFolder, IStruct } from "src/libs/models";
import { deleteFileOrFolder } from "src/libs/axios/api/uploadFile";
import { getListFileByPath, getRootPaths, renderPathFolder } from "src/libs/hooks";

interface TreeViewElementProps {
  teamId: number;
  projectId: number;
  isShowDelete?: boolean;
  data: IStruct;
  setFiles: Function;
  setPath?: Function;
  callback?: Function;
}

export default function TreeViewElement({
  teamId,
  projectId,
  isShowDelete = false,
  data,
  setFiles,
  setPath,
  callback,
}: TreeViewElementProps) {
  const rootPath = data.path;
  // state
  const [currentPath, setCurrentPath] = React.useState("");

  // function
  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setCurrentPath(nodeIds.toString());
    const res = getListFileByPath(data, nodeIds.toString());
    setFiles(res);
    if (setPath) {
      setPath(nodeIds.toString().slice(rootPath.length, nodeIds.toString().length - 1));
    }
  };

  const handleDeleteFolder = async (path: string) => {
    const res = await deleteFileOrFolder(teamId, projectId, path);
    if (res) {
      toast.success("Delete Folder Successful");
      if (callback) {
        callback();
      }
    }
  };

  const renderTree = (nodes: IFolder, isShowDelete: boolean) => (
    <TreeItem
      key={nodes.path}
      nodeId={nodes.path.toString()}
      label={
        <Box
          sx={{
            display: "flex",
            height: "40px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontSize: "14px",
            }}
          >
            {renderPathFolder(nodes.path, rootPath)}
          </Typography>
          {nodes.path !== rootPath && !isShowDelete && (
            <Tooltip title="Delete folder">
              <DeleteForeverIcon
                fontSize="small"
                onClick={() => handleDeleteFolder(nodes.path.slice(rootPath.length))}
              />
            </Tooltip>
          )}
        </Box>
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children
            .filter((child) => child.type === "Folder")
            .map((node) => {
              const cloneObject = { ...node };

              if (cloneObject.children) {
                const folders = cloneObject.children.filter((c) => c.type === "Folder");
                cloneObject.children = folders;
              }

              return renderTree(cloneObject, isShowDelete);
            })
        : null}
    </TreeItem>
  );

  React.useEffect(() => {
    if (data) {
      const res = getListFileByPath(data, currentPath);
      setFiles(res);
    }
  }, [data]);

  React.useEffect(() => {
    if (rootPath) {
      const res = getListFileByPath(data, rootPath);
      setFiles(res);
    }
  }, [rootPath]);

  const roots = getRootPaths(data);

  return (
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultSelected={[rootPath]}
      sx={{ flexGrow: 1, maxWidth: 400 }}
      onNodeSelect={handleSelect}
      defaultExpanded={roots}
    >
      {renderTree(data, isShowDelete)}
    </TreeView>
  );
}
