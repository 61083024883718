import { TableRow, TableCell, tableCellClasses, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography/Typography";
import styled from "styled-components";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

export const LinkRouter = styled(Link)`
  color: inherit;
  text-decoration: none;
  line-height: 22px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
`;

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f2f2f2",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  height: "46px",
  padding: "0 15px",
  fontSize: "14px",
  lineHeight: "20px",
  fontFamily: "'Inter', sans-serif !important",
}));

export const HeadingText = styled(Typography)(() => ({
  fontSize: "30px",
  lineHeight: "50px",
  fontWeight: 700,
  color: "black",
}));

export const TitleText = styled(Typography)(() => ({
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: 600,
  color: "black",
}));

export const BodyText = styled(Typography)(() => ({
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: 400,
  color: "black",
  display: "block",
}));

export const SpanText = styled(Typography)(() => ({
  fontSize: "12px",
  color: "#fff",
}));

export const LiText = styled(Box)(() => ({
  fontSize: "12px",
  color: "#fff",
  marginBottom: "8px",
}));

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    style={{
      inset: "0px 0px auto 0px",
      padding: 0,
      maxWidth: "auto",
    }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 500,
    backgroundColor: "black",
    maxWidth: 800,
    padding: "24px",
  },
});

export const SPAN = styled.span`
  font-size: 14px;
  font-weight: 400;
`;
export const NormalCustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    style={{
      inset: "0px 0px auto 0px",
      padding: 0,
      maxWidth: "auto",
    }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 300,
    backgroundColor: "black",
    maxWidth: 800,
    padding: "8px",
  },
});
