import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Typography, Stack, FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";

import { INewSession } from "src/libs/models/assistant";
import { ModalNoAction } from "src/components/common";
import { getListTeamByPage } from "src/libs/axios/api/team";
import { getListProjectByTeam } from "src/libs/axios/api/project";
import { ISelectBox, SelectModel } from "src/libs/models";
import { BodyText, NormalCustomTooltip } from "src/libs/AppStyle";
import { renderURLByCurrentURLAndSessionHR } from "src/libs/hooks";
import { ProjectIcon, SettingWorkstationIcon, TeamMemberIcon } from "src/assest/icons";

import UserGuide from "../../UserGuide";
import Assistant from "../Assistant";

interface TopHrAssistantProps {
  currenSession: INewSession | null;
  currentProject: SelectModel | null;
  listGroupProjectByTeam: ISelectBox[];
  defaultProjectWidth: string;
  setListGroupProjectByTeam: Function;
  setCurrentProject: Function;
}

const LIST_HR_ASSISTANT_TOOLS = [
  {
    id: 5,
    titleHTML: <span>Hiring</span>,
    hover: "Craft job postings and tailored interview questions",
    shortPath: "chat-hiring",
    alt: "Chat Hiring",
  },
  {
    id: 6,
    titleHTML: (
      <span>
        Talent <br />
        Selection
      </span>
    ),
    hover: "Review and extract insights from resumes and interview videos",
    shortPath: "talent-selection",
    alt: "Chat Talent Selection",
  },
  {
    id: 7,
    titleHTML: (
      <span>
        Performance <br />
        Analytics
      </span>
    ),
    hover: "Analyze and visualize HR data",
    shortPath: "performance-analytics",
    alt: "Chat Performance Analytics",
  },
  {
    id: 8,
    titleHTML: (
      <span>
        Interactive <br />
        Learning
      </span>
    ),
    hover: "Interactive and tailored learning and development",
    shortPath: "interactive-learning",
    alt: "Chat Interactive Learning",
  },
];

const TopHrAssistant: React.FC<TopHrAssistantProps> = ({
  currentProject,
  setCurrentProject,
  currenSession,
  listGroupProjectByTeam,
  defaultProjectWidth,
  setListGroupProjectByTeam,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const mobileScreen = useMediaQuery("(max-width:768px)");

  // state
  const [open, setOpen] = useState(false);
  const [isProjectLoading, setIsProjectLoading] = useState(false);

  // functions
  const setProject = (id: number, list: ISelectBox[], project: any = null) => {
    let current;
    for (const team of list) {
      const selectedProject = team.projects.find((project) => project.id === id);
      if (selectedProject) {
        current = {
          id: `${team.teamId}-${selectedProject.id}`,
          name: `${team?.teamName}-${selectedProject.name}`,
          teamId: team.teamId,
          projectId: selectedProject.id,
          about: selectedProject.objective,
          projectName: selectedProject.name,
          teamName: team?.teamName,
          members: team?.members,
        };
      }
    }
    if (current) {
      setCurrentProject({ ...current });
      localStorage.setItem("current_project", JSON.stringify(current));
    } else {
      if (project) {
        setCurrentProject({ ...project });
        localStorage.setItem("current_project", JSON.stringify(project));
      }
    }
  };

  const handleChangeProject = (id: number) => {
    setProject(id, listGroupProjectByTeam);

    const currentURLSplit = location.pathname.split("/");
    const currentToolURL = currentURLSplit[2];

    navigate(`/hr-assistant/${currentToolURL}/0`);
  };

  const getTeams = async () => {
    setIsProjectLoading(true);
    const res = await getListTeamByPage();
    if (res?.results?.length > 0) {
      const listPromise = res.results.map((team) => getListProjectByTeam(1, team.id));

      Promise.all(listPromise)
        .then((results) => {
          setIsProjectLoading(false);
          const resProject = results.map((r) => r.results);
          const groupProjects: ISelectBox[] = [];

          res.results.forEach((team) => {
            const projects = resProject.find((r) => r[0]?.team === team.id);
            const groupProject: ISelectBox = {
              teamId: team.id,
              teamName: team.name,
              projects: projects || [],
              members: team?.members || [],
            };
            groupProjects.push(groupProject);

            if (team.is_default && projects) {
              const defaultProject = projects.find((p) => p.is_default);
              if (defaultProject) {
                const current = {
                  id: `${team.id}-${defaultProject.id}`,
                  name: `${team.name}-${defaultProject.name}`,
                  teamId: team.id,
                  projectId: defaultProject.id,
                  about: defaultProject.objective,
                  projectName: defaultProject.name,
                  teamName: team.name,
                  members: team?.members,
                };
                if (!localStorage.getItem("current_project")) {
                  setCurrentProject({ ...current });
                  localStorage.setItem("current_project", JSON.stringify(current));
                }
              }
            }
          });

          if (localStorage.getItem("current_project")) {
            const project = JSON.parse(localStorage.getItem("current_project"));
            if (project) {
              setCurrentProject({ ...project });
            }
          }

          setListGroupProjectByTeam(groupProjects);
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  };

  // useEffect
  useEffect(() => {
    getTeams();
  }, []);

  return (
    <>
      <Box
        component="div"
        sx={{
          height: `${mobileScreen ? "120px" : "60px"}`,
          display: "flex",
          alignItems: "center",
        }}
        className="bottom-shadow-header flex-direction-responsive"
      >
        {!mobileScreen && (
          <>
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                minWidth: "250px",
                flex: `0 0 ${defaultProjectWidth}`,
              }}
            >
              <Button
                sx={{
                  padding: 0,
                  minWidth: "auto",
                  color: "#737373",
                  "& :hover": {
                    color: "#1672c9",
                  },
                }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <SettingWorkstationIcon />
              </Button>
              <Box component="div">
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: 1,
                    marginBottom: "8px",
                  }}
                >
                  Team: {currentProject?.teamName}
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: 1,
                  }}
                >
                  Project: {currentProject?.projectName}
                </Typography>
              </Box>
            </Box>

            <Box
              component="div"
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="div"
                  sx={{
                    width: "220px",
                  }}
                >
                  <Assistant />
                </Box>
                <Stack
                  alignItems="center"
                  gap={2}
                  flexDirection="row"
                  borderLeft="2px solid #737373"
                  paddingLeft={2}
                >
                  {LIST_HR_ASSISTANT_TOOLS.map((tool) => {
                    return (
                      <NormalCustomTooltip
                        title={tool.hover}
                        key={tool.id}
                      >
                        <Button
                          sx={{
                            paddingX: "5px",
                            paddingY: 0,
                            minWidth: "auto",
                            display: "flex",
                            flexDirection: "column",
                            color: `${location.pathname.includes(tool.shortPath) ? "#1672c9" : "black"}`,
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "#1672c9",
                              "& svg": {
                                color: "#1672c9",
                              },
                            },
                            borderRadius: "0px",
                            cursor: "pointer",
                            borderBottom: `${
                              location.pathname.includes(tool.shortPath) ? "2px solid black" : "2px solid transparent"
                            }`,
                            transition: "all 0.3s ease",
                            textTransform: "none",
                            fontSize: "14px",
                            fontWeight: `${location.pathname.includes(tool.shortPath) ? "600" : "400"}`,
                            height: "50px",
                            width: "80px",
                          }}
                          onClick={() => {
                            const URL = renderURLByCurrentURLAndSessionHR(tool.shortPath, currenSession);
                            navigate(URL);
                          }}
                        >
                          {tool.titleHTML}
                        </Button>
                      </NormalCustomTooltip>
                    );
                  })}
                </Stack>
              </Box>
              <Box
                sx={{
                  display: { lg: "flex", xs: "none" },
                  justifyContent: "left",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <UserGuide />
              </Box>
            </Box>
          </>
        )}

        {mobileScreen && (
          <>
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "50px",
                width: "100%",
              }}
            >
              <Button
                sx={{
                  padding: 0,
                  minWidth: "auto",
                  color: "#737373",
                  "& :hover": {
                    color: "#1672c9",
                  },
                }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <SettingWorkstationIcon />
              </Button>

              <Box
                component="div"
                sx={{}}
              >
                <Assistant />
              </Box>
            </Box>

            <Stack
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
              mt={1}
              width="100%"
              maxWidth="400px"
            >
              {LIST_HR_ASSISTANT_TOOLS.map((tool) => {
                return (
                  <NormalCustomTooltip
                    title={tool.hover}
                    key={tool.id}
                  >
                    <Button
                      sx={{
                        paddingX: "5px",
                        paddingY: 0,
                        minWidth: "auto",
                        display: "flex",
                        flexDirection: "column",
                        color: `${location.pathname.includes(tool.shortPath) ? "#1672c9" : "black"}`,
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: "#1672c9",
                          "& svg": {
                            color: "#1672c9",
                          },
                        },
                        borderRadius: "0px",
                        cursor: "pointer",
                        borderBottom: `${
                          location.pathname.includes(tool.shortPath) ? "2px solid black" : "2px solid transparent"
                        }`,
                        transition: "all 0.3s ease",
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: `${location.pathname.includes(tool.shortPath) ? "600" : "400"}`,
                        height: "50px",
                        width: "100%",
                      }}
                      onClick={() => {
                        const URL = renderURLByCurrentURLAndSessionHR(tool.shortPath, currenSession);
                        navigate(URL);
                      }}
                    >
                      {tool.titleHTML}
                    </Button>
                  </NormalCustomTooltip>
                );
              })}
            </Stack>
          </>
        )}
      </Box>
      <ModalNoAction
        open={open}
        setOpen={setOpen}
      >
        <Box
          component="div"
          mb="5px"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: 1,
            }}
          >
            Team and project settings
          </Typography>
          <FormControl
            sx={{
              width: "100%",
              backgroundColor: "white",
              "& label": {
                fontSize: "14px",
              },
              marginBottom: 3,
              position: "relative",
            }}
          >
            <InputLabel
              htmlFor="grouped-native-select"
              sx={{
                top: "-7px",
                "&.MuiInputLabel-shrink": {
                  top: "0px !important",
                },
              }}
            >
              Choose a team and a project
            </InputLabel>
            <Select
              native
              value={`${currentProject?.projectId || ""}`}
              id="grouped-native-select"
              label="Choose a team and a project"
              sx={{
                "&:focus": {
                  borderWidth: "1px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
                height: "40px",
                fontSize: "14px",
              }}
              onChange={(e: SelectChangeEvent) => handleChangeProject(Number(e.target.value))}
              disabled={isProjectLoading}
            >
              <option
                aria-label="None"
                value=""
                style={{
                  display: "none",
                }}
              />
              {listGroupProjectByTeam?.length > 0 &&
                listGroupProjectByTeam.map((v) => (
                  <optgroup
                    key={v.teamId}
                    label={v.teamName}
                  >
                    {v &&
                      v?.projects &&
                      v.projects.map((p) => (
                        <option
                          key={p.id}
                          value={p.id}
                        >
                          {p.name}
                        </option>
                      ))}
                  </optgroup>
                ))}
            </Select>
            {isProjectLoading && (
              <CircularProgress
                color="primary"
                size="15px"
                sx={{
                  position: "absolute",
                  top: "30%",
                  right: "40px",
                  transform: "translateY(50%)",
                }}
              />
            )}
          </FormControl>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Box component="div">
              <BodyText
                variant="caption"
                sx={{
                  fontWeight: "600 !important",
                  marginBottom: 1,
                }}
              >
                Team:&nbsp;
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {currentProject?.teamName}
                </span>
              </BodyText>
              <BodyText
                variant="caption"
                sx={{
                  fontWeight: "600 !important",
                  marginBottom: 1,
                }}
              >
                Project name:&nbsp;
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {currentProject?.projectName}
                </span>
              </BodyText>
              <BodyText
                variant="caption"
                sx={{
                  fontWeight: "600 !important",
                  marginBottom: 3,
                }}
              >
                Project summary:&nbsp;
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {currentProject?.about}
                </span>
              </BodyText>
            </Box>
            <Box
              component="div"
              sx={{
                minWidth: "150px",
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  marginBottom: 2,
                }}
                className="select-project-team"
              >
                <Button
                  sx={{
                    padding: 0,
                    minWidth: "auto",
                    color: "#737373",
                    "& :hover": {
                      color: "#1672c9",
                    },
                  }}
                >
                  <TeamMemberIcon
                    width="20"
                    height="20"
                  />
                </Button>
                <a href="/team">Manage team</a>
              </Box>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
                className="select-project-team"
              >
                <Button
                  sx={{
                    padding: 0,
                    minWidth: "auto",
                    color: "#737373",
                    "& :hover": {
                      color: "#1672c9",
                    },
                  }}
                >
                  <ProjectIcon
                    width="20"
                    height="20"
                  />
                </Button>

                <a href="/project">Manage project</a>
              </Box>
              <BodyText
                variant="caption"
                sx={{
                  fontWeight: "600 !important",
                  marginBottom: 1,
                  marginTop: 3,
                }}
              >
                Team members:
              </BodyText>
              <Box
                component="ul"
                sx={{
                  paddingLeft: 2,
                  marginTop: 0,
                }}
              >
                {currentProject?.members?.length > 0 &&
                  currentProject.members.map((c) => (
                    <Box
                      key={c.id}
                      component="li"
                      sx={{
                        listStyle: "none",
                        fontSize: "14px",
                        lineHeight: "1.5",
                      }}
                    >
                      {c.first_name}
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </ModalNoAction>
    </>
  );
};

export default TopHrAssistant;
