import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

const VisualizeIdeas = () => {
  // state
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      {currentPage === 1 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1,
            }}
          >
            Visualize Ideas
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            Use this tool to visualize ideas, create flowcharts and diagrams.
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
              textDecoration: "underline",
            }}
          >
            Sample questions:
          </Typography>
          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Issue tree or logic tree: Visualizes a pyramidal breakdown of a problem into multiple levels of subsets.
                It's useful for identifying root causes or potential solutions.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Create an issue tree to explore ideas on [enter your problem you try to solve here]. Example: how to
                    reduce carbon emissions in a transportation business.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Mind map: Organizes information hierarchically, making it useful for brainstorming and decision-making.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Create a mind map diagram for ideas on [enter your problem here]. Example: how to increase
                    profitability for a consulting business.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                User journey: Visualizes the process a person undergoes to accomplish a goal, or the lifecycle of a
                product or service.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Create a journey diagram for [details here]. Example: a vehicle's lifecycle.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Gantt chart: Illustrates project schedules and task dependencies, and is widely used in project
                management.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Create a Gantt chart for [enter your project details here]. Example: launching a SaaS product with 6
                    month development durations with following milestones….
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {currentPage === 2 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1,
            }}
          >
            Visualize Ideas
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            Use this tool to visualize ideas, create flowcharts and diagrams.
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
              textDecoration: "underline",
            }}
          >
            Sample questions (continued):
          </Typography>
          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Sequence diagram: Shows how parts of a system work sequentially, aiding in understanding complex
                processes
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Create a sequence diagram for [your process steps here] . Example: an emergency braking system in a
                    vehicle.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Entity relationship diagram (ERD): Illustrates the relationships and attributes of entities within a
                system. It's often used in database or system development and as educational teaching tools.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Create an entity relationship diagram for [enter system or database details] (example: users on
                    LinkedIn).
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Timeline diagram: Displays a sequence of events in chronological order. It's typically used for
                visualizing project schedules, historical events, or processes.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Create a timeline diagram of [enter your event detail here].
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          paddingY: 2,
          justifyContent: "space-between",
        }}
      >
        {currentPage === 2 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              setCurrentPage(1);
            }}
          >
            Previous
          </Button>
        )}
        {currentPage === 1 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              setCurrentPage(2);
            }}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default VisualizeIdeas;
