// AboutFinancialAssistant
import React from "react";
import { Typography, Box } from "@mui/material";
import { FaRegShareFromSquare } from "react-icons/fa6";

const ABOUT_HR_ASSISTANT = [
  {
    id: 1,
    content: "Create a new project for managing extensive hiring events. (optional).",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Create new projects for large-scale financial analysis initiatives (optional).
      </Typography>
    ),
  },
  {
    id: 2,
    content: "Initiate separate chat sessions for each hiring phase or smaller events.",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Initiate separate chat sessions for each component.
      </Typography>
    ),
  },
  {
    id: 3,
    content: "Team members can all join in shared chat sessions.",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Team members can all join in shared chat sessions.
      </Typography>
    ),
  },
  {
    id: 4,
    content: "",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Click on (<FaRegShareFromSquare fontSize="12px" />
        &nbsp;share) Share in each response to Edit and Share via email, or posted on LinkedIn, Facebook Page or X
        (Twitter).
      </Typography>
    ),
  },
];

const AboutFinancialAssistant = () => {
  return (
    <React.Fragment>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: 1,
        }}
      >
        Financial Analytics Assistants
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Specialize in the analysis of financial data and extracting insights from annual reports.
      </Typography>

      <Box
        component="ul"
        sx={{
          paddingLeft: 2,
        }}
      >
        {ABOUT_HR_ASSISTANT.map((a) => (
          <Box
            key={`about-hr-assistant-${a.id}`}
            component="li"
            sx={{}}
          >
            {a.contentHTML}
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default AboutFinancialAssistant;
