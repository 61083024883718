import { toDate, utcToZonedTime } from "date-fns-tz";

import React from "react";
import moment from "moment";
import toast from "react-hot-toast";

import { store } from "src/store/store";
import { ITeam } from "../models/team";
import { errorResponseSlide } from "src/store/features/error_response";
import { IReference, ISession } from "../models/chat";
import { IStruct, RulePassword } from "../models";
import { IAgentModel, INewSession } from "../models/assistant";
import {
  FINANCIAL_ASSISTANT_TOOL,
  HR_ASSISTANT_TOOL_ID,
  MARKETING_ASSISTANT_TOOL,
  SUPPLY_CHAIN_ASSISTANT_TOO,
  rulePassword,
} from "src/data";

export const handleError = (error: any, teamId = null) => {
  if (error?.response?.status === 403) {
    store.dispatch(
      errorResponseSlide.actions.addErrorResponse({
        teamId: teamId,
        content: error?.response?.data?.detail,
      }),
    );
    return;
  }

  if (error?.response?.status === 500) {
    if (error?.response?.data?.detail) {
      toast.error(error?.response?.data?.detail);
      return;
    }

    toast.error("There seems to be an issue with getting responses. Apologize for the inconvenience, please try again");
    return;
  }
  if (error?.response?.status === 404) {
    return;
  }
  const err = error?.response?.data;
  if (!err) {
    return;
  }
  if (typeof err === "string") {
    toast.error(err);
    return;
  }
  const listErr = Object.values(err);
  const listKeys = Object.keys(err);

  if (listErr && listErr.length > 0) {
    let notyErr = "";
    if (typeof listErr[0] === "string") {
      notyErr = `${listKeys[0] ? `${listKeys[0]}:` : ""}${listErr[0]}`;
    } else {
      notyErr = `${listKeys[0] ? `${listKeys[0]}:` : ""}${listErr[0][0]}`;
    }
    toast.error(notyErr);
  }

  return;
};

export const handleErrorNotify = (error: any, teamId = null) => {
  if (error?.response?.status === 403) {
    store.dispatch(
      errorResponseSlide.actions.addErrorResponse({
        teamId: teamId,
        content: error?.response?.data?.detail,
      }),
    );
    return {
      content: "",
    };
  }
  return {
    content: "We are unable to retrieve responses. Please refresh or try again",
  };
};

export const normalizeStr = (str: string) => {
  if (!str) {
    return "";
  }
  return str.toLowerCase().replace(/ /g, "_");
};

export const validatePassword = (password: string): RulePassword[] => {
  return rulePassword.map((rule) => {
    const result = rule.pattern.test(password);
    return { ...rule, success: result, init: false };
  });
};

export const renderPathFolder = (path: string, rootPath: string) => {
  if (path === rootPath) {
    return "Project folder";
  }
  const length = path.length;
  const str = path.slice(0, length - 1);
  const arr = str.split("/");

  return arr[arr.length - 1];
};

export const getListFileByPath = (data: IStruct, path: string) => {
  if (data.path === path && data.children?.length > 0) {
    const res = data.children.filter((child) => child.type === "File");
    return res;
  }

  if (data.children && data.children.length > 0) {
    const result: any[] = [];
    data.children.forEach((child) => {
      const childResult = getListFileByPath(child, path);
      result.push(...childResult);
    });
    return result;
  }

  return [];
};

export const getRootPaths = (node: IStruct): string[] => {
  let folderPaths: string[] = [];

  if (node.type === "Folder") {
    folderPaths.push(node.path);
  }

  if (node.children) {
    for (const child of node.children) {
      const childFolderPaths = getRootPaths(child);
      folderPaths = folderPaths.concat(childFolderPaths);
    }
  }

  return folderPaths;
};

export const renderPathFile = (path: string) => {
  if (!path) {
    return {
      name: "",
      type: "",
    };
  }
  const arr = path.split("/");
  const lastElement = arr[arr.length - 1];
  const arrLastElement = lastElement.split(".");

  return {
    name: lastElement,
    type: arrLastElement[arrLastElement.length - 1],
  };
};

export const renderPathFileToDelete = (path: string, rootPath: string) => {
  return path.slice(rootPath.length);
};

export const convertByte = (size: number) => {
  return size / (1024 * 1024);
};

export const scrollToTop = (refElement: React.RefObject<HTMLDivElement>) => {
  if (refElement?.current) {
    refElement.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
};

export const scrollToBottom = (refElement: React.RefObject<HTMLDivElement>, bottom?: number) => {
  if (refElement.current) {
    refElement.current.scrollTo({
      top: bottom ? bottom : refElement.current.scrollHeight,
      behavior: "smooth",
    });
  }
};

export const getDay = (date: string) => {
  if (!date) return moment().format("HH:ss");

  const timestamp = Number(date) * 1000;

  const dateFromTimestamp = moment(timestamp);
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");

  if (dateFromTimestamp.isSame(today, "day")) {
    return moment(timestamp).format("HH:ss");
  } else if (dateFromTimestamp.isSame(yesterday, "day")) {
    return `Yesterday ${moment(timestamp).format("HH:ss")}`;
  } else {
    return moment(timestamp).format("DD/MM/YYYY HH:ss");
  }
};

export const checkPermission = (team: ITeam) => {
  const status = team?.status;

  if (status === "Free Trial") return true;
  if (status === "Inactive") return false;
  if (status === "Subscribed") return true;
  if (status === "No Credit") return true;

  return false;

  // if return False => disabled
};

export const removeDuplicateString = (references: string[]): string[] => {
  return [...new Set(references)];
};

export const removeDuplicateNumber = (references: number[]): number[] => {
  return [...new Set(references)];
};

export const convertToRanges = (arr: number[]) => {
  const uniqueArr = [...new Set(arr)];
  const sortedArr = uniqueArr.sort((a, b) => a - b);

  const ranges = [];
  let start = sortedArr[0];
  for (let i = 1; i < sortedArr.length; i++) {
    if (sortedArr[i] - sortedArr[i - 1] !== 1) {
      if (start === sortedArr[i - 1]) {
        ranges.push(`${start}`);
      } else {
        ranges.push(`${start}-${sortedArr[i - 1]}`);
      }
      start = sortedArr[i];
    }
  }
  if (start === sortedArr[sortedArr.length - 1]) {
    ranges.push(`${start}`);
  } else {
    ranges.push(`${start}-${sortedArr[sortedArr.length - 1]}`);
  }

  return ranges;
};

export const renderReference = (sources: IReference[]) => {
  if (sources.toString() === "None") return [""];
  const listFilesName = sources.map((s) => s?.source?.key);
  const filesName = removeDuplicateString(listFilesName);
  const referRaw = filesName.map((file) => {
    const sourceByFile = sources.filter((s) => s?.source?.key === file);
    const pages = sourceByFile.map((f) => f?.page);

    const pageStr = convertToRanges(pages).join(", ");

    return `${renderPathFile(file).name} (${pageStr})`;
  });

  return referRaw;
};

export const renderCurrentURL = (toolID: number, session: ISession | null) => {
  if (toolID === 1) {
    return `/workstation/chat-document/${session?.query_document_external_id || 0}`;
  }
  if (toolID === 2) {
    return `/workstation/chat-visualization/${session?.visualization_external_id || 0}`;
  }
  if (toolID === 3) {
    return `/workstation/chat-flow/${session?.flowchart_external_id || 0}`;
  }
  if (toolID === 4) {
    return `/workstation/chat-ideal/${session?.generate_idea_external_id || 0}`;
  }
  if (toolID === 5) {
    return `/workstation/chat-web/${session?.query_2_web_external_id || 0}`;
  }
};

export const renderCurrentURLBusinessByToolURL = (toolURL: string, session: ISession | null) => {
  if (toolURL.includes("chat-document")) {
    return `/workstation/chat-document/${session?.query_document_external_id || 0}`;
  }
  if (toolURL.includes("chat-visualization")) {
    return `/workstation/chat-visualization/${session?.visualization_external_id || 0}`;
  }
  if (toolURL.includes("chat-flow")) {
    return `/workstation/chat-flow/${session?.flowchart_external_id || 0}`;
  }
  if (toolURL.includes("chat-ideal")) {
    return `/workstation/chat-ideal/${session?.generate_idea_external_id || 0}`;
  }
  if (toolURL.includes("chat-web")) {
    return `/workstation/chat-web/${session?.query_2_web_external_id || 0}`;
  }
};

export const renderURLByRoleToolAssistant = (currentLocation: string, agents: IAgentModel[]) => {
  // HR ASSISTANT
  if (currentLocation.includes("chat-hiring")) {
    const agent = agents.find((agent) => agent.role === HR_ASSISTANT_TOOL_ID.CHAT_HIRING_ROLE);
    return `/hr-assistant/chat-hiring/${agent?.conversation_id || 0}`;
  }
  if (currentLocation.includes("talent-selection")) {
    const agent = agents.find((agent) => agent.role === HR_ASSISTANT_TOOL_ID.TALENT_SELECTION_ROLE);
    return `/hr-assistant/talent-selection/${agent?.conversation_id || 0}`;
  }
  if (currentLocation.includes("performance-analytics")) {
    const agent = agents.find((agent) => agent.role === HR_ASSISTANT_TOOL_ID.PERFORMANCE_ANALYTICS_ROLE);
    return `/hr-assistant/performance-analytics/${agent?.conversation_id || 0}`;
  }
  if (currentLocation.includes("interactive-learning")) {
    const agent = agents.find((agent) => agent.role === HR_ASSISTANT_TOOL_ID.INTERACTIVE_LEARNING_ROLE);
    return `/hr-assistant/interactive-learning/${agent?.conversation_id || 0}`;
  }

  // MARKETING ASSISTANT
  if (currentLocation.includes("market-intelligence")) {
    const agent = agents.find((agent) => agent.role === MARKETING_ASSISTANT_TOOL.MARKETING_INTELLIGENCE_TOOL.role);
    return `/marketing-assistant/market-intelligence/${agent?.conversation_id || 0}`;
  }
  if (currentLocation.includes("marketing-analytics")) {
    const agent = agents.find((agent) => agent.role === MARKETING_ASSISTANT_TOOL.MARKETING_ANALYTICS_TOOL.role);
    return `/marketing-assistant/marketing-analytics/${agent?.conversation_id || 0}`;
  }
  if (currentLocation.includes("content-creation")) {
    const agent = agents.find((agent) => agent.role === MARKETING_ASSISTANT_TOOL.CONTENT_CREATION_TOOL.role);
    return `/marketing-assistant/content-creation/${agent?.conversation_id || 0}`;
  }

  // SUPPLY CHAIN ASSISTANT
  if (currentLocation.includes("vendor-assessment")) {
    const agent = agents.find((agent) => agent.role === SUPPLY_CHAIN_ASSISTANT_TOO.VENDOR_ASSESSMENT_TOOL.role);
    return `/supply-chain-assistant/vendor-assessment/${agent?.conversation_id || 0}`;
  }
  if (currentLocation.includes("contract-analysis")) {
    const agent = agents.find((agent) => agent.role === SUPPLY_CHAIN_ASSISTANT_TOO.CONTRACT_ANALYSIS_TOOL.role);
    return `/supply-chain-assistant/contract-analysis/${agent?.conversation_id || 0}`;
  }
  if (currentLocation.includes("data-analytics")) {
    const agent = agents.find((agent) => agent.role === SUPPLY_CHAIN_ASSISTANT_TOO.DATA_ANALYTICS_TOOL.role);
    return `/supply-chain-assistant/data-analytics/${agent?.conversation_id || 0}`;
  }
  if (currentLocation.includes("strategy-assistant")) {
    const agent = agents.find((agent) => agent.role === SUPPLY_CHAIN_ASSISTANT_TOO.STRATEGY_ASSISTANT_TOOL.role);
    return `/supply-chain-assistant/strategy-assistant/${agent?.conversation_id || 0}`;
  }

  // FINANCE ASSISTANT
  if (currentLocation.includes("financial-analytics")) {
    const agent = agents.find((agent) => agent.role === FINANCIAL_ASSISTANT_TOOL.FINANCIAL_ANALYTICS_TOOL.role);
    return `/financial-assistant/financial-analytics/${agent?.conversation_id || 0}`;
  }
  if (currentLocation.includes("annual-reports")) {
    const agent = agents.find((agent) => agent.role === FINANCIAL_ASSISTANT_TOOL.ANNUAL_REPORTS_TOOL.role);
    return `/financial-assistant/annual-reports/${agent?.conversation_id || 0}`;
  }
};

export const getToolFromCurrentURL = (currentURL: string) => {
  if (currentURL.includes("chat-document")) {
    return 1;
  }
  if (currentURL.includes("chat-visualization")) {
    return 2;
  }
  if (currentURL.includes("chat-flow")) {
    return 3;
  }
  if (currentURL.includes("chat-ideal")) {
    return 4;
  }
  if (currentURL.includes("chat-web")) {
    return 5;
  }
  if (currentURL.includes("chat-hiring")) {
    return 5;
  }
  if (currentURL.includes("talent-selection")) {
    return 6;
  }
  if (currentURL.includes("performance-analytics")) {
    return 7;
  }
  if (currentURL.includes("interactive-learning")) {
    return 8;
  }
  if (currentURL.includes("market-intelligence")) {
    return 1;
  }
  if (currentURL.includes("marketing-analytics")) {
    return 2;
  }
  if (currentURL.includes("content-creation")) {
    return 3;
  }

  return 1;
};

export const renderURLByCurrentURLAndSessionHR = (currentURL: string, session: INewSession) => {
  if (currentURL.includes("chat-hiring")) {
    const assistantSession = session?.agents || [];
    const agent = assistantSession.find((agent) => agent.role === `${HR_ASSISTANT_TOOL_ID.CHAT_HIRING_ROLE}`);

    return `/hr-assistant/chat-hiring/${agent?.conversation_id || 0}`;
  }

  if (currentURL.includes("talent-selection")) {
    const assistantSession = session?.agents || [];
    const agent = assistantSession.find((agent) => agent.role === `${HR_ASSISTANT_TOOL_ID.TALENT_SELECTION_ROLE}`);

    return `/hr-assistant/talent-selection/${agent?.conversation_id || 0}`;
  }

  if (currentURL.includes("performance-analytics")) {
    const assistantSession = session?.agents || [];
    const agent = assistantSession.find((agent) => agent.role === `${HR_ASSISTANT_TOOL_ID.PERFORMANCE_ANALYTICS_ROLE}`);

    return `/hr-assistant/performance-analytics/${agent?.conversation_id || 0}`;
  }

  if (currentURL.includes("interactive-learning")) {
    const assistantSession = session?.agents || [];
    const agent = assistantSession.find((agent) => agent.role === `${HR_ASSISTANT_TOOL_ID.INTERACTIVE_LEARNING_ROLE}`);

    return `/hr-assistant/interactive-learning/${agent?.conversation_id || 0}`;
  }
};

// Function to find the object
export const findParentObjectByConversationId = (dataArray, conversationId) => {
  for (let i = 0; i < dataArray.length; i++) {
    const assistantSessions = dataArray[i].agents || [];
    const foundObject = assistantSessions.find((session) => session.conversation_id === conversationId);
    if (foundObject) {
      return dataArray[i]; // Found the parent object
    }
  }
  return null; // Not found
};

export const validateImageOrVideo = (files: File[]) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
  const videoExtensions = ["mp4", "avi", "mkv", "mov", "wmv"];

  for (let i = 0; i < files.length; i++) {
    const fileExtension = files[i].name.split(".").pop().toLowerCase();
    if (!imageExtensions.includes(fileExtension) && !videoExtensions.includes(fileExtension)) {
      return false;
    }
  }
  return true;
};

export const getTypeMedia = (url: string) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
  const videoExtensions = ["mp4", "avi", "mkv", "mov", "wmv"];

  const fileExtension = url.split(".").pop().toLowerCase();

  if (imageExtensions.includes(fileExtension)) {
    return "image";
  }

  if (videoExtensions.includes(fileExtension)) {
    return "video";
  }

  return "image";
};

export const getTextFromHTML = (htmlString: string) => {
  const parser = new DOMParser();

  // Parse the HTML string
  const doc = parser.parseFromString(htmlString, "text/html");

  // Extract text content
  let textContent = doc.body.textContent || "";

  // textContent = textContent.replace(/\s+/g, ' ').trim();

  return textContent.trim();
};

export const getImageFromHTML = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Get all image elements
  const imgElements = doc.getElementsByTagName("img");

  // Extract src attributes and push them to an array
  const srcArray = [];
  for (let i = 0; i < imgElements.length; i++) {
    srcArray.push({
      url: imgElements[i].getAttribute("src"),
    });
  }

  return srcArray;
};

export const getListAttachedMedia = (listImage: any) => {
  const listAttachedMedia = listImage.map((image) => {
    return {
      media_type: image?.media_type,
      media_url: image?.url,
    };
  });

  return listAttachedMedia;
};

// export const getToolIDFromCurrentURL = (currentURL: string) => {

// }
export const getAssistantNameByCurrentURL = (currentURL: string) => {
  if (currentURL.includes("chat-document")) {
    return {
      name: "Insight Assistants Overview",
      id: "document_analysis",
    };
  }
  if (currentURL.includes("chat-web")) {
    return {
      name: "Insight Assistants Overview",
      id: "web_insights",
    };
  }
  if (currentURL.includes("chat-visualization")) {
    return {
      name: "Insight Assistants Overview",
      id: "data_analytics",
    };
  }
  if (currentURL.includes("chat-flow")) {
    return {
      name: "Insight Assistants Overview",
      id: "visualize_ideas",
    };
  }
  if (currentURL.includes("chat-ideal")) {
    return {
      name: "Insight Assistants Overview",
      id: "generate_create",
    };
  }
  if (currentURL.includes("chat-hiring")) {
    return {
      name: "HR Assistants Overview",
      id: "hiring",
    };
  }
  if (currentURL.includes("talent-selection")) {
    return {
      name: "HR Assistants Overview",
      id: "talent_selection",
    };
  }
  if (currentURL.includes("performance-analytics")) {
    return {
      name: "HR Assistants Overview",
      id: "performance_analytics",
    };
  }
  if (currentURL.includes("interactive-learning")) {
    return {
      name: "HR Assistants Overview",
      id: "interactive_learning",
    };
  }
  if (currentURL.includes("market-intelligence")) {
    return {
      name: "Marketing Assistants Overview",
      id: "market_intelligence",
    };
  }
  if (currentURL.includes("marketing-analytics")) {
    return {
      name: "Marketing Assistants Overview",
      id: "marketing_analytics",
    };
  }
  if (currentURL.includes("content-creation")) {
    return {
      name: "Marketing Assistants Overview",
      id: "content_creation",
    };
  }
  if (currentURL.includes("vendor-assessment")) {
    return {
      name: "Supply Chain Assistants Overview",
      id: "vendor_assessment",
    };
  }
  if (currentURL.includes("contract-analysis")) {
    return {
      name: "Supply Chain Assistants Overview",
      id: "contract_analysis",
    };
  }
  if (currentURL.includes("data-analytics")) {
    return {
      name: "Supply Chain Assistants Overview",
      id: "data_analytics_supply",
    };
  }
  if (currentURL.includes("strategy-assistant")) {
    return {
      name: "Supply Chain Assistants Overview",
      id: "strategy_assistant",
    };
  }
  if (currentURL.includes("financial-analytics")) {
    return {
      name: "Financial Assistants Overview",
      id: "financial_analytics",
    };
  }
  if (currentURL.includes("annual-reports")) {
    return {
      name: "Financial Assistants Overview",
      id: "annual_reports",
    };
  }
};

export const handleConsoleLogChatSocket = (data: any) => {
  console.info(data?.event);
};

interface ContentData {
  url?: string;
  content?: string;
}

export const getTextAndImageFromFcardHTML = (inputString: string) => {
  const contentData: ContentData[] = [];
  const matches = inputString.matchAll(
    /<f-card>\s*<f-name>(.*?)<f-name>\s*(?:<f-title>(.*?)<f-title>\s*<f-url>(.*?)<f-url>|<f-content>(.*?)<f-content>)\s*<\/f-card>/g,
  );

  for (const match of matches) {
    const [, name, title, url, content] = match;
    const data: ContentData = {};

    if (name === "create_image_content") {
      data.url = url;
      data.content = title;
    } else if (name === "create_text_content") {
      data.content = content;
    }

    contentData.push(data);
  }

  return contentData;
};

export const generateImageHtml = (input: string) => {
  const match = input.match(
    /<f-card>\s*<f-name>(.*?)<f-name>\s*(?:<f-title>(.*?)<f-title>\s*<f-url>(.*?)<f-url>|<f-content>(.*?)<f-content>)\s*<\/f-card>/,
  );

  if (match) {
    const [, name, title, url, content] = match;

    if (name === "create_image_content") {
      return `<p>${title}</p><img src="${url}" alt="${title}"/>`;
    } else if (name === "create_text_content") {
      return `<p>${content}</p>`;
    }
  }

  return null;
};

export const validateHTML = (input: string) => {
  // const htmlRegex = /<\/?[a-z][\s\S]*>/i;

  // return htmlRegex.test(input);
  try {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  } catch (e) {
    return false;
  }
};

export const getDateByTimeZone = (dateString: string, zone: string) => {
  const parsedDate = toDate(dateString, { timeZone: zone });

  return utcToZonedTime(parsedDate, zone);
};

export const customFormatDate = (date: any, format: string) => {
  return moment(date).format(format);
};
