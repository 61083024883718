import Box from "@mui/material/Box";

interface IconWrapperProps {
  children: React.ReactNode;
  borderColor?: string;
}

const IconWrapper = ({
  children,
  borderColor = "#a3cdf5",
}: IconWrapperProps) => {
  return (
    <Box
      sx={{
        border: `1px solid ${borderColor}`,
        padding: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        overflow: "hidden",
        "&:hover": {
          backgroundColor: "#d9d9d9",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default IconWrapper;
