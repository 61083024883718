import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Grid } from "@mui/material";

import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { LinkRouter } from "../libs/AppStyle";

import MainHomeImg from "src/assest/images/AI_Assistants_for_Business.png";

const ImageBackground = styled.img`
  max-width: 100%;
  height: auto;
`;

const LOGIN_DATA = [
  {
    id: 1,
    text: "Effortlessly extract insights from vast documents, videos, audios, websites, YouTube content, and complex datasets.",
  },
  {
    id: 2,
    text: "Foster team creativity and visualize concepts, enhance collaboration in shared chat sessions.",
  },
  {
    id: 3,
    text: "Craft content, edit and share across social platforms.",
  },
];

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, []);

  return (
    <>
      <Helmet>
        <title>The best AI platform for business to accelerate innovation and productivity.</title>
        <meta
          name="description"
          content="Supercharge your business with AI assistants for innovation 
and team collaboration. Chat with documents, websites, video, and data. Collaborate anytime, 
anywhere to drive insights and actions."
        />
        <meta
          name="robots"
          content="index, follow"
        />
        <link
          rel="canonical"
          href="https://platform.neuralpit.com"
        />

        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:title"
          content="The best AI platform for businesses to accelerate innovation 
and productivity."
        />
        <meta
          property="og:description"
          content="Supercharge your business with AI tools for innovation 
and team collaboration. Chat with documents, websites, video, and data. Collaborate anytime, 
anywhere to drive insights and actions."
        />
        <meta
          property="og:image"
          content="https://neuralpit.com/images/AI_Assistants_for_Business.png"
        />
        <meta
          property="og:url"
          content="https://platform.neuralpit.com"
        />
        <meta
          property="og:site_name"
          content="NeuralPit Platform"
        />

        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:site"
          content="@NeuralPit"
        />
        <meta
          name="twitter:title"
          content="The best AI platform for businesses to accelerate innovation 
and productivity."
        />
        <meta
          name="twitter:description"
          content="Supercharge your business with AI tools for 
innovation and team collaboration. Chat with documents, websites, video, and data. Collaborate 
anytime, anywhere to drive insights and actions."
        />
        <meta
          name="twitter:image"
          content="https://neuralpit.com/images/AI_Assistants_for_Business.png"
        />
      </Helmet>
      <Container
        component="main"
        maxWidth="lg"
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "60vh" }}
        >
          <Grid
            item
            xs={6}
          >
            <Box>
              <Typography variant="h4">Welcome to NeuralPit platform!</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  fontSize: "14px",
                }}
              >
                <LinkRouter
                  to="/register"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Create account
                </LinkRouter>
              </Button>
              <Button
                type="submit"
                variant="outlined"
                sx={{ ml: 1 }}
              >
                <LinkRouter
                  to="/register"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Sign up
                </LinkRouter>
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Box
              component="div"
              sx={{
                flex: 1,
                display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" },
                flexDirection: "column",
                justifyContent: "center",
                paddingX: 2,
                backgroundColor: "#a3cdf5",
                gap: 2,
                paddingY: 2,
                minHeight: "100vh",
              }}
            >
              <Box component="div">
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "32px",
                    fontWeight: "600",
                  }}
                >
                  Accelerate innovation and productivity with NeuralPit AI Assistants.
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    paddingLeft: 0,
                  }}
                >
                  {LOGIN_DATA.map((i) => (
                    <Box
                      key={i.id}
                      component="li"
                      sx={{
                        marginTop: "8px",
                        fontSize: "14px",
                        fontWeight: "400",
                        display: "inline-block",
                        listStyleType: "circle",
                        lineHeight: 1.5,
                      }}
                    >
                      {i.text}
                    </Box>
                  ))}
                </Box>
                <Box>
                  <ImageBackground src={MainHomeImg} />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HomePage;
