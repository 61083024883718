import { useState, useEffect, useCallback } from "react";
import { Formik, Form, FastField } from "formik";
import { Box, Stack, Button } from "@mui/material";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";

import { TitleText } from "src/libs/AppStyle";
import { ModalNoAction, Input, Select } from "src/components/common";
import { IMember } from "src/libs/models/team";
import { getListTeamByPage } from "src/libs/axios/api/team";
import { ISelectModel } from "src/libs/models";
import { createNewInvitationsByTeamSlug } from "src/libs/axios/api/invitations";
import { getMyProfile } from "src/libs/axios/api/user";

interface AddNewMemberProps {
  open: boolean;
  setOpen: Function;
  teamId: number;
}

const ROLES: ISelectModel[] = [
  {
    id: "admin",
    name: "admin",
  },
  {
    id: "member",
    name: "member",
  },
];

const AddNewMember: React.FC<AddNewMemberProps> = ({ open, setOpen, teamId }) => {
  // state
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  const [data, setData] = useState({
    id: 0,
    email: "",
    role: "",
  });

  // function
  const getListTeam = async () => {
    const res = await getListTeamByPage();
    if (res?.results) {
      const newRes = res.results.map((r) => ({
        id: r.id,
        name: r.name,
      }));
      setTeams(newRes);
    }
  };

  const myProfile = useCallback(async () => {
    const res = await getMyProfile();
    if (res) {
      setUserId(res?.id);
    }
  }, []);

  const addNewMember = async (payload: IMember) => {
    setIsLoading(true);
    const res = await createNewInvitationsByTeamSlug(payload);
    setIsLoading(false);
    if (res) {
      toast.success("Invitations Successful");
      setOpen(false);
    }
  };

  // useEffect
  useEffect(() => {
    if (!open) return;
    myProfile();
    getListTeam();
  }, [open]);

  useEffect(() => {
    if (teams?.length > 0) {
      const currentProject = localStorage.getItem("current_project");
      if (teamId) {
        setData((prev) => ({
          ...prev,
          id: teamId,
        }));
      } else if (currentProject) {
        const project = JSON.parse(currentProject);
        setData((prev) => ({
          ...prev,
          id: project?.teamId,
        }));
      }
    }
  }, [teams]);

  return (
    <ModalNoAction
      open={open}
      setOpen={setOpen}
    >
      <Box
        component="div"
        sx={{
          minWidth: "500px",
        }}
      >
        <TitleText
          variant="h3"
          sx={{
            marginBottom: "20px",
          }}
        >
          Add new member
        </TitleText>
        <Formik
          initialValues={data}
          onSubmit={(values) => {
            addNewMember({
              id: 0,
              team: values?.id,
              email: values?.email,
              role: values?.role,
              invited_by: userId,
              is_accepted: true,
            });
          }}
          enableReinitialize
        >
          {() => {
            return (
              <Form>
                <Stack spacing={4}>
                  <FastField
                    name="id"
                    component={Select}
                    options={teams}
                    label="Team"
                  />
                  <FastField
                    name="email"
                    component={Input}
                    type="text"
                    placeHolder="Email *"
                  />
                  <FastField
                    name="role"
                    component={Select}
                    options={ROLES}
                    label="Role"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    startIcon={
                      !isLoading ? (
                        ""
                      ) : (
                        <CircularProgress
                          color="inherit"
                          size="15px"
                        />
                      )
                    }
                    sx={{
                      height: "47px",
                      textTransform: "capitalize",
                    }}
                  >
                    Add
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ModalNoAction>
  );
};

export default AddNewMember;
