import { createSlice } from "@reduxjs/toolkit"

import type { PayloadAction } from "@reduxjs/toolkit"
import { ISession } from "src/libs/models/chat"


interface SessionState {
  newSession: ISession;
}

const initialState: SessionState = {
  newSession: {
    id: 0,
    name: ""
  }
}

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    addSession: (state, action: PayloadAction<ISession>) => {
      state.newSession = action.payload
    }
  }
})

export const { addSession } = sessionSlice.actions
export default sessionSlice.reducer