import { handleError } from "src/libs/hooks";
import { IProject, IProjectResponse } from "src/libs/models/project";
import api from "../api";

export const getListProjectByTeam = async (page: number, teamId: number): Promise<IProjectResponse | undefined> => {
  try {
    const res = await api.get(`/a/${teamId}/project`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const deleteProjectByIdAndTeamSlug = async (id: number, teamId: number) => {
  try {
    await api.delete(`/a/${teamId}/project/${id}`)
    return "Delete Project Successful"
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const createNewProjectByTeamSlug = async (payload: IProject, teamId: number): Promise<IProject | undefined> => {
  const { name, background_description, objective, team } = payload

  try {
    const res = await api.post(`/a/${teamId}/project`, { name, background_description, objective, team })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const getProjectByIdAndTeamSlug = async (id: number, teamId: number): Promise<IProject | undefined> => {
  try {
    const res = await api.get(`/a/${teamId}/project/${id}`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const updateProjectByIdAndTeamSlug = async (payload: IProject, teamId: number): Promise<IProject | undefined> => {
  const { name, background_description, objective, team } = payload

  try {
    const res = await api.patch(`/a/${teamId}/project/${payload.id}`, { name, background_description, objective, team })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}