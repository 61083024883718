import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { FastFieldProps } from "formik";

interface InputProps extends FastFieldProps {
  type: string;
  placeHolder: string;
  autoComplete: string;
  isFocus?: boolean;
  isMultiple?: boolean;
  row?: number;
  disabled?: boolean;
}

const Input = ({
  type,
  placeHolder,
  field,
  autoComplete,
  form,
  isFocus = false,
  isMultiple = false,
  row = 0,
  disabled = false,
}: InputProps) => {
  const { onChange, name, value } = field;
  const { errors, touched } = form;
  const showError = errors[name] && touched[name];

  return (
    <Box component="div">
      <TextField
        id={name}
        value={value}
        label={placeHolder}
        type={type}
        fullWidth
        margin="normal"
        onChange={onChange}
        autoComplete={autoComplete}
        sx={{
          marginBottom: "0",
          marginTop: "0",
          "& .MuiInputBase-input": {
            padding: "12px 14px",
            fontSize: "14px",
          },
          "& label": {
            fontSize: "14px",
            top: "-4px",
          },
          "& label.Mui-focused": {
            lineHeight: "2.4375em",
            left: "5px",
          },
          "& label.MuiInputLabel-shrink": {
            lineHeight: "2.4375em",
          },
          "& .MuiInputBase-root": {
            "& textarea": {
              padding: 0,
            },
          },
          fontSize: "14px",
        }}
        name={name}
        aria-invalid
        autoFocus={isFocus}
        multiline={isMultiple}
        minRows={row}
        disabled={disabled}
      />
      {showError && (
        <Typography
          component="span"
          sx={{
            color: "red",
            fontSize: "12px",
          }}
        >
          {errors[name]}
        </Typography>
      )}
    </Box>
  );
};

export default Input;
