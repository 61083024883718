import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

const DocumentAnalysis = () => {
  // state
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      {currentPage === 0 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1,
            }}
          >
            Document Analysis
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            Use this tool to extract insights from documents, video and audio.
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            To summarize and extract insights from documents, audios or videos:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 1,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  display: "inline-block",
                }}
              >
                Step 1:
              </Typography>
              &nbsp;Add your documents (one or multiple documents in pdf, doc, docx, ppt, csv) or video and audio.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  display: "inline-block",
                }}
              >
                Step 1:
              </Typography>
              &nbsp;Ask questions.
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setCurrentPage(1)}
          >
            Sample questions for analyze Annual Reports
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 0.5,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setCurrentPage(2)}
          >
            Sample questions for summarizing training videos
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 0.5,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setCurrentPage(3)}
          >
            Sample questions for reviewing Resumes
          </Typography>
        </Box>
      )}
      {currentPage === 1 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1.5,
            }}
          >
            Sample questions for analyzing Annual Reports (
            <a
              href="https://www.youtube.com/watch?v=0DUh6XLau9w"
              style={{
                textDecoration: "underline",
                color: "#1976d2",
              }}
              target="_blank"
              rel="noreferrer"
            >
              see sample video
            </a>
            ):
          </Typography>

          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 1,
                fontStyle: "italic",
              }}
            >
              How did the company perform in 2022 compared to previous years?
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              What risks is the company facing? How do they manage these risks?
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              What growth strategies does the company have?
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Analyze trends in the company's profitability ratio based on its revenue and profits in 2022 and previous
              years.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Analyze trends in the company's Return on Equity (ROE) based on its Net Profit After Tax and Total Equity
              in 2022 and previous years.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Apply a similar questions for Return on Invested Capital (ROIC), Return on Assets (ROA), Liquidity Ratio,
              etc.
            </Box>
          </Box>
        </Box>
      )}
      {currentPage === 2 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1.5,
            }}
          >
            Sample questions for summarizing videos and audios:
          </Typography>

          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 1,
                fontStyle: "italic",
              }}
            >
              Summarize main content of the video.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Give me steps to do [your action here] given in the video.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              What are PPEs mentioned in the safety training video and how to use them appropriately.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              What are the safety hazards mentioned in the video for Site A?
            </Box>
          </Box>
        </Box>
      )}
      {currentPage === 3 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1.5,
            }}
          >
            Sample questions for reviewing resumes and interview videos:
          </Typography>

          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 1,
                fontStyle: "italic",
              }}
            >
              Does any candidate have Python and React skills?
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Does any candidate have Leadership experience?
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Does any candidate have working experience at Google or Apple?
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Summarize key strengths of candidate A and candidate B in a table.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Based on the interview video (or interview note, give me an example where candidate A had to deal with
              conflicts and work, and how did the candidate manage the conflict.)
            </Box>
          </Box>
        </Box>
      )}
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          paddingY: 2,
          justifyContent: "space-between",
        }}
      >
        {currentPage !== 0 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => setCurrentPage(0)}
          >
            Home
          </Button>
        )}
        {currentPage > 1 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              if (currentPage === 0) {
                return;
              }
              setCurrentPage(currentPage - 1);
            }}
          >
            Previous
          </Button>
        )}
        {currentPage !== 0 && currentPage !== 3 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              if (currentPage === 3) {
                return;
              }
              setCurrentPage(currentPage + 1);
            }}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DocumentAnalysis;
