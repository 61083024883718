import { Box } from "@mui/material";

import Header from "../SidebarLayout/Header";
import Footer from "src/components/Footer";
import WarningSubscription from "src/components/Workstation/WarningSubscription";

interface LoggedLayoutProps {
  children: React.ReactNode;
}

const LoggedLayout = ({ children }: LoggedLayoutProps) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box mb={3}>
        <Header />
        <Box
          sx={{
            position: "relative",
            zIndex: 5,
            display: "block",
            flex: 1,
            mt: 2,
            height: "calc(100% - 130px)",
          }}
        >
          {children}
        </Box>
      </Box>
      <WarningSubscription />
      <Footer />
    </Box>
  );
};

export default LoggedLayout;
