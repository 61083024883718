import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FastField, Form, Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, Divider, Stack, Container } from "@mui/material";

// import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import { Input } from "src/components/common";
import { setToken } from "src/libs/axios/api";
import { register } from "src/libs/axios/api/auth";
import { LinkRouter } from "src/libs/AppStyle";
import { RulePassword } from "src/libs/models";
import { rulePassword } from "src/data";
import { validatePassword } from "src/libs/hooks";
import { login, addRefreshToken } from "src/store/features/auth";
import { HRIcon, ImprovementIcon, MarketingIcon, ProcurementIcon } from "src/assest/icons";

import Footer from "src/components/Footer";
import CustomGoogleLogin from "src/components/Auth/CustomGoogleLogin";
import CustomLinkedinLogin from "src/components/Auth/CustomLinkedinLogin";

// const UlComponent = styled.ul`
//   margin-top: 0;
//   padding-left: 10px;
//   display: flex;
//   flex-direction: column;
//   gap: 5px;
// `;
// const LiComponent = styled.li`
//   list-style: none;
//   font-size: 12px;
// `;

const LOGIN_DATA = [
  {
    id: 1,
    text: "Effortlessly extract insights from vast documents, videos, audios, websites, YouTube content, and complex datasets.",
  },
  {
    id: 2,
    text: "Foster team creativity and visualize concepts, enhance collaboration in shared chat sessions.",
  },
  {
    id: 3,
    text: "Craft content, edit and share across social platforms (LinkedIn, Facebook Page and X (Twitter), and elevate your digital presence.",
  },
];

const RegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const urlRedirect = searchParams.get("redirect");
  const invitationId = searchParams.get("invitation_id") ? (searchParams.get("invitation_id") as string) : null;

  const [isLoading, setIsLoading] = useState(false);
  const [rulesPassword, setRulesPassword] = useState<RulePassword[]>(rulePassword);

  // function
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
    invitationId: Yup.string(),
    termsAgreement: Yup.boolean(),
  });

  const registerAccount = async (credentials: {
    email: string;
    password: string;
    invitation_id: string;
    terms_agreement: boolean;
  }) => {
    setIsLoading(true);
    const res = await register(credentials);
    setIsLoading(false);
    if (res) {
      const searchParams = new URLSearchParams();
      searchParams.append("mail", credentials.email);
      if (invitationId) {
        searchParams.append("invitation_id", invitationId);
      }

      navigate({
        pathname: "/confirm-email",
        search: searchParams.toString(),
      });
    }
  };

  const handleValidate = (values) => {
    const results = validatePassword(values?.password);
    setRulesPassword(results);
  };

  const Login = (response) => {
    if (response?.access_token) {
      setToken(response.access_token);
      dispatch(login(response.access_token));
      dispatch(addRefreshToken(response.refresh_token));
      if (urlRedirect) {
        navigate(urlRedirect);
        return;
      }
      localStorage.removeItem("current_project");
      setTimeout(() => {
        navigate("/middle-workstation");
      }, 1000);
    }
  };

  return (
    <>
      <Helmet>
        <title>The best AI platform for businesses to accelerate innovation and productivity.</title>
        <meta
          name="description"
          content="Accelerate innovation and productivity using AI Assistants. 
Chat with documents, websites, video, and data anytime, anywhere to drive insights and 
actions."
        />
        <meta
          name="robots"
          content="index, follow"
        />
        <link
          rel="canonical"
          href="https://Platform.neuralpit.com/register"
        />

        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:title"
          content="The best AI platform for businesses to accelerate innovation 
and productivity."
        />
        <meta
          property="og:description"
          content="Accelerate innovation and productivity using AI 
Assistants. Chat with documents, websites, video, and data anytime, anywhere to drive insights 
and actions."
        />
        <meta
          property="og:image"
          content="https://neuralpit.com/images/AI_Assistants_for_Business.png"
        />
        <meta
          property="og:url"
          content="https://Platform.neuralpit.com/register"
        />
        <meta
          property="og:site_name"
          content="NeuralPit Platform"
        />

        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:site"
          content="@NeuralPit"
        />
        <meta
          name="twitter:title"
          content="The best AI platform for businesses to accelerate innovation 
and productivity."
        />
        <meta
          name="twitter:description"
          content="Accelerate innovation and productivity using AI 
Assistants. Chat with documents, websites, video, and data anytime, anywhere to drive insights 
and actions."
        />
        <meta
          name="twitter:image"
          content="https://neuralpit.com/images/AI_Assistants_for_Business.png"
        />
      </Helmet>
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          minHeight: "100vh",
          paddingY: 0,
          overflowY: "auto",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box
            component="div"
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              maxWidth: "600px",
              paddingTop: 4,
            }}
          >
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "600",
                marginBottom: 2,
              }}
            >
              Welcome to NeuralPit!
            </Typography>
            {/* <Typography
              sx={{
                fontSize: "16px",
              }}
            >
              Analyze, Create and Share - Fast! with AI Assistants.
            </Typography> */}
            <Typography
              sx={{
                fontSize: "16px",
              }}
            >
              Empower businesses and professionals to achieve more in less time.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={(values) => {
                  const credentials = {
                    email: values?.email,
                    password: values?.password,
                    invitation_id: invitationId,
                    terms_agreement: true,
                  };
                  registerAccount(credentials);
                }}
                validationSchema={validationSchema}
                validate={handleValidate}
                enableReinitialize
              >
                {(formikProps) => {
                  return (
                    <Form>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 4,
                        }}
                      >
                        <Avatar sx={{ m: 1, bgcolor: "#1672C9" }}>
                          <LockOutlinedIcon />
                        </Avatar>
                        <Typography
                          component="h1"
                          variant="h5"
                        >
                          Sign Up
                        </Typography>
                        <Typography
                          component="span"
                          sx={{ fontSize: "13px" }}
                        >
                          You&apos;ll get access to most out of our features, updates and more!
                        </Typography>
                        <Box
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginTop: "20px",
                          }}
                        >
                          <Stack gap={3}>
                            <FastField
                              name="email"
                              component={Input}
                              type="text"
                              placeHolder="Email Address *"
                            />
                            <FastField
                              name="password"
                              component={Input}
                              type="password"
                              placeHolder="Password *"
                            />
                          </Stack>
                          <Box
                            component="div"
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                              gap: "6px",
                            }}
                          >
                            {rulesPassword.map((rule) => (
                              <Box key={rule.code}>
                                <Typography
                                  component="span"
                                  sx={{
                                    color: `${rule.init ? "" : rule.success ? "#1672C9" : ""}`,
                                    fontSize: "11px",
                                  }}
                                >
                                  {rule.message}
                                </Typography>
                              </Box>
                            ))}
                          </Box>

                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, textTransform: "capitalize" }}
                            disabled={!(formikProps.isValid && formikProps.dirty) || isLoading}
                            startIcon={
                              !isLoading ? (
                                ""
                              ) : (
                                <CircularProgress
                                  color="inherit"
                                  size="15px"
                                />
                              )
                            }
                          >
                            Agree & Sign up
                          </Button>
                        </Box>
                        <Divider
                          sx={{
                            mt: 2,
                            mb: 2,
                            textTransform: "uppercase",
                            ":after": {
                              borderColor: "#a6a6a6",
                            },
                            ":before": {
                              borderColor: "#a6a6a6",
                            },
                          }}
                          variant="fullWidth"
                          flexItem
                        >
                          or
                        </Divider>
                        <CustomGoogleLogin
                          Login={Login}
                          invitationId={invitationId}
                        />
                        <div
                          style={{
                            width: "100%",
                            height: "20px",
                          }}
                        ></div>
                        <CustomLinkedinLogin
                          Login={Login}
                          invitationId={invitationId}
                        />
                      </Box>

                      <Typography
                        component="span"
                        sx={{ fontSize: "13px" }}
                      >
                        By clicking Agree & Sign up, you agree to NeuralPit Terms of Service, Privacy & Security Policy
                        and Cookie Policy
                      </Typography>
                    </Form>
                  );
                }}
              </Formik>
              <Box
                mt={2}
                component="div"
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  Already on NeuralPit?&nbsp;
                  <LinkRouter to="/login">
                    <Typography
                      component="span"
                      color="blue"
                      sx={{
                        textDecoration: "underline",
                        fontSize: "14px",
                      }}
                    >
                      Sign in
                    </Typography>
                  </LinkRouter>
                </Typography>
              </Box>
            </Box>
            <Box
              component="div"
              mt={3}
            >
              <Footer />
            </Box>
          </Box>
          <Box
            component="div"
            sx={{
              flex: 1,
              display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              paddingX: 2,
              backgroundColor: "#a3cdf5",
              gap: 2,
              paddingY: 2,
              minHeight: "100vh",
            }}
          >
            <Box component="div">
              <Typography
                variant="h1"
                sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                }}
              >
                Accelerate innovation and productivity with NeuralPit AI platform.
              </Typography>
              {/* <Typography
                variant="h2"
                sx={{
                  marginTop: "8px",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#fff",
                }}
              >
                Steps to unlock innovation and productivity:
              </Typography> */}
              {LOGIN_DATA.map((i) => (
                <Box
                  key={i.id}
                  component="li"
                  sx={{
                    marginTop: "8px",
                    fontSize: "14px",
                    fontWeight: "400",
                    listStyleType: "disc",
                    lineHeight: 1.5,
                  }}
                >
                  {i.text}
                </Box>
              ))}
              <Box
                component="div"
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "8px",
                  mt: 3,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <ImprovementIcon />
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Insight Assistants
                  </Typography>
                </Box>
                <Box
                  component="div"
                  sx={{
                    marginTop: "8px",
                    paddingLeft: "44px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    Extract insights from documents and data, generate and visualize ideas.
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      marginTop: "6px",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    For Consulting and Business Improvement.
                  </Typography>
                </Box>
              </Box>
              <Box
                component="div"
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "8px",
                  mt: 2,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <HRIcon />
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    AI Assistants for HR
                  </Typography>
                </Box>
                <Box
                  component="div"
                  sx={{
                    marginTop: "8px",
                    paddingLeft: "44px",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    Draft and share job postings on social platforms, review resumes to identify the right talent, and
                    conduct HR data analytics.
                  </Typography>
                </Box>
              </Box>
              <Box
                component="div"
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "8px",
                  mt: 3,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <MarketingIcon />
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    AI Assistants for Marketing
                  </Typography>
                </Box>
                <Box
                  component="div"
                  sx={{
                    marginTop: "8px",
                    paddingLeft: "44px",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    Extract market and competitor insights from websites and YouTube videos; craft and share marketing
                    materials across social platforms.
                  </Typography>
                </Box>
              </Box>
              <Box
                component="div"
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "8px",
                  mt: 3,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <ProcurementIcon />
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    AI Assistants for Procurement and Supply Chain
                  </Typography>
                </Box>
                <Box
                  component="div"
                  sx={{
                    marginTop: "8px",
                    paddingLeft: "44px",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    Distill critical insights from supplier proposals and commercial contracts, and conduct analysis of
                    procurement and supply chain data.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default RegisterPage;
