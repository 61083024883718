import { handleError } from "src/libs/hooks"
import api from "../api"
import { IMember } from "src/libs/models/team"

export const createNewMember = async (teamId: number, payload: IMember): Promise<IMember | undefined> => {
  try {
    const res = await api.post(`/teams/${teamId}/memberships`, { role: payload.role })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const deleteMembershipByTeamSlug = async (id: number, teamId: number): Promise<string | undefined> => {
  try {
    await api.delete(`/teams/${teamId}/memberships/${id}`)
    return "Delete Membership Successful"
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const getMemberByIdAndTeamSlug = async (id: number, teamId: number): Promise<IMember | undefined> => {
  try {
    const res = await api.get(`/teams/${teamId}/memberships/${id}`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const pathMemberByIdAndTeamSlug = async (id: number, teamId: number, role: string): Promise<IMember | undefined> => {
  try {
    const res = await api.patch(`/teams/${teamId}/memberships/${id}`, { role })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}