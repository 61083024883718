import { Box, Typography } from "@mui/material";

const TalentSelection = () => {
  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      <Box
        component="div"
        sx={{}}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: 1,
          }}
        >
          Talent Selection Assistant
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Use this tool to pinpoint the right talents for your team and organization, focusing on matching skills,
          experience, and cultural values.
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Upload one more multiple resumes (pdf, docs, docx) and interview notes (videos, audios) and ask questions.
        </Typography>

        <Box
          component="ul"
          mb={2}
          mt={1}
          padding={0}
          paddingLeft={2}
        >
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Does any candidate have Python and React skills?
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Does any candidate have Leadership experience?
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Does any candidate have working experience at Google or Apple?
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Summarize key strengths of candidate A and candidate B in a table.
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Based on the interview video (or interview notes), give me an example where candidate A had to deal with
              conflicts and work, and how did the candidate manage the conflict.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TalentSelection;
