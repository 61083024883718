import * as Yup from "yup";
import { useState } from "react";
import { FastField, Form, Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { Input } from "src/components/common";
import { resendConfirmEmail, validateEmail } from "src/libs/axios/api/auth";
import { setToken } from "src/libs/axios/api";
import { useDispatch } from "react-redux";
import { login, addRefreshToken } from "src/store/features/auth";

const ConfirmEmailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const confirmMail = searchParams.get("mail");
  const invitationId = searchParams.get("invitation_id") ? (searchParams.get("invitation_id") as string) : null;

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Code is required"),
  });

  const validateEmailAccount = async (code: string) => {
    setIsLoading(true);
    const res = await validateEmail(code, confirmMail, invitationId);
    setIsLoading(false);
    if (res?.access_token) {
      setToken(res.access_token);
      dispatch(login(res.access_token));
      dispatch(addRefreshToken(res?.refresh_token));
      localStorage.removeItem("current_project");
      setTimeout(() => {
        navigate("/middle-workstation");
      }, 1000);
    }
  };

  const handleResendEmail = async (email: string) => {
    const res = await resendConfirmEmail(email);
    if (res) {
      toast.success(res);
    }
  };

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "150px",
      }}
    >
      <Box
        component="div"
        sx={{
          minHeight: "400px",
        }}
      >
        <Typography
          component="h2"
          mb={5}
        >
          Please check your email for the confirmation code to activate your NeuralPit account.
        </Typography>
        <Container
          component="main"
          maxWidth="xs"
        >
          <Box>
            <Formik
              initialValues={{ code: "" }}
              onSubmit={(values) => {
                validateEmailAccount(values?.code);
              }}
              validationSchema={validationSchema}
            >
              {(formikProps) => {
                return (
                  <Form>
                    <FastField
                      name="code"
                      component={Input}
                      type="text"
                      placeHolder="Input code *"
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, textTransform: "capitalize" }}
                      disabled={!(formikProps.isValid && formikProps.dirty) || isLoading}
                      startIcon={
                        !isLoading ? (
                          ""
                        ) : (
                          <CircularProgress
                            color="inherit"
                            size="15px"
                          />
                        )
                      }
                    >
                      Submit
                    </Button>
                    <Button
                      type="button"
                      fullWidth
                      variant="outlined"
                      sx={{ mt: 1, mb: 2, textTransform: "capitalize" }}
                      onClick={() => {
                        handleResendEmail(confirmMail);
                      }}
                    >
                      Resend
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ConfirmEmailPage;
