import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { redirectPage } from "src/libs/axios/api/auth";

const InvitationPage = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  const redirectPageCode = async () => {
    const res = await redirectPage(code);
    if (res) {
      if (!res.redirect_to_signup) {
        navigate(`/workstation?invitation_id=${res?.invitation?.id}`);
      } else {
        navigate(`/register?invitation_id=${res?.invitation?.id}`);
      }
    } else {
      navigate("/register");
    }
  };

  useEffect(() => {
    if (code) {
      redirectPageCode();
    }
  }, [code]);

  return (
    <Box>
      <div>Invitation Page</div>
    </Box>
  );
};

export default InvitationPage;
