import React, { useState } from "react";
import { Tooltip, Button } from "@mui/material";

import { EditIcon } from "src/assest/icons";
import EditSessionModal from "../../EditSessionModal";

interface EditSessionProps {
  sessionId: number;
  projectId: number;
  teamId: number;
  callback: Function;
}

const EditSession: React.FC<EditSessionProps> = ({ sessionId, projectId, teamId, callback }) => {
  // state
  const [editSessionModal, setEditSessionModal] = useState(false);

  return (
    <React.Fragment>
      <Tooltip title="Edit Session">
        <Button
          sx={{
            minWidth: "auto",
            padding: "0",
            color: "#1672c9",
          }}
          onClick={() => {
            setEditSessionModal(true);
          }}
        >
          <EditIcon
            width="20"
            height="20"
          />
        </Button>
      </Tooltip>

      <EditSessionModal
        open={editSessionModal}
        setOpen={setEditSessionModal}
        teamId={teamId}
        projectId={projectId}
        sessionId={sessionId}
        callback={callback}
      />
    </React.Fragment>
  );
};

export default EditSession;
