import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactGA from "react-ga4";

import { store } from "src/store/store";

import "nprogress/nprogress.css";
import App from "src/App";

const queryClient = new QueryClient();

ReactGA.initialize("G-7F26P3GLX4");

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});
