import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

const DataAnalytics = () => {
  // state
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      {currentPage === 0 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1,
            }}
          >
            Data Analytics
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            Use this tool to analyze and visualize data.
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            To start analyzing and visualizing your data:
          </Typography>
          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  display: "inline-block",
                }}
              >
                Step 1:
              </Typography>
              &nbsp;Add ONE data file (in excel, csv).
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mt: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  display: "inline-block",
                }}
              >
                Step 2:
              </Typography>
              &nbsp;Ask questions
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setCurrentPage(1)}
          >
            Sample questions for analyzing Safety data
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 0.5,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setCurrentPage(2)}
          >
            Sample questions for Sales data
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 0.5,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setCurrentPage(3)}
          >
            Sample questions for Procurement data
          </Typography>
        </Box>
      )}
      {currentPage === 1 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1.5,
            }}
          >
            Sample questions for analyzing Safety data (
            <a
              href="https://www.youtube.com/watch?v=0DUh6XLau9w"
              style={{
                textDecoration: "underline",
                color: "#1976d2",
              }}
              target="_blank"
              rel="noreferrer"
            >
              see sample video
            </a>
            ):
          </Typography>

          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 1,
                fontStyle: "italic",
              }}
            >
              Plot total incidents by Department.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Plot total incidents by Department and by Gender.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Identify top 10 injury locations for Department A.
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Plot Injury locations within the Maintenance Department for males in the 25-34 age group.
            </Box>
          </Box>
        </Box>
      )}
      {currentPage === 2 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1.5,
            }}
          >
            SSample questions for analyzing Sales data:
          </Typography>

          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 1,
                fontStyle: "italic",
              }}
            >
              Determine the correlation between Sales and Marketing Expense
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Plot Sales by Business Unit
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Plot Sales by Month for Business Unit A
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Plot Sales by Product group
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Plot Sales by Region for Business Unit A
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              List top 10 products with highest sales in 2022 in Region X
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Plot the correlation between Sales and Marketing expense
            </Box>
          </Box>
        </Box>
      )}
      {currentPage === 3 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1.5,
            }}
          >
            Sample questions for Procurement data:
          </Typography>

          <Box
            component="ul"
            mb={2}
            mt={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                mb: 1,
                fontStyle: "italic",
              }}
            >
              Plot total spend for top 20 categories in 2023
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Identify top 10 vendors with highest spend in 2023
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Identify top 10 products with highest spend for the top 10 vendors in 2023
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Plot total spend by country
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Plot DIFOT for top 20 vendors with highest DIFOT performance
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              Plot DIFOT for top 20 vendors with lowest DIFOT performance
            </Box>
            <Box
              component="li"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 1,
              }}
            >
              List the vendors and their unit prices for Product A purchased during 2023
            </Box>
          </Box>
        </Box>
      )}
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          paddingY: 2,
          justifyContent: "space-between",
        }}
      >
        {currentPage !== 0 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => setCurrentPage(0)}
          >
            Home
          </Button>
        )}
        {currentPage > 1 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              if (currentPage === 0) {
                return;
              }
              setCurrentPage(currentPage - 1);
            }}
          >
            Previous
          </Button>
        )}
        {currentPage !== 0 && currentPage !== 3 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              if (currentPage === 3) {
                return;
              }
              setCurrentPage(currentPage + 1);
            }}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DataAnalytics;
