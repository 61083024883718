import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GiAlarmClock } from "react-icons/gi";
import { Button, Stack, Divider, Menu, MenuItem, Tooltip } from "@mui/material";

import LogoutIcon from "@mui/icons-material/Logout";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

import { logout } from "src/store/features/auth";
import { BodyText } from "src/libs/AppStyle";
import { LinkRouter } from "src/libs/AppStyle";
import { AccountIcon } from "src/assest/icons";
import { logoutAccount } from "src/libs/axios/api/auth";

interface AccountItemProps {
  pathName: string;
}

const AccountItem: React.FC<AccountItemProps> = ({ pathName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // function
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutAccount = async () => {
    localStorage.removeItem("current_project");
    localStorage.removeItem("previous_url");
    await logoutAccount();
    dispatch(logout());
    navigate("/login");
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="Edit name, password, and payments">
        <Button
          id="my-account"
          color="inherit"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            padding: 0,
            paddingX: "5px",
            color: `${pathName === "/profile" || pathName === "/payment" ? "#1672c9" : "black"}`,
            "&:hover": {
              backgroundColor: "transparent",
              color: "#1672c9",
              "& svg": {
                color: "#1672c9",
              },
            },
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
          >
            <AccountIcon
              className={pathName === "/profile" || pathName === "/payment" ? "activate-svg" : "default-svg"}
            />
            <BodyText
              variant="caption"
              sx={{
                fontSize: "12px !important",
                textTransform: "capitalize",
                color: "inherit !important",
              }}
            >
              Account
            </BodyText>
          </Stack>
        </Button>
      </Tooltip>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <LinkRouter to="/profile">
            <Button
              color="inherit"
              startIcon={<AccountCircleIcon />}
              sx={{
                textTransform: "none",
                fontSize: "14px",
              }}
            >
              Profile
            </Button>
          </LinkRouter>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkRouter to="/payment">
            <Button
              color="inherit"
              startIcon={<PaymentsIcon />}
              sx={{
                textTransform: "none",
                fontSize: "14px",
              }}
            >
              My Payment
            </Button>
          </LinkRouter>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkRouter to="/schedule-post">
            <Button
              color="inherit"
              startIcon={<GiAlarmClock />}
              sx={{
                textTransform: "none",
                fontSize: "14px",
              }}
            >
              Schedule Posts
            </Button>
          </LinkRouter>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkRouter to="/integration">
            <Button
              color="inherit"
              startIcon={<ConnectWithoutContactIcon />}
              sx={{
                textTransform: "none",
                fontSize: "14px",
              }}
            >
              Integration
            </Button>
          </LinkRouter>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogoutAccount}>
          <Button
            color="inherit"
            startIcon={<LogoutIcon />}
            sx={{
              textTransform: "none",
              fontSize: "14px",
            }}
          >
            Logout
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountItem;
