import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { CustomWidthTooltip, LiText } from "src/libs/AppStyle";
import { UseGuideIcon, SettingWorkstationIcon } from "src/assest/icons";

const GUIDE_PAGES = [
  {
    id: 1,
    title: "Business Improvement",
  },
  {
    id: 2,
    title: "HR",
  },
  {
    id: 3,
    title: "Procurement & Supply Chain",
  },
  {
    id: 5,
    title: "Marketing & Sales",
  },
  {
    id: 4,
    title: "Financials",
  },
  {
    id: 6,
    title: "Operations",
  },
];

const BoxUserGuide = () => {
  // state
  const [currentPage, setCurrentPage] = useState(0);

  // function
  const handlePrevious = () => {
    if (currentPage === 1) return;
    setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage === 6) return;
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <Box
      component="div"
      sx={{
        height: "500px",
        overflowY: "auto",
      }}
    >
      {currentPage === 0 && (
        <Box component="div">
          <Typography
            sx={{
              fontSize: "12px",
              color: "#fff",
              fontWeight: "700",
              marginBottom: 1.5,
            }}
            variant="h5"
          >
            Workstation - Chat Session
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontSize: "12px",
              color: "#fff",
              display: "block",
              marginBottom: 1.5,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                textDecoration: "underline",
                display: "inline",
              }}
            >
              Purpose
            </Typography>
            : Allow team members to collaboratively interact with the same documents and data, contribute ideas anywhere
            and anytime in shared chat sessions.
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontSize: "12px",
              color: "#fff",
              textDecoration: "underline",
              display: "block",
            }}
          >
            How to Use:
          </Typography>
          <Box component="ul">
            <LiText
              component="li"
              sx={{
                listStyleType: "decimal",
              }}
            >
              Use the Team and Project Settings (&nbsp;
              <SettingWorkstationIcon
                width="20"
                height="20"
                className="move-6"
              />
              &nbsp;) to select a specific team and project. If not required, leave these settings as default.
            </LiText>
            <LiText
              component="li"
              sx={{
                listStyleType: "decimal",
              }}
            >
              New teams and projects can be created via the Teams and Projects options in the top menu.
            </LiText>
            <LiText
              component="li"
              sx={{
                listStyleType: "decimal",
              }}
            >
              Create a new chat session or use an existing default session. For better context and improved team
              collaboration, a chat session can be edited with a Session Name, Objectives, and Topic.
            </LiText>
            <LiText
              component="li"
              sx={{
                listStyleType: "decimal",
              }}
            >
              A single session can utilize multiple tools.
            </LiText>
            <LiText
              component="li"
              sx={{
                listStyleType: "decimal",
              }}
            >
              Upload data and start querying.
            </LiText>
          </Box>
          <Typography
            variant="caption"
            sx={{
              fontSize: "12px",
              color: "#fff",
              display: "block",
              marginBottom: 1.5,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                textDecoration: "underline",
                display: "inline",
              }}
            >
              Examples of Different Use Cases&nbsp;
            </Typography>
            (Click on each link for more details):
          </Typography>
          <Box component="ul">
            {GUIDE_PAGES.map((g) => (
              <LiText
                key={g.id}
                component="li"
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => setCurrentPage(g.id)}
              >
                {g.title}
              </LiText>
            ))}
          </Box>
        </Box>
      )}
      <Box component="div">
        {currentPage === 1 && (
          <Box component="div">
            <Typography
              sx={{
                fontSize: "12px",
                color: "#fff",
                fontWeight: "700",
                marginBottom: 1.5,
              }}
              variant="h5"
            >
              Business Improvement - Examples Using NeuralPit:
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              1. Use <strong>"Generate Ideas"</strong> or <strong>“Diagrams”</strong> to break down complex problems
              into smaller elements, generate ideas, identify facts to validate hypotheses, and pinpoint viable
              solutions.
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              Examples:
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                Generate ideas on how to reduce the carbon emissions of a transportation business using a structured
                problem-solving approach."
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                Give me 5 assessment criteria for evaluating ideas on how to increase profitability in a consulting
                business"
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                Create a Mindmap diagram on how to increase profitability for a fashion store”
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                {" "}
                “Create an issue tree on how to maximize value from building a train line”
              </LiText>
            </Box>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              2. Use <strong>"Analytics"</strong> to analyze data and identify root causes or key contributors to a
              problem.
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              Example for Machine operations and fuel consumption data analytics:
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Plot fuel consumption by machine in a column chart, ranked from largest to smallest."
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Identify the top 5 machines that consume the most fuel."
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Plot the top 5 machines with the highest maintenance costs."
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Identify factors that have the most impact on fuel consumption."
              </LiText>
            </Box>
          </Box>
        )}
        {currentPage === 2 && (
          <Box component="div">
            <Typography
              sx={{
                fontSize: "12px",
                color: "#fff",
                fontWeight: "700",
                marginBottom: 1.5,
              }}
              variant="h5"
            >
              Human Resource Management - some examples
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              1. Use <strong>"Chat with Documents"</strong> to select talents based on skills and experience, not
              keywords:
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                Which candidates have Python and React skill?" or any skills that you are looking for
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                Which candidates have Leadership experience? Give details"
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                Does any candidate have working experience with Google or Apple?"
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Does any candidate speak any languages other than English?"
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "What strengths does candidate Y have?"
              </LiText>
            </Box>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              2. Use <strong>"Analytics"</strong> to analyze employee performance data:
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Plot Performance Rating by Training Completion."
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                Identify correlation between Annual Salary and Performance Rating"
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Break-down Performance Rating by Department"
              </LiText>
            </Box>
          </Box>
        )}
        {currentPage === 3 && (
          <Box component="div">
            <Typography
              sx={{
                fontSize: "12px",
                color: "#fff",
                fontWeight: "700",
                marginBottom: 1.5,
              }}
              variant="h5"
            >
              Procurement and supply chain - some examples
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              1. Use <strong>“Chat with documents”</strong> to evaluate vendor proposals or understand contracts
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                Upload proposals and ask "List out vendors that meet xxx and yyy"
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                Upload contracts and ask "List out contracts that have volume discount or rebate clauses or other
                special T&Cs"
              </LiText>
            </Box>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              2. Use <strong>“Analytics”</strong> to gain insights into spend data, vendor delivery performance, or
              invoice data:
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{}}
              >
                <Box component="div">
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "12px",
                      color: "#fff",
                      display: "block",
                    }}
                  >
                    Upload spend data and ask:
                  </Typography>
                  <Box component="ul">
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Plot spend by category for the past 12 months, ranking from highest to lowest."
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Which vendors offer the lowest average price for a specific material or service?"
                    </LiText>
                  </Box>
                </Box>
              </LiText>
              <LiText
                component="li"
                sx={{}}
              >
                <Box component="div">
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "12px",
                      color: "#fff",
                      display: "block",
                    }}
                  >
                    Ask vendor DIFOT data:
                  </Typography>
                  <Box component="ul">
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Identify materials with the lowest DIFOT, and list vendors for these materials"
                    </LiText>
                  </Box>
                </Box>
              </LiText>
            </Box>
          </Box>
        )}
        {currentPage === 4 && (
          <Box component="div">
            <Typography
              sx={{
                fontSize: "12px",
                color: "#fff",
                fontWeight: "700",
                marginBottom: 1.5,
              }}
              variant="h5"
            >
              Financial analysis - some examples using NeuralPit
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              1. Use <strong>"Chat with Documents"</strong> to analyze company annual reports or operating procedures
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "How did the company perform in 2023 compared to previous years?"
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Analyze the trend in the Profitability ratio based on Revenue and Profits for 2023 and previous years
                (or other ratios such as the Liquidity ratio or Leverage ratio)."
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "What growth strategies does the company have?"
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "What risks is the company facing, and how do they plan to manage these risks?
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "What strategies does the company have to manage competition?"
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Why should investors consider investing in the company?"
              </LiText>
            </Box>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              2. Use <strong>"Analytics"</strong> to analyze financial data:
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Plot total expenses by department."
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Identify top 5 expenses"
              </LiText>
              <LiText
                component="li"
                sx={{
                  fontStyle: "italic",
                }}
              >
                "Plot revenue by region, team, or quarter."
              </LiText>
            </Box>
          </Box>
        )}
        {currentPage === 5 && (
          <Box component="div">
            <Typography
              sx={{
                fontSize: "12px",
                color: "#fff",
                fontWeight: "700",
                marginBottom: 1.5,
              }}
              variant="h5"
            >
              Marketing & sales - some examples using NeuralPit
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              1. Use <strong>“Chat with documents”</strong> to gain insights on target customers:
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{}}
              >
                <Box component="div">
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "12px",
                      color: "#fff",
                      display: "block",
                    }}
                  >
                    Upload the customer's annual report or website and ask:
                  </Typography>
                  <Box component="ul">
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "How did the company perform in 2022?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "What risks are they facing?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "What growth strategies does the company have?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Which products and services are they offering?"
                    </LiText>
                  </Box>
                </Box>
              </LiText>
              <LiText
                component="li"
                sx={{}}
              >
                <Box component="div">
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "12px",
                      color: "#fff",
                      display: "block",
                    }}
                  >
                    Upload customer reviews for specific products and ask:
                  </Typography>
                  <Box component="ul">
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Analyze consumer sentiments and provide insights, detailing the percentages of positive, neutral,
                      and negative feedback on the product."
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Which features are most liked by consumers, and why?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Which features are least liked by consumers, and why?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "What are the most frequent suggestions for improvement?"
                    </LiText>
                  </Box>
                </Box>
              </LiText>
            </Box>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
                marginBottom: 1.5,
              }}
            >
              2. Use <strong>“Chat with documents”</strong> or <strong>“Diagrams”</strong> to create new marketing
              content “Create a LinkedIn post with emoj for this product launch with the following features….”
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              3. Use <strong>“Analytics”</strong> to understand marketing campaign effectiveness or sales performance
              data:
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{}}
              >
                <Box component="div">
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "12px",
                      color: "#fff",
                      display: "block",
                    }}
                  >
                    Upload marketing campaign data and ask:
                  </Typography>
                  <Box component="ul">
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Plot numbers of clicks or impressions for each channel?"
                    </LiText>
                  </Box>
                </Box>
              </LiText>
              <LiText
                component="li"
                sx={{}}
              >
                <Box component="div">
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "12px",
                      color: "#fff",
                      display: "block",
                    }}
                  >
                    Ask "Sales data":
                  </Typography>
                  <Box component="ul">
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Plot sales volume or revenue per month for this product line? Or by location / region"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Identify any correlation between sales of any two product lines"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Plot numbers of clicks or impressions for each channel?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "What are the most frequent positive or negative reviews?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "How did the company perform in 2022?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "What risks are they facing?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "What growth strategies does the company have?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Which products and services are they offering?"
                    </LiText>
                  </Box>
                </Box>
              </LiText>
            </Box>
          </Box>
        )}
        {currentPage === 6 && (
          <Box component="div">
            <Typography
              sx={{
                fontSize: "12px",
                color: "#fff",
                fontWeight: "700",
                marginBottom: 1.5,
              }}
              variant="h5"
            >
              Operations - some examples using NeuralPit
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              1. Use <strong>"Chat with Documents"</strong> for Quick Access to Operating Procedures:
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{}}
              >
                <Box component="div">
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "12px",
                      color: "#fff",
                      display: "block",
                    }}
                  >
                    Upload operating procedures, training documents, or YouTube training videos and ask:
                  </Typography>
                  <Box component="ul">
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "What are the steps to perform this task?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "How do I operate this machine?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "How can I create a material PO in SAP?"
                    </LiText>
                  </Box>
                </Box>
              </LiText>
            </Box>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "#fff",
                display: "block",
              }}
            >
              2. Use <strong>"Analytics"</strong> to Analyze Operational Performance
            </Typography>
            <Box component="ul">
              <LiText
                component="li"
                sx={{}}
              >
                <Box component="div">
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "12px",
                      color: "#fff",
                      display: "block",
                    }}
                  >
                    Upload operational data and inquire:
                  </Typography>
                  <Box component="ul">
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Plot the total downtime by machine, rank from highest to lowest?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Which machines have the highest maintenance costs? List their age and total operating hours."
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Plot distribution of the total number of defects for product line X?"
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Plot the total safety incidents by department and rank from highest to lowest."
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Display a distribution for the number of incidents within this age group" or for males (or
                      females), or within this department."
                    </LiText>
                    <LiText
                      component="li"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      "Can you analyze the dataset and provide insights into the factors impacting the total number of
                      safety incidents?"
                    </LiText>
                  </Box>
                </Box>
              </LiText>
            </Box>
          </Box>
        )}
        {currentPage > 0 && (
          <Box
            component="div"
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{
                padding: 0,
                minWidth: "auto",
                fontSize: "12px",
                textDecoration: "underline",
                color: "#fff",
                textTransform: "capitalize",
              }}
              onClick={() => setCurrentPage(0)}
            >
              Home
            </Button>
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              <KeyboardArrowLeftIcon
                sx={{
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={handlePrevious}
              />

              <KeyboardArrowRightIcon
                sx={{
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={handleNext}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const UserGuide = () => {
  // state
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  // function
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box
        component="div"
        className="user-guide-box"
      >
        <CustomWidthTooltip
          title={<BoxUserGuide />}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Button
            onClick={handleTooltipOpen}
            sx={{
              padding: 0,
              minWidth: "auto",
              position: "relative",
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <UseGuideIcon />
            <Box
              component="div"
              sx={{
                position: "absolute",
                top: "-20px",
                right: "30px",
                backgroundColor: "#000000",
                color: "#fff",
                fontSize: "12px",
                textTransform: "capitalize",
                width: "max-content",
                borderRadius: "8px",
                padding: "5px 10px",
                opacity: hover ? 1 : 0,
                transitionDuration: "0.5s",
              }}
            >
              Use cases for NeuralPit tools
            </Box>
          </Button>
        </CustomWidthTooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default UserGuide;
