import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Footer = (props: any) => {
  return (
    <Typography
      variant="body1"
      color="text.secondary"
      align="center"
      {...props}
      lineHeight="30px"
      fontSize="12px"
    >
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://neuralpit.com"
      >
        NeuralPit Platform
      </Link>{" "}
      2024.
    </Typography>
  );
};

export default Footer;
