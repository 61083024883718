// GenerateCreate
import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

const GenerateCreate = () => {
  // state
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      {currentPage === 1 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1,
            }}
          >
            Generate and Innovate
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            Use this tool to generate ideas and innovate with teams.
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
              textDecoration: "underline",
            }}
          >
            Sample questions:
          </Typography>
          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Problem Solving: Ask for solutions to a problem using a structured problem-solving approach.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                  >
                    Provide ideals to reduce carbon emissions in transportation using a structured problem-solving
                    method.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Evaluate Ideas: Generate ideals and establish criterial for their evaluation.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                  >
                    Suggest the top 3 criteria to access ideals for reducing carbon emissions in transportation using a
                    structured problem-solving approach.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Marketing Content: Draft marketing content, create social media posts, analyze SEO or keywords, and
                determine SEO strategy.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                  >
                    Draft a LinkedIn post based on the following content, add suitable emoji: (copy & paste the content
                    here).
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {currentPage === 2 && (
        <Box
          component="div"
          sx={{}}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: 1,
            }}
          >
            Generate and Innovate
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
            }}
          >
            Use this tool to generate ideas and innovate with teams.
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
              textDecoration: "underline",
            }}
          >
            Sample questions (continued):
          </Typography>
          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Business Strategy: Utilize strategy frameworks such as Blue Ocean, Game Theory, and Scenario Planning.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                  >
                    Using the Blue Ocean Strategy, how can we strategic our entry into the sustainable fashion market
                    considering the flowing factors:
                  </Typography>

                  <Box
                    component="ul"
                    my={1}
                    padding={0}
                    paddingLeft={2}
                  >
                    <Box
                      component="li"
                      sx={{
                        fontSize: "12px",
                        listStyleType: "none",
                        fontStyle: "italic",
                        mt: 1,
                      }}
                    >
                      - Context: Overcrowded fast fashion market with growing ethical concerns.
                    </Box>
                    <Box
                      component="li"
                      sx={{
                        fontSize: "12px",
                        listStyleType: "none",
                        fontStyle: "italic",
                        mt: 1,
                      }}
                    >
                      - Product: Sustainable, biodegradable clothing.
                    </Box>
                    <Box
                      component="li"
                      sx={{
                        fontSize: "12px",
                        listStyleType: "none",
                        fontStyle: "italic",
                        mt: 1,
                      }}
                    >
                      - Market: Eco-conscious urbanites, aged 18-35.
                    </Box>
                    <Box
                      component="li"
                      sx={{
                        fontSize: "12px",
                        listStyleType: "none",
                        fontStyle: "italic",
                        mt: 1,
                      }}
                    >
                      - USP: Tech-integrated clothing for real-time design changes.
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "100",
              mt: 1,
              fontStyle: "italic",
            }}
          >
            How can we uniquely position ourselves, capture a distinctive market space, and eclipse competitors?
          </Typography>

          <Box
            component="ul"
            my={2}
            padding={0}
            paddingLeft={2}
          >
            <Box
              component="li"
              sx={{
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                }}
              >
                Risk Assessment: Conduct risk-reward and pre-mortem analyses for business decisions, devising mitigation
                strategies.
              </Typography>

              <Box
                component="ul"
                my={1}
                padding={0}
                paddingLeft={2}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                  >
                    Considering our plan to open 50 stores in Europe within a year, with local partnerships,
                    influencers, and a $10 million budget, can you provide a pre-mortem analysis highlighting potential
                    failures and solutions?
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          paddingY: 2,
          justifyContent: "space-between",
        }}
      >
        {currentPage === 2 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              setCurrentPage(1);
            }}
          >
            Previous
          </Button>
        )}
        {currentPage === 1 && (
          <Button
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#cccccc",
              },
              padding: "8px 10px",
              lineHeight: 1,
              color: "#000000",
            }}
            onClick={() => {
              setCurrentPage(2);
            }}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default GenerateCreate;
