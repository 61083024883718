import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";

interface ModalProps {
  open: boolean;
  setOpen: Function;
  onClick: Function;
  body: React.ReactNode;
  isDisabled?: boolean;
  labelConfirm?: string;
  isLoading?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const Modal = ({
  open,
  setOpen,
  onClick,
  body,
  isDisabled = false,
  labelConfirm = "Agree",
  isLoading = false,
}: ModalProps) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleClicK = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        zIndex: 9999999,
      }}
    >
      {body}
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClicK}
          sx={{
            textTransform: "capitalize",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
          disabled={isDisabled}
        >
          {isLoading && (
            <CircularProgress
              color="inherit"
              size="15px"
            />
          )}
          <span>{labelConfirm}</span>
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={handleClose}
          sx={{ textTransform: "capitalize", fontSize: "14px" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
