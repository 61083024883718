// UpdateIconAssistant

import React, { useState, useEffect } from "react";
import { Formik, FastField, Form } from "formik";
import { Tooltip, Button, Box, Typography, Stack } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

import { EditIcon } from "src/assest/icons";
import { SelectModel } from "src/libs/models";
import { Input, ModalNoAction, Select } from "src/components/common";
import { LIST_TOPIC } from "src/data";
import { INewSession } from "src/libs/models/assistant";
import { fetchSessionById, updateSessionAssistantById } from "src/libs/axios/api/chat";
import toast from "react-hot-toast";

interface EditSessionProps {
  sessionId: number;
  currentProject: SelectModel | null;
  callback: Function;
}

const UpdateIconAssistant: React.FC<EditSessionProps> = ({ sessionId, currentProject, callback }) => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [editSessionModal, setEditSessionModal] = useState(false);
  const [session, setSession] = useState<INewSession | null>();

  // function
  const getSessionById = async () => {
    const res = await fetchSessionById(currentProject?.teamId, currentProject?.projectId, sessionId);
    if (res) {
      setSession({ ...res });
    } else {
      setSession(null);
    }
  };

  const updateSessionById = async (values: INewSession) => {
    setIsLoading(true);
    const res = await updateSessionAssistantById(currentProject?.teamId, currentProject?.projectId, sessionId, values);
    setIsLoading(false);
    if (res) {
      toast.success("Update session successfully!");
      setEditSessionModal(false);
      callback();
    }
  };

  // effect
  useEffect(() => {
    if (editSessionModal) {
      getSessionById();
    }
  }, [sessionId, editSessionModal]);

  return (
    <React.Fragment>
      <Tooltip title="Edit Session">
        <Button
          sx={{
            minWidth: "auto",
            padding: "0",
            color: "#1672c9",
          }}
          onClick={() => {
            setEditSessionModal(true);
          }}
        >
          <EditIcon
            width="20"
            height="20"
          />
        </Button>
      </Tooltip>

      <ModalNoAction
        open={editSessionModal}
        setOpen={setEditSessionModal}
      >
        <Box
          component="div"
          sx={{
            width: "70vw",
            height: "70vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            mb={2}
            flexShrink={0}
          >
            Edit new chat session
          </Typography>
          <Formik
            initialValues={session}
            onSubmit={(values) => {
              updateSessionById(values);
            }}
            enableReinitialize
          >
            {() => {
              return (
                <>
                  <Form
                    style={{
                      height: "90%",
                    }}
                  >
                    <Stack
                      height="100%"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Stack gap={3}>
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Box
                              sx={{
                                width: "150px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                New Session
                              </Typography>
                            </Box>
                            <Box flexGrow={1}>
                              <FastField
                                name="name"
                                component={Input}
                                type="text"
                                placeHolder="Enter Name Here"
                              />
                            </Box>
                          </Stack>
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Box
                              sx={{
                                width: "150px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Topic
                              </Typography>
                            </Box>
                            <Box flexGrow={1}>
                              <FastField
                                name="topic"
                                component={Select}
                                options={LIST_TOPIC}
                                label="Topic"
                              />
                            </Box>
                          </Stack>

                          <Stack flexDirection="row">
                            <Typography
                              width="150px"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              Objective
                            </Typography>
                            <Box flexGrow={1}>
                              <FastField
                                name="objective"
                                component={Input}
                                type="text"
                                placeHolder="optional - a brief description about the chat session objectives"
                                isMultiple={true}
                                row={3}
                              />
                            </Box>
                          </Stack>
                          {/* <Stack flexDirection="row" alignItems="baseline">
                            <Typography width="150px">Websites</Typography>
                            <Box flexGrow={1}>
                              <FieldArray
                                name="links"
                                render={(arrayHelper) => {
                                  return (
                                    <>
                                      <Stack gap="15px">
                                        {formikProps.values.links.map(
                                          (link, index) => (
                                            <Stack
                                              key={`link-${index}`}
                                              flexDirection="row"
                                              justifyContent="space-between"
                                              gap="15px"
                                            >
                                              <Box
                                                sx={{
                                                  flexGrow: 1,
                                                }}
                                              >
                                                <FastField
                                                  name={`links.${index}`}
                                                  component={Input}
                                                  type="text"
                                                  placeHolder="Web links or youtube links"
                                                />
                                              </Box>
                                              <Button
                                                sx={{
                                                  paddingLeft: 0,
                                                  minWidth: "auto",
                                                }}
                                                onClick={() => {
                                                  arrayHelper.remove(index);
                                                }}
                                              >
                                                <IconWrapper>
                                                  <RemoveCircleOutlineIcon />
                                                </IconWrapper>
                                              </Button>
                                            </Stack>
                                          )
                                        )}
                                      </Stack>
                                      <Box>
                                        <Button
                                          sx={{
                                            paddingLeft: 0,
                                            minWidth: "auto",
                                          }}
                                          onClick={() => {
                                            arrayHelper.push("");
                                          }}
                                        >
                                          <IconWrapper>
                                            <AddCircleOutlineIcon />
                                          </IconWrapper>
                                        </Button>
                                      </Box>
                                    </>
                                  );
                                }}
                              />
                            </Box>
                          </Stack> */}
                        </Stack>
                      </Box>
                      <Box textAlign="right">
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                          }}
                          type="submit"
                          variant="contained"
                          startIcon={
                            !isLoading ? (
                              ""
                            ) : (
                              <CircularProgress
                                color="inherit"
                                size="15px"
                              />
                            )
                          }
                          disabled={isLoading}
                        >
                          {sessionId ? "Update" : "Add"}
                        </Button>
                      </Box>
                    </Stack>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </ModalNoAction>
    </React.Fragment>
  );
};

export default UpdateIconAssistant;
