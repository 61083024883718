import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ITeam } from "src/libs/models/team";

interface SelectBaseProps {
  data: ITeam[];
  value: string;
  id: string;
  labelId: string;
  label: string;
  setValue: Function;
}

const SelectBase: React.FC<SelectBaseProps> = ({
  data,
  value,
  id,
  labelId,
  label,
  setValue,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <FormControl
      fullWidth
      sx={{
        "& label": {
          fontSize: "14px",
          top: "-3px",
        },
        "& label.Mui-focused": {
          lineHeight: "2.4375em",
          left: "5px",
        },
        "& label.MuiInputLabel-shrink": {
          lineHeight: "2.4375em",
        },
      }}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        value={value}
        label={label}
        onChange={handleChange}
      >
        {/* <MenuItem value={10}>Ten</MenuItem> */}
        {data?.length > 0 &&
          data.map((d) => (
            <MenuItem
              key={d.id}
              value={d.id}
              sx={{
                fontSize: "14px",
              }}
            >
              {d.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectBase;
