// Hiring
import { Box, Typography } from "@mui/material";

const Hiring = () => {
  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      <Box
        component="div"
        sx={{}}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: 1,
          }}
        >
          Talent Acquisition Assistant
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Use this tool to craft job posts, create tailored interview questions for specific roles, and share.
        </Typography>

        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 2,
          }}
        >
          Sample questions:
        </Typography>
        <Box
          component="ul"
          mb={2}
          mt={1}
          padding={0}
          paddingLeft={2}
        >
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Create a job post for the following role: Company: Google
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Title: Marketing Manager
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Location: Flexible Working (Remote)
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Responsibilities: Grow and manage social media assets.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Team Structure: Report directly to the Regional Marketing Director.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Qualifications: minimum
            </Typography>
          </Box>
        </Box>

        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 2,
          }}
        >
          If you do not want to provide further details yet and just want to get a draft of the job post, simply ask:
          "Just provide me a draft". You can still add more details to the draft later.
        </Typography>

        <Box
          component="ul"
          mb={2}
          mt={1}
          padding={0}
          paddingLeft={2}
        >
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Create a list of interview questions for this role
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Hiring;
