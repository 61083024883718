import React from "react";

const GoogleDriveCallback = () => {
  React.useEffect(() => {
    const queryString = window.location.search;
    // alert(queryString);
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    const split = state?.split("__");
    const provider = split[0];

    if (provider === "google_drive") {
      localStorage.setItem("google_drive_code", JSON.stringify({ code, state }));
    } else if (provider === "onedrive") {
      localStorage.setItem("one_drive_code", JSON.stringify({ code, state }));
    } else if (provider === "gmail") {
      localStorage.setItem("gmail_code", JSON.stringify({ code, state: state }));
    } else if (provider === "outlook") {
      localStorage.setItem("outlook_code", JSON.stringify({ code, state: state }));
    } else if (provider === "linkedin") {
      localStorage.setItem("linkedin_code", JSON.stringify({ code, state }));
    } else if (provider === "facebook") {
      localStorage.setItem("facebook_code", JSON.stringify({ code, state }));
    } else if (provider === "twitter") {
      localStorage.setItem("twitter_code", JSON.stringify({ code, state }));
    }

    window.close();
  }, []);

  return <></>;
};

export default GoogleDriveCallback;
