import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Formik, Form, FastField } from "formik";
import { Box, Button, Container } from "@mui/material";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { Input } from "src/components/common";
import { createTeam, getTeamById, patchTeamById } from "src/libs/axios/api/team";
import { ITeam } from "src/libs/models/team";

const AddTeamPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { teamId } = params;

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ITeam>({
    id: 0,
    name: "",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });

  // function
  const createNewTeam = async (payload: ITeam) => {
    setIsLoading(true);
    const res = await createTeam(payload);
    setIsLoading(false);
    if (res) {
      navigate("/team");
    }
  };

  const updateTeam = async (payload: ITeam) => {
    setIsLoading(true);
    const res = await patchTeamById(payload);
    setIsLoading(false);
    if (res) {
      toast.success("Update team successfully!");
      navigate("/team");
    }
  };

  const getTeam = async () => {
    const res = await getTeamById(Number(teamId));
    if (res) {
      setData((prev) => ({
        ...prev,
        ...res,
      }));
    }
  };

  // useEffect
  useEffect(() => {
    if (teamId !== "0") {
      getTeam();
    }
  }, [teamId]);

  return (
    <Container
      component="main"
      maxWidth="sm"
    >
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Typography variant="h4">Add New Team</Typography>
        <Formik
          initialValues={data}
          onSubmit={(values) => {
            if (teamId === "0") {
              createNewTeam(values);
            } else {
              updateTeam({
                id: teamId,
                ...values,
              });
            }
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {(formikProps) => {
            return (
              <Box
                component="div"
                sx={{ mt: 1 }}
              >
                <Form>
                  <FastField
                    name="name"
                    component={Input}
                    type="text"
                    placeHolder="Name *"
                    isFocus={true}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 1 }}
                    disabled={!(formikProps.isValid && formikProps.dirty) || isLoading}
                    startIcon={
                      !isLoading ? (
                        ""
                      ) : (
                        <CircularProgress
                          color="inherit"
                          size="15px"
                        />
                      )
                    }
                  >
                    Submit
                  </Button>
                </Form>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </Container>
  );
};

export default AddTeamPage;
