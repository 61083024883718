import * as React from "react";
import { Formik, Form, FastField } from "formik";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { Select, Input, ModalNoAction } from "src/components/common";
import { getMemberByIdAndTeamSlug, pathMemberByIdAndTeamSlug } from "src/libs/axios/api/membership";
import { IMember } from "src/libs/models/team";
import { ROLES } from "src/data";

interface EditMemberModalProps {
  open: boolean;
  memberId: number;
  teamId: number;
  setOpen: Function;
  callback: Function;
}

const EditMemberModal = ({ open, memberId, teamId, setOpen, callback }: EditMemberModalProps) => {
  // state
  const [data, setData] = React.useState<IMember>({
    id: 0,
    team: 0,
    email: "",
    role: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  // function
  const updateRoleMember = async (role: string) => {
    setIsLoading(true);
    const res = await pathMemberByIdAndTeamSlug(Number(memberId), teamId, role);
    setIsLoading(false);
    if (res) {
      toast.success("Update Successful!");
      setOpen(false);
      callback();
    }
  };

  const getMemberInfo = async () => {
    const res = await getMemberByIdAndTeamSlug(Number(memberId), teamId);
    if (res) {
      setData((prev) => ({
        ...prev,
        ...res,
      }));
    }
  };

  // useEffect
  React.useEffect(() => {
    if (open && memberId) {
      getMemberInfo();
    }
  }, [open, memberId]);

  return (
    <ModalNoAction
      open={open}
      setOpen={setOpen}
    >
      <Typography
        variant="h4"
        mb={2}
      >
        Edit Team Member
      </Typography>
      <Box>
        <Formik
          initialValues={data}
          onSubmit={(values) => {
            updateRoleMember(values?.role);
          }}
          enableReinitialize
        >
          {(formikProps) => {
            return (
              <Form>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "25px",
                  }}
                >
                  <FastField
                    name="email"
                    component={Input}
                    type="text"
                    placeHolder="Email *"
                  />
                  {data.role !== "owner" && (
                    <FastField
                      name="role"
                      component={Select}
                      options={ROLES}
                      label="Role"
                    />
                  )}
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1, textTransform: "capitalize" }}
                  disabled={!formikProps.values.email || !formikProps.values.role}
                  startIcon={
                    !isLoading ? (
                      ""
                    ) : (
                      <CircularProgress
                        color="inherit"
                        size="15px"
                      />
                    )
                  }
                >
                  Save
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ModalNoAction>
  );
};

export default EditMemberModal;
