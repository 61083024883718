import React from "react";
import { Typography, Box } from "@mui/material";
import { FaRegShareFromSquare } from "react-icons/fa6";

const ABOUT_HR_ASSISTANT = [
  {
    id: 1,
    content: "Create a new project for managing extensive hiring events. (optional).",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Create a new project for managing extensive hiring events. (optional).
      </Typography>
    ),
  },
  {
    id: 2,
    content: "Initiate separate chat sessions for each hiring phase or smaller events.",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Initiate separate chat sessions for each hiring phase or smaller events.
      </Typography>
    ),
  },
  {
    id: 3,
    content: "Team members can all join in shared chat sessions.",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Team members can all join in shared chat sessions.
      </Typography>
    ),
  },
  {
    id: 4,
    content: "",
    contentHTML: (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Click on (<FaRegShareFromSquare fontSize="12px" />
        &nbsp;share) Share in each response to Edit and Share via email, or posted on LinkedIn, Facebook Page or X
        (Twitter).
      </Typography>
    ),
  },
];

const AboutHrAssistant = () => {
  return (
    <React.Fragment>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: 1,
        }}
      >
        Human Resource Assistants
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "100",
          mt: 1,
        }}
      >
        Assist in HR tasks like hiring and onboarding. This includes drafting job postings, sharing them via emails,
        Facebook page and other platforms, and formulating tailored interview questions. The Assistants also offers
        interactive learning and HR performance analytics.
      </Typography>

      <Box
        component="ul"
        sx={{
          paddingLeft: 2,
        }}
      >
        {ABOUT_HR_ASSISTANT.map((a) => (
          <Box
            key={`about-hr-assistant-${a.id}`}
            component="li"
            sx={{}}
          >
            {a.contentHTML}
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default AboutHrAssistant;
