import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import { RootState } from "src/store/store";

const LoadingCircle = () => {
  // state
  const is_loading = useSelector((state: RootState) => state.loading.is_loading);

  return (
    <>
      {is_loading && (
        <Box
          component="div"
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            left: 0,
            marginLeft: "5px",
            zIndex: 99999999999,
          }}
        >
          <CircularProgress sx={{}} />
        </Box>
      )}
    </>
  );
};

export default LoadingCircle;
