import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";

import { ModalNoAction } from "src/components/common";
import SessionInformation from "./SessionInformation";

interface EditSessionModalProps {
  teamId: number;
  projectId: number;
  sessionId: number;
  open: boolean;
  setOpen: Function;
  callback: Function;
}

const LIST_TABS = [
  {
    id: 1,
    label: "New chat session",
    value: "1",
  },
];

const EditSessionModal = ({
  teamId,
  projectId,
  sessionId,
  open,
  setOpen,
  callback,
}: EditSessionModalProps) => {
  // state
  const [currentStep, setCurrentStep] = useState("1");

  // function
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentStep(newValue);
  };

  return (
    <ModalNoAction open={open} setOpen={setOpen}>
      <Box
        sx={{
          width: "85vw",
          height: "85vh",
        }}
      >
        <Typography variant="h5" mb={2}>
          Add new chat session
        </Typography>
        <Box
          sx={{
            height: "95%",
          }}
        >
          <TabContext value={currentStep}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <TabList onChange={handleChangeTab} aria-label="Create session">
                {LIST_TABS.map((tab) => (
                  <Tab
                    key={tab.id}
                    label={tab.label}
                    value={tab.value}
                    sx={{
                      textTransform: "none",
                      backgroundColor: `${
                        tab.value === currentStep ? "#1976d2" : ""
                      }`,
                      color: `${
                        tab.value === currentStep
                          ? "#ffffff !important"
                          : "#1976d2"
                      }`,
                    }}
                  />
                ))}
              </TabList>
            </Box>
            <TabPanel
              value="1"
              sx={{
                height: "90%",
              }}
            >
              <SessionInformation
                teamId={teamId}
                projectId={projectId}
                sessionId={sessionId}
                setOpen={setOpen}
                callback={callback}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </ModalNoAction>
  );
};

export default EditSessionModal;
