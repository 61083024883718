import { ITeam, ITeamResponse } from "src/libs/models/team";
import { handleError } from "src/libs/hooks";
import api from "../api";

export const createTeam = async (payload: ITeam): Promise<ITeam | undefined> => {
  try {
    const res = await api.post("/teams", { name: payload.name })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const getListTeamByPage = async (): Promise<ITeamResponse | undefined> => {
  try {
    const res = await api.get(`/teams`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const getTeamById = async (id: number): Promise<ITeam | undefined> => {
  try {
    const res = await api.get(`/teams/${id}`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const updateTeamById = async (payload: ITeam): Promise<ITeam | undefined> => {
  const { id, name } = payload

  try {
    const res = await api.put(`/teams/${id}`, { name })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const patchTeamById = async (payload: ITeam): Promise<ITeam | undefined> => {
  const { id, name } = payload

  try {
    const res = await api.patch(`/teams/${id}`, { name })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const deleteTeamById = async (id: number) => {
  try {
    await api.delete(`/teams/${id}`)
    return "Delete Team Successful"
  } catch (error) {
    console.warn(error)
    handleError(error)
  }
  return undefined
}

export const getTeamQuotaByTeamId = async (teamId: number) => {
  try {
    const res = await api.get(`/teams/${teamId}/teamquotas`)
    return res.data
  } catch (err) {
    console.warn(err)
    handleError(err)
  }
  return undefined
}

export const getInvitationsPending = async (teamId: number,) => {
  try {
    const res = await api.get(`/teams/${teamId}/invitations-pending`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const fetchTeamStatus = async (teamId: number) => {
  try {
    const res = await api.get(`/teams/${teamId}/teamquotas/status`)
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}