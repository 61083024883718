import { Button, Stack, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { WorkStationIcon } from "src/assest/icons";
import { BodyText } from "src/libs/AppStyle";

interface WorkstationItemProps {
  pathName: string;
}

const WorkstationItem: React.FC<WorkstationItemProps> = ({ pathName }) => {
  const navigate = useNavigate();

  return (
    <Tooltip title="Analyze docs, websites & data, visualize ideas">
      <Button
        sx={{
          padding: 0,
          paddingX: "5px",
          color: `${pathName?.includes("workstation") ? "#1672c9" : "black"}`,
          "&:hover": {
            backgroundColor: "transparent",
            color: "#1672c9",
            "& svg": {
              color: "#1672c9",
            },
          },
        }}
        className="hover-btn"
        onClick={() => {
          navigate("/middle-workstation");
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
        >
          <WorkStationIcon className={pathName?.includes("workstation") ? "activate-svg" : "default-svg"} />
          <BodyText
            variant="caption"
            sx={{
              fontSize: "12px !important",
              textTransform: "capitalize",
              color: "inherit !important",
            }}
          >
            Workstation
          </BodyText>
        </Stack>
      </Button>
    </Tooltip>
  );
};

export default WorkstationItem;
