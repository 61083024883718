import { Box, Typography } from "@mui/material";

const ContractAnalysis = () => {
  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      <Box
        component="div"
        sx={{}}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: 1,
          }}
        >
          Contract Analysis Assistant
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Use this tool to upload contracts, analyze and extract contract.
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Upload one or multiple Contract (in pdf, csv, docs, docx), and ask questions.
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Sample questions for reviewing procurement contracts:
        </Typography>

        <Box
          component="ul"
          mb={2}
          mt={1}
          padding={0}
          paddingLeft={2}
        >
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              "What specific services are included in the scope of this agreement?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              "How is the pricing structured, and what are the payment terms?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              "What is the duration of this contract, and under what conditions can it be terminated?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              "What are the delivery timelines for the services provided?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              "How will the provider's performance be measured and reported?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              "What measures are in place for confidentiality and data security?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              "How does the contract address liability and risk management?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              "What are the procedures for dispute resolution?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              "Does this contract ensure compliance with relevant laws and industry standards?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              "What is the process for managing changes to the contract or service scope
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContractAnalysis;
