import { handleError } from "src/libs/hooks";
import { IInvitations, IInvitationsResponse } from "src/libs/models/invitations";
import api from "../api";
import { IMember } from "src/libs/models/team";

export const getListInvitationsByTeamSlug = async (page: number, teamSlug: string): Promise<IInvitationsResponse | undefined> => {
  try {
    const res = await api.get(`/a/${teamSlug}/team/api/invitations`, {
      params: { page }
    })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const createNewInvitationsByTeamSlug = async (payload: IMember) => {
  const { team, email, role, invited_by, is_accepted } = payload
  try {
    const res = await api.post(`/teams/${team}/invitations`, { team, email, role, invited_by, is_accepted })
    return res.data
  } catch (error) {
    handleError(error, team)
    return undefined
  }
}

export const getInvitationByTeamSlugAndId = async (teamSlug: string, id: string): Promise<IInvitations | undefined> => {
  try {
    const res = await api.get(`/a/${teamSlug}/team/api/invitations/${id}`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const updateInvitationByTeamSlugAndId = async (teamSlug: string, id: string): Promise<IInvitations | undefined> => {
  try {
    const res = await api.put(`/a/${teamSlug}/team/api/invitations/${id}`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const replaceInvitationByTeamSlugAndId = async (teamSlug: string, id: string): Promise<IInvitations | undefined> => {
  try {
    const res = await api.patch(`/a/${teamSlug}/team/api/invitations/${id}`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const deleteInvitationById = async (id: string) => {
  try {
    await api.delete(`/teams/invitations/${id}`)
    return "Delete Successful"
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const resendInvitation = async (payload: IMember) => {
  const { team, email, role, invited_by, is_accepted } = payload

  try {
    const res = await api.post(`/teams/${team}/resend-invitation`, {
      team,
      email,
      role,
      invited_by,
      is_accepted
    })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}