import { handleError, handleErrorNotify } from "src/libs/hooks"
import api from "../api"
import { IChatDocuments, IHistoriesChatDocument, IHistoryChat, ISession } from "src/libs/models/chat"
import { IConversation } from "src/libs/models"
import { INewSession, INewSessionModel } from "src/libs/models/assistant"

export const createNewChat = async (teamId: number, projectId: number, session: ISession): Promise<ISession | undefined> => {
  const { name, topic, content, objective } = session

  try {
    const res = await api.post(`/a/${teamId}/project/${projectId}/chats`, { name, topic, content, objective })
    return res.data?.chat
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const getListChats = async (page: number, teamId: number, projectId: number): Promise<ISession[] | undefined> => {
  if (!teamId || !projectId) {
    return
  }
  try {
    const res = await api.get(`/a/${teamId}/project/${projectId}/chats`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const deleteChat = async (id: number, teamId: number, projectId: number): Promise<string | undefined> => {
  try {
    await api.delete(`/a/${teamId}/project/${projectId}/chats/${id}`)
    return "Delete successful"
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const uploadDocumentToChat = async (id: string, projectId: number, teamId: number, key: any, chat_id: string): Promise<IChatDocuments | undefined> => {
  let payload: any
  if (chat_id !== "0") {
    payload = { key, chat_id: Number(chat_id) }
  } else {
    payload = { key }
  }
  try {
    const res = await api.post(`/a/${teamId}/project/${projectId}/conversations/${id}/upload_document`, payload)
    return res.data?.message || "Documents uploaded successfully."
  } catch (error) {
    console.warn(error)
    handleErrorNotify(error, teamId)
    return undefined
  }
}

export const getChatSessionById = async (id: number, projectId: number, teamId: number): Promise<ISession | undefined> => {
  try {
    const res = await api.get(`/a/${teamId}/project/${projectId}/chats/${id}`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const getDocumentByChatId = async (teamId: number, projectId: number, id: string): Promise<IConversation[] | undefined> => {
  if (!id) return
  try {
    const res = await api.get(`/a/${teamId}/project/${projectId}/conversations/${id}/documents`)
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const getDocumentVisualization = async (teamId: number, projectId: number, conversation_id: string): Promise<IConversation | undefined> => {
  try {
    const res = await api.get(`/a/${teamId}/project/${projectId}/conversations/${conversation_id}/visualization_datasource`)
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const updateSessionById = async (teamId: number, projectId: number, id: number, session: ISession): Promise<ISession | undefined> => {
  const { name, topic, content, objective } = session
  try {
    const res = await api.patch(`/a/${teamId}/project/${projectId}/chats/${id}`, { name, topic, content, objective })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}


export const uploadFileChat = async (teamId: number, projectId: number, id: string, files: File[], folder: string, chat_id: number) => {
  if (!files) {
    return
  }

  const formData = new FormData();
  formData.append("folder", folder)
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i])
  }
  if (id) {
    formData.append("external_id", id)
  }
  if (chat_id) {
    formData.append("chat_id", chat_id.toString())
  }
  if (teamId) {
    formData.append("project_id", projectId.toString())
  }
  if (projectId) {
    formData.append("team_id", teamId.toString())
  }
  try {
    const res = await api.post(`/a/${teamId}/project/${projectId}/conversations/upload_file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res.data
  } catch (error) {
    handleErrorNotify(error, teamId)
    return undefined
  }
}

export const deleteFileOnSession = async (teamId: number, projectId: number, id: string, document_id: string) => {
  try {
    const res = await api.delete(`/a/${teamId}/project/${projectId}/conversations/${id}/document/${document_id}`)
    return res.data?.message || "Document deleted successfully."
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const getChatHistories = async (teamId: number, projectId: number, id: string): Promise<IHistoryChat[] | undefined> => {
  if (!id) return undefined
  try {
    const res = await api.get(`/a/${teamId}/project/${projectId}/conversations/${id}/history`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const fetchChatHistoriesChatPage = async (teamId: number, projectId: number, id: string, offset: number) => {
  if (!id || !projectId || !id) return undefined
  try {
    const res = await api.get(`/a/${teamId}/project/${projectId}/conversations/${id}/history?offset=${offset}&count=10`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const getHistoriesChatDocument = async (teamId: number, projectId: number, id: string): Promise<IHistoriesChatDocument[] | undefined> => {
  try {
    const res = await api.get(`/a/${teamId}/project/${projectId}/conversations/${id}/history`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const addLinkToSession = async (teamId: number, projectId: number, sessionId: string, conversation_id: string, url: string, mode: string) => {
  let payload: any

  if (sessionId !== "0") {
    payload = {
      url,
      mode,
      external_id: conversation_id,
      chat_id: sessionId
    }
  } else {
    payload = {
      url,
      mode
    }
  }

  try {
    const res = await api.post(`/a/${teamId}/project/${projectId}/conversations/website_document`, payload)
    return res.data
  } catch (error) {
    console.warn(error)
    handleErrorNotify(error, teamId)
    return undefined
  }
}

export const fetchListSessionByAssistantID = async (teamId: number, projectId: number, assistantID: number): Promise<INewSession[] | undefined> => {
  try {
    const res = await api.get(`/a/${teamId}/project/${projectId}/chats-v2?assistant_id=${assistantID}`)
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const addNewSessionByAssistantID = async (teamId: number, projectId: number, session: ISession): Promise<INewSessionModel | undefined> => {
  const { name, assistant_id, topic, content, objective } = session

  try {
    const res = await api.post(`/a/${teamId}/project/${projectId}/chats-v2`, { name, assistant_id, topic, content, objective })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const fetchSessionById = async (teamId: number, projectId: number, id: number): Promise<INewSession | undefined> => {
  try {
    const res = await api.get(`/a/${teamId}/project/${projectId}/chats-v2/${id}`)
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}

export const updateSessionAssistantById = async (teamId: number, projectId: number, id: number, session: INewSession): Promise<INewSession | undefined> => {
  try {
    const res = await api.put(`/a/${teamId}/project/${projectId}/chats-v2/${id}`, {
      name: session.name,
      topic: session.topic,
      content: session.content,
      objective: session.objective,
    })

    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}