import * as React from "react";
import { TransitionProps } from "@mui/material/transitions";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import { styled } from "@mui/material/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, Box, Typography, Tab, Input } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";

import { addPaymentMethod } from "src/libs/axios/api/payment";
// import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

interface ModalProps {
  open: boolean;
  teamId: string;
  setOpen: Function;
  onClick: Function;
  callback?: Function;
}

const StyledInput = styled(Input)(() => ({
  "& .MuiInputBase-input": {
    padding: "0px",
    fontSize: "16px",
    height: "1.2em",
    lineHeight: "1.2em",
    "&::placeholder": {
      color: "#424770",
    },
  },
}));

const styles = {
  base: {
    fontSize: "16px",
    color: "#424770",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const CardForm = (props: any) => {
  const elements = useElements();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = React.useState(false);
  const [holder, setHolder] = React.useState("");

  React.useEffect(() => {
    const cardNumberElement: any = elements?.getElement(CardNumberElement);
    if (cardNumberElement) cardNumberElement.clear();
    const expiryElement: any = elements?.getElement(CardExpiryElement);
    if (expiryElement) expiryElement.clear();
    const cvcElement: any = elements?.getElement(CardCvcElement);
    if (cvcElement) cvcElement.clear();
    setHolder("");
  }, [props.open]);

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      if (!stripe || !elements) return;
      const cardNumberElement: any = elements?.getElement(CardNumberElement);
      if (!cardNumberElement) {
        setIsLoading(false);
        toast.error("No exit card!");
      }
      const token = await stripe.createToken(cardNumberElement);

      if (token.error) {
        toast.error(token.error.message);
        setIsLoading(false);
      } else {
        const res: any = await addPaymentMethod(props.teamId, {
          token: token.token.id,
          holder: holder,
        });
        if (res?.success) {
          // navigate(`/payment/${props.teamId}/method`);
          // props.callback && props.callback();
          if (props?.callback) {
            props.callback();
          }
          setIsLoading(false);
          props.handleClose();
        }
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Typography>Card Number</Typography>
        <Box
          sx={{
            border: "1px solid #E1E0DF",
            borderRadius: 15,
            padding: "5px 10px",
          }}
        >
          <CardNumberElement
            options={{
              style: styles,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "45%",
          }}
        >
          <Typography>Expiry Date</Typography>
          <Box
            sx={{
              border: "1px solid #E1E0DF",
              borderRadius: 15,
              padding: "5px 10px",
            }}
          >
            <CardExpiryElement
              options={{
                style: styles,
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "45%",
          }}
        >
          <Typography>CVC</Typography>
          <Box
            sx={{
              border: "1px solid #E1E0DF",
              borderRadius: 15,
              padding: "5px 10px",
            }}
          >
            <CardCvcElement
              options={{
                style: styles,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Typography>Card Holder</Typography>
        <Box
          sx={{
            border: "1px solid #E1E0DF",
            borderRadius: 15,
            padding: "5px 10px",
          }}
        >
          <StyledInput
            value={holder}
            fullWidth
            onChange={(e) => setHolder(e.target.value)}
            disableUnderline={true}
            required
            placeholder="Full name displayed on card"
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ textTransform: "capitalize" }}
          startIcon={
            !isLoading ? (
              ""
            ) : (
              <CircularProgress
                color="inherit"
                size="15px"
              />
            )
          }
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={props.handleClose}
          sx={{ textTransform: "capitalize", ml: 2 }}
        >
          Cancel
        </Button>
      </Box>
    </form>
  );
};

const PaymentModal = ({ open, setOpen, teamId, callback }: ModalProps) => {
  const [value, setValue] = React.useState("1");
  // const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue("1");
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box
        m={2}
        minWidth={320}
      >
        <Typography variant="h6">Payment Method</Typography>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="Profile Tab"
            >
              <Tab
                label="Stripe"
                value="1"
                sx={{
                  textTransform: "capitalize",
                }}
              />
              <Tab
                label="PayPal"
                value="2"
                sx={{
                  textTransform: "capitalize",
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Elements stripe={stripePromise}>
              <CardForm
                open={open}
                teamId={teamId}
                handleClose={handleClose}
                callback={callback}
              />
            </Elements>
          </TabPanel>
          <TabPanel value="2">
            <PayPalScriptProvider options={{ clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
              <PayPalButtons
                fundingSource="paypal"
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: "0.1",
                        },
                      },
                    ],
                    application_context: {
                      return_url: "http://localhost:3000/paypal-callback",
                      cancel_url: "http://localhost:3000/paypal-callback",
                    },
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then(async (details) => {
                    const res: any = await addPaymentMethod(teamId, {
                      payer: details.payer,
                    });
                    if (res?.success) {
                      handleClose();
                      if (callback) {
                        callback();
                      }
                    }
                  });
                }}
                // createSubscription={(data, actions) => {
                //   return actions.subscription.create({
                //     /* Creates the subscription */
                //     plan_id: "P-3L053249RR4221356MVKYO4I",
                //   });
                // }}
              />
            </PayPalScriptProvider>
          </TabPanel>
        </TabContext>
      </Box>
    </Dialog>
  );
};

export default PaymentModal;
