import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { FastFieldProps } from "formik";
import { ISelectModel } from "src/libs/models";

interface SelectNoActionProps extends FastFieldProps {
  options: ISelectModel[];
  label: string;
}

export default function SelectNoAction({
  options,
  label,
  field,
}: SelectNoActionProps) {
  const { onChange, name, value } = field;

  const handleChange = (e: SelectChangeEvent) => {
    const changeEvent = {
      target: {
        name: name,
        value: e.target.value,
      },
    };
    onChange(changeEvent);
  };

  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiSelect-select": {
          padding: "0 14px",
          height: "47px !important",
          lineHeight: "47px",
          fontSize: "14px",
        },
      }}
    >
      <FormControl
        fullWidth
        sx={{
          "& label": {
            fontSize: "14px",
            top: "-3px",
          },
          "& label.Mui-focused": {
            lineHeight: "2.4375em",
            left: "5px",
          },
          "& label.MuiInputLabel-shrink": {
            lineHeight: "2.4375em",
          },
        }}
      >
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          id={name}
          value={value}
          label={label}
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{
                fontSize: "14px",
              }}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
