import * as Yup from "yup";

import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FastField, Form, Formik } from "formik";
import { Box, Button, Divider, Stack } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { Input } from "src/components/common";
import { setToken } from "src/libs/axios/api";
import { loginApi } from "src/libs/axios/api/auth";
import { LinkRouter } from "src/libs/AppStyle";
import { login, addRefreshToken } from "src/store/features/auth";
import { HRIcon, ImprovementIcon, MarketingIcon, ProcurementIcon } from "src/assest/icons";

import Footer from "src/components/Footer";
import CustomGoogleLogin from "src/components/Auth/CustomGoogleLogin";
import CustomLinkedinLogin from "src/components/Auth/CustomLinkedinLogin";

const LOGIN_DATA = [
  {
    id: 1,
    text: "Effortlessly extract insights from vast documents, videos, audios, websites, YouTube content, and complex datasets.",
  },
  {
    id: 2,
    text: "Foster team creativity and visualize concepts, enhance collaboration in shared chat sessions.",
  },
  {
    id: 3,
    text: "Craft content, edit and share across social platforms (LinkedIn, Facebook Page and X (Twitter), and elevate your digital presence.",
  },
];

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlRedirect = searchParams.get("redirect");
  const invitation_id = searchParams.get("invitation_id") ? (searchParams.get("invitation_id") as string) : null;

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().required(),
  });

  // functions
  const loginAccount = async (credentials: { email: string; password: string }) => {
    localStorage.removeItem("previous_url");
    setIsLoading(true);
    const res = await loginApi(credentials);
    setIsLoading(false);
    Login(res);
  };

  const Login = (response) => {
    if (response?.access_token) {
      setToken(response.access_token);
      dispatch(login(response.access_token));
      dispatch(addRefreshToken(response.refresh_token));
      if (urlRedirect) {
        navigate(urlRedirect);
        return;
      }
      localStorage.removeItem("current_project");
      localStorage.removeItem("previous_url");
      setTimeout(() => {
        navigate("/middle-workstation");
      }, 1000);
    }
  };

  return (
    <>
      <Helmet>
        <title>The best AI platform for businesses to accelerate innovation and productivity.</title>
        <meta
          name="description"
          content="Accelerate innovation and productivity using AI Assistants. 
Chat with documents, websites, video, and data anytime, anywhere to drive insights and 
actions."
        />
        <meta
          name="robots"
          content="index, follow"
        />
        <link
          rel="canonical"
          href="https://Platform.neuralpit.com/login"
        />

        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:title"
          content="The best AI platform for businesses to accelerate innovation 
and productivity."
        />
        <meta
          property="og:description"
          content="Accelerate innovation and productivity using AI 
Assistants. Chat with documents, websites, video, and data anytime, anywhere to drive insights 
and actions."
        />
        <meta
          property="og:image"
          content="https://neuralpit.com/images/AI_Assistants_for_Business.png"
        />
        <meta
          property="og:url"
          content="https://Platform.neuralpit.com/login"
        />
        <meta
          property="og:site_name"
          content="NeuralPit Platform"
        />

        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:site"
          content="@NeuralPit"
        />
        <meta
          name="twitter:title"
          content="The best AI platform for businesses to accelerate innovation 
and productivity."
        />
        <meta
          name="twitter:description"
          content="Accelerate innovation and productivity using AI 
Assistants. Chat with documents, websites, video, and data anytime, anywhere to drive insights 
and actions."
        />
        <meta
          name="twitter:image"
          content="https://neuralpit.com/images/AI_Assistants_for_Business.png"
        />
      </Helmet>
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          minHeight: "100vh",
          paddingY: 0,
          overflowY: "auto",
          height: "100%",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <Box
            component="div"
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              maxWidth: "600px",
              paddingTop: 4,
            }}
          >
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "600",
                marginBottom: 0.5,
              }}
            >
              Welcome to NeuralPit!
            </Typography>

            {/* <Typography
              sx={{
                fontSize: "16px",
              }}
            >
              Analyze, Create and Share - Fast! with AI Assistants.
            </Typography> */}
            <Typography
              sx={{
                fontSize: "16px",
                mb: 3,
              }}
            >
              Empower businesses and professionals to achieve more in less time.
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "#1672C9" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                mb={3}
              >
                Sign In
              </Typography>

              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={(values) => {
                  loginAccount(values);
                }}
                validationSchema={validationSchema}
              >
                {(formikProps) => {
                  return (
                    <Form
                      style={{
                        width: "100%",
                      }}
                    >
                      <Box
                        component="div"
                        sx={{ mt: 1 }}
                      >
                        <Stack gap={3}>
                          <FastField
                            name="email"
                            component={Input}
                            type="text"
                            placeHolder="Email Address *"
                            isFocus={true}
                          />
                          <FastField
                            name="password"
                            component={Input}
                            type="password"
                            placeHolder="Password *"
                          />
                        </Stack>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2, textTransform: "capitalize" }}
                          disabled={!(formikProps.isValid && formikProps.dirty) || isLoading}
                          startIcon={
                            !isLoading ? (
                              ""
                            ) : (
                              <CircularProgress
                                color="inherit"
                                size="15px"
                              />
                            )
                          }
                        >
                          Sign In
                        </Button>
                        <LinkRouter to="/forgot-password">
                          <Typography
                            component="span"
                            color="blue"
                            fontSize="14px"
                          >
                            Forgot password?
                          </Typography>
                        </LinkRouter>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
              <Divider
                sx={{
                  mt: 2,
                  mb: 2,
                  textTransform: "uppercase",
                  ":after": {
                    borderColor: "#a6a6a6",
                  },
                  ":before": {
                    borderColor: "#a6a6a6",
                  },
                  fontSize: "14px",
                }}
                variant="fullWidth"
                flexItem
              >
                or
              </Divider>
              <CustomGoogleLogin
                Login={Login}
                invitationId={invitation_id}
              />
              <div
                style={{
                  width: "100%",
                  height: "20px",
                }}
              ></div>
              <CustomLinkedinLogin
                Login={Login}
                invitationId={invitation_id}
              />
            </Box>
            <Box mt={3}>
              <Typography fontSize="14px">
                New to NeuralPit?&nbsp;
                <LinkRouter to="/register">
                  <Typography
                    component="span"
                    color="blue"
                    sx={{
                      textDecoration: "underline",
                      fontSize: "14px",
                    }}
                  >
                    Sign up now
                  </Typography>
                </LinkRouter>
              </Typography>
            </Box>
            <Box
              component="div"
              mt={3}
            >
              <Footer />
            </Box>
          </Box>

          <Box
            component="div"
            sx={{
              flex: 1,
              display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              paddingX: 2,
              backgroundColor: "#a3cdf5",
              gap: 2,
              paddingY: 2,
              minHeight: "100vh",
            }}
          >
            <Box component="div">
              <Typography
                variant="h1"
                sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                }}
              >
                Accelerate innovation and productivity with NeuralPit AI platform.
              </Typography>
              <Box
                component="ul"
                sx={{
                  paddingLeft: 2,
                }}
              >
                {LOGIN_DATA.map((i) => (
                  <Box
                    key={i.id}
                    component="li"
                    sx={{
                      marginTop: "8px",
                      fontSize: "14px",
                      fontWeight: "400",
                      listStyleType: "disc",
                      lineHeight: 1.5,
                    }}
                  >
                    {i.text}
                  </Box>
                ))}
              </Box>
              <Box
                component="div"
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "8px",
                  mt: 3,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <ImprovementIcon />
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Insight Assistants
                  </Typography>
                </Box>
                <Box
                  component="div"
                  sx={{
                    marginTop: "8px",
                    paddingLeft: "44px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    Extract insights from documents and data, generate and visualize ideas.
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      marginTop: "6px",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    For Consulting and Business Improvement.
                  </Typography>
                </Box>
              </Box>
              <Box
                component="div"
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "8px",
                  mt: 2,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <HRIcon />
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    AI Assistants for HR
                  </Typography>
                </Box>
                <Box
                  component="div"
                  sx={{
                    marginTop: "8px",
                    paddingLeft: "44px",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    Draft and share job postings on social platforms, review resumes to identify the right talent, and
                    conduct HR data analytics.
                  </Typography>
                </Box>
              </Box>
              <Box
                component="div"
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "8px",
                  mt: 3,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <MarketingIcon />
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    AI Assistants for Marketing
                  </Typography>
                </Box>
                <Box
                  component="div"
                  sx={{
                    marginTop: "8px",
                    paddingLeft: "44px",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    Extract market and competitor insights from websites and YouTube videos; craft and share marketing
                    materials across social platforms.
                  </Typography>
                </Box>
              </Box>
              <Box
                component="div"
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "8px",
                  mt: 3,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <ProcurementIcon />
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    AI Assistants for Procurement and Supply Chain
                  </Typography>
                </Box>
                <Box
                  component="div"
                  sx={{
                    marginTop: "8px",
                    paddingLeft: "44px",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    Distill critical insights from supplier proposals and commercial contracts, and conduct analysis of
                    procurement and supply chain data.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default LoginPage;
