import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { Modal } from "src/components/common";
import { addErrorResponse } from "src/store/features/error_response";
import { RootState } from "src/store/store";

const WarningSubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const error = useSelector((state: RootState) => state.errorResponse.error);

  const bodyElement = (
    <Box
      sx={{
        minWidth: "400px",
        padding: "20px",
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
        }}
      >
        {error?.content}
      </Typography>
    </Box>
  );

  // state
  const [open, setOpen] = useState(false);

  // function
  const handleClose = (value: boolean) => {
    setOpen(value);
    dispatch(addErrorResponse(null));
  };

  const handleClick = () => {
    navigate(`/upgrade-subscription?url_callback=${location.pathname}&teamId=${error?.teamId}`);
    dispatch(addErrorResponse(null));
    setOpen(false);
  };

  // useEffect
  useEffect(() => {
    if (error) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error]);

  return (
    <Modal
      open={open}
      setOpen={handleClose}
      body={bodyElement}
      onClick={handleClick}
      labelConfirm="Upgrade"
    />
  );
};

export default WarningSubscription;
