import React, { ReactNode } from "react";

// import Footer from "src/components/Footer";

interface BlankLayoutProps {
  children?: ReactNode;
}

const BlankLayout = ({ children }: BlankLayoutProps) => {
  return (
    // <Box
    //   sx={{
    //     flex: 1,
    //     height: "100vh",
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "space-between",
    //     flexGrow: 1,
    //   }}
    // >
    //   {children}
    //   <Box
    //     component="div"
    //     sx={{
    //       flexShrink: 1,
    //     }}
    //   >
    //     <Footer />
    //   </Box>
    // </Box>
    <React.Fragment>{children}</React.Fragment>
  );
};

export default BlankLayout;
