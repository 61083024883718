import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

interface ModalNoActionsProps {
  children: React.ReactNode;
  open: boolean;
  setOpen: Function;
  reset?: Function;
  cssString?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "5px",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  paddingTop: "10px",
  paddingBottom: "10px",
};

const ModalNoActions = ({ children, open, setOpen, reset, cssString = {} }: ModalNoActionsProps) => {
  const handleClose = () => {
    setOpen(false);
    if (reset) {
      reset();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={cssString}
    >
      <Box sx={style}>
        <Box
          component="div"
          sx={{
            position: "relative",
          }}
        >
          <Button
            sx={{
              padding: "4px",
              minWidth: "auto",
              borderRadius: "50%",
              ":hover": {
                backgroundColor: "#e0e0e0",
              },
              position: "absolute",
              top: "-5px",
              right: "-15px",
            }}
            onClick={handleClose}
          >
            <CloseIcon
              sx={{
                color: "black",
                width: "20px",
                height: "20px",
              }}
            />
          </Button>
          <Box>{children}</Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalNoActions;
