import React, { useState } from "react";
import toast from "react-hot-toast";
import { Tooltip, Button, Box, Typography } from "@mui/material";

import { Modal } from "src/components/common";
import { DeleteIcon } from "src/assest/icons";
import { INewSession } from "src/libs/models/assistant";
import { deleteSessionById } from "src/libs/axios/api/assistant";

interface DeleteIconAssistantProps {
  sessionId: number;
  projectId: number;
  teamId: number;
  currentSession: INewSession | null;
  setCurrentSession: React.Dispatch<React.SetStateAction<INewSession | null>>;
  callback: Function;
}

const DeleteIconAssistant: React.FC<DeleteIconAssistantProps> = ({
  sessionId,
  projectId,
  teamId,
  currentSession,
  setCurrentSession,
  callback,
}) => {
  // state
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // element
  const bodyDeleteElement = (
    <Box
      sx={{
        minWidth: "400px",
        padding: "40px",
      }}
    >
      <Typography fontSize="14px">Do you want to delete this session?</Typography>
    </Box>
  );

  // functions
  const handleConfirmDeleteSession = async () => {
    setIsLoading(true);
    const res = await deleteSessionById(teamId, projectId, sessionId);
    setIsLoading(false);
    if (res) {
      setOpen(false);
      callback();
      toast.success("Delete session successfully!");

      if (sessionId === currentSession?.id) {
        setCurrentSession(null);
      }
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Delete Session">
        <Button
          sx={{
            minWidth: "auto",
            padding: "0",
            color: "black",
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <DeleteIcon
            width="20"
            height="20"
          />
        </Button>
      </Tooltip>

      <Modal
        open={open}
        setOpen={setOpen}
        body={bodyDeleteElement}
        onClick={handleConfirmDeleteSession}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default DeleteIconAssistant;
