import { Suspense, lazy } from "react";

import BlankLayout from "src/layouts/BlankLayout";
import LoggedLayout from "src/layouts/LoggedLayout";

import LoginPage from "src/pages/Auth/Login";
import RegisterPage from "src/pages/Auth/Register";
import PasswordResetPage from "src/pages/Auth/PasswordReset";
import ConfirmEmailPage from "src/pages/Auth/ConfirmEmail";
import HomePage from "src/pages/Home";
import LinkedinCallback from "src/components/Auth/LinkedinCallback";
import PayPalCallback from "src/components/Subscription/PayPalCallback";
import TeamPage from "src/pages/Team";
import AddTeamPage from "src/pages/Team/AddTeam";
import ProfilePage from "src/pages/Profile";
import CompanyPage from "src/pages/Company";
import PasswordChangePage from "src/pages/Auth/ChangePassword";
import InvitationPage from "src/pages/Invitation";
import UpgradeSubscriptionPage from "src/pages/UpgradeSubscription";

import SuspenseLoader from "src/components/common/SuspenseLoader";
import GoogleDriveCallback from "src/pages/GoogleDriveCallback";
import HRAssistantLayout from "src/layouts/HRAssistantLayout";
import MarketingAssistantLayout from "src/layouts/MarketingAssistantLayout";
import SupplyChainAssistantLayout from "src/layouts/SupplyChainAssistantLayout";
import FinancialAssistantLayout from "src/layouts/FinancialAssistantLayout";
import BusinessAnalysisAssistantLayout from "src/layouts/BusinessAnalysisAssistantLayout";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const DashboardPage = Loader(lazy(() => import("src/pages/Dashboard")));
const ProjectPage = Loader(lazy(() => import("src/pages/Project")));
const SubscriptionPage = Loader(lazy(() => import("src/pages/Subscription")));
const ViewPlanPage = Loader(lazy(() => import("src/pages/Subscription/ViewPlan")));
const SubscriptionPlanPage = Loader(lazy(() => import("src/pages/Subscription/SubscriptionPlan")));
const PaymentPage = Loader(lazy(() => import("src/pages/Payment")));
const PaymentHistoryPage = Loader(lazy(() => import("src/pages/Payment/PaymentHistory")));
const PaymentMethodPage = Loader(lazy(() => import("src/pages/Payment/PaymentMethod")));
const IntegrationPage = Loader(lazy(() => import("src/pages/Integration")));
const ChatDocumentScreenPage = Loader(lazy(() => import("src/pages/ChatScreen/ChatDocumentScreen")));
const ChatWebScreenPage = Loader(lazy(() => import("src/pages/ChatScreen/ChatWebScreen")));
const ChatVisualizationScreenPage = Loader(lazy(() => import("src/pages/ChatScreen/ChatVisualizationScreen")));
const ChatCreateFlowScreenPage = Loader(lazy(() => import("src/pages/ChatScreen/ChatCreateFlowScreen")));
const ChatGenIdealScreenPage = Loader(lazy(() => import("src/pages/ChatScreen/ChatGenIdealScreen")));
const ChatHiringScreenPage = Loader(lazy(() => import("src/pages/HRAssistantScreen/ChatHiringScreen")));
const TalentSelectionScreenPage = Loader(lazy(() => import("src/pages/HRAssistantScreen/TalentSelectionScreen")));
const PerformanceAnalyticsScreenPage = Loader(
  lazy(() => import("src/pages/HRAssistantScreen/PerformanceAnalyticsScreen")),
);
const InteractiveLearningScreenPage = Loader(
  lazy(() => import("src/pages/HRAssistantScreen/InteractiveLearningScreen")),
);
const MarketIntelligenceScreenPage = Loader(
  lazy(() => import("src/pages/MarketingAssistantScreen/MarketIntelligenceScreen")),
);
const MarketAnalyticScreenPage = Loader(lazy(() => import("src/pages/MarketingAssistantScreen/MarketAnalyticScreen")));
const ContentCreationScreenPage = Loader(
  lazy(() => import("src/pages/MarketingAssistantScreen/ContentCreationScreen")),
);
const VendorAssessmentScreenPage = Loader(lazy(() => import("src/pages/SupplyChainAssistant/VendorAssessmentScreen")));
const ContractAnalysisScreenPage = Loader(lazy(() => import("src/pages/SupplyChainAssistant/ContractAnalysisScreen")));
const DataAnalyticsScreenPage = Loader(lazy(() => import("src/pages/SupplyChainAssistant/DataAnalyticsScreen")));
const StrategyAssistantScreenPage = Loader(
  lazy(() => import("src/pages/SupplyChainAssistant/StrategyAssistantScreen")),
);
const FinancialAnalyticsScreenPage = Loader(
  lazy(() => import("src/pages/FinanceAssistantScreen/FinancialAnalyticsScreen")),
);
const AnnualReportsScreenPage = Loader(lazy(() => import("src/pages/FinanceAssistantScreen/AnnualReportsScreen")));
const MiddleWorkstationPage = Loader(lazy(() => import("src/pages/MiddleWorkstation")));
const SchedulePostPage = Loader(lazy(() => import("src/pages/SchedulePost")));
const SchedulePostDetailPage = Loader(lazy(() => import("src/pages/SchedulePost/SchedulePostDetail")));

export const publicRoutes = [
  {
    id: 1,
    path: "/",
    page: HomePage,
    layout: BlankLayout,
  },
  {
    id: 2,
    path: "/login",
    page: LoginPage,
    layout: BlankLayout,
  },
  {
    id: 3,
    path: "/register",
    page: RegisterPage,
    layout: BlankLayout,
  },
  {
    id: 4,
    path: "/forgot-password",
    page: PasswordResetPage,
    layout: BlankLayout,
  },
  {
    id: 5,
    path: "/confirm-email",
    page: ConfirmEmailPage,
    layout: BlankLayout,
  },
  {
    id: 6,
    path: "/linkedin-callback",
    page: LinkedinCallback,
    layout: BlankLayout,
  },
  {
    id: 7,
    path: "/teams/invitation/:code",
    page: InvitationPage,
    layout: BlankLayout,
  },
];

export const privateRoutes = [
  {
    id: "1",
    path: "/dashboard",
    page: DashboardPage,
    layout: LoggedLayout,
  },
  {
    id: "2",
    path: "/project",
    page: ProjectPage,
    layout: LoggedLayout,
  },
  {
    id: "3",
    path: "/team",
    page: TeamPage,
    layout: LoggedLayout,
  },
  {
    id: "4",
    path: "/team/new/:teamId",
    page: AddTeamPage,
    layout: LoggedLayout,
  },
  {
    id: "5",
    path: "/profile",
    page: ProfilePage,
    layout: LoggedLayout,
  },
  {
    id: "6",
    path: "/company",
    page: CompanyPage,
    layout: LoggedLayout,
  },
  {
    id: "8",
    path: "/subscription",
    page: SubscriptionPage,
    layout: LoggedLayout,
  },
  {
    id: "9",
    path: "/subscription/:teamId",
    page: ViewPlanPage,
    layout: LoggedLayout,
  },
  {
    id: "10",
    path: "/subscription/:teamId/plans",
    page: SubscriptionPlanPage,
    layout: LoggedLayout,
  },
  {
    id: "11",
    path: "/payment",
    page: PaymentPage,
    layout: LoggedLayout,
  },
  {
    id: "12",
    path: "/payment/:teamId/method",
    page: PaymentMethodPage,
    layout: LoggedLayout,
  },
  {
    id: "13",
    path: "/payment/:teamId/history",
    page: PaymentHistoryPage,
    layout: LoggedLayout,
  },
  {
    id: "14",
    path: "/change-password",
    page: PasswordChangePage,
    layout: LoggedLayout,
  },
  {
    id: "15",
    path: "/paypal-callback",
    page: PayPalCallback,
    layout: LoggedLayout,
  },
  {
    id: "16",
    path: "/upgrade-subscription",
    page: UpgradeSubscriptionPage,
    layout: BlankLayout,
  },
  {
    id: "17",
    path: "/a/1/google-token",
    page: GoogleDriveCallback,
    layout: BlankLayout,
  },
  {
    id: "18",
    path: "/integration",
    page: IntegrationPage,
    layout: LoggedLayout,
  },
  {
    id: "19-document",
    path: "/workstation/chat-document/:id",
    page: ChatDocumentScreenPage,
    layout: BusinessAnalysisAssistantLayout,
  },
  {
    id: "20-web",
    path: "/workstation/chat-web/:id",
    page: ChatWebScreenPage,
    layout: BusinessAnalysisAssistantLayout,
  },
  {
    id: "21-visualization",
    path: "/workstation/chat-visualization/:id",
    page: ChatVisualizationScreenPage,
    layout: BusinessAnalysisAssistantLayout,
  },
  {
    id: "22-flow",
    path: "/workstation/chat-flow/:id",
    page: ChatCreateFlowScreenPage,
    layout: BusinessAnalysisAssistantLayout,
  },
  {
    id: "23-ideal",
    path: "/workstation/chat-ideal/:id",
    page: ChatGenIdealScreenPage,
    layout: BusinessAnalysisAssistantLayout,
  },
  {
    id: "24",
    path: "/middle-workstation",
    page: MiddleWorkstationPage,
    layout: LoggedLayout,
  },
  {
    id: "25",
    path: "/hr-assistant/chat-hiring/:id",
    page: ChatHiringScreenPage,
    layout: HRAssistantLayout,
  },
  {
    id: "26",
    path: "/hr-assistant/talent-selection/:id",
    page: TalentSelectionScreenPage,
    layout: HRAssistantLayout,
  },
  {
    id: "27",
    path: "/hr-assistant/performance-analytics/:id",
    page: PerformanceAnalyticsScreenPage,
    layout: HRAssistantLayout,
  },
  {
    id: "28",
    path: "/hr-assistant/interactive-learning/:id",
    page: InteractiveLearningScreenPage,
    layout: HRAssistantLayout,
  },
  {
    id: "29",
    path: "/marketing-assistant/market-intelligence/:id",
    page: MarketIntelligenceScreenPage,
    layout: MarketingAssistantLayout,
  },
  {
    id: "30",
    path: "/marketing-assistant/marketing-analytics/:id",
    page: MarketAnalyticScreenPage,
    layout: MarketingAssistantLayout,
  },
  {
    id: "31",
    path: "/marketing-assistant/content-creation/:id",
    page: ContentCreationScreenPage,
    layout: MarketingAssistantLayout,
  },
  {
    id: "32",
    path: "/supply-chain-assistant/vendor-assessment/:id",
    page: VendorAssessmentScreenPage,
    layout: SupplyChainAssistantLayout,
  },
  {
    id: "33",
    path: "/supply-chain-assistant/contract-analysis/:id",
    page: ContractAnalysisScreenPage,
    layout: SupplyChainAssistantLayout,
  },
  {
    id: "34",
    path: "/supply-chain-assistant/data-analytics/:id",
    page: DataAnalyticsScreenPage,
    layout: SupplyChainAssistantLayout,
  },
  {
    id: "35",
    path: "/supply-chain-assistant/strategy-assistant/:id",
    page: StrategyAssistantScreenPage,
    layout: SupplyChainAssistantLayout,
  },
  {
    id: "36",
    path: "/financial-assistant/financial-analytics/:id",
    page: FinancialAnalyticsScreenPage,
    layout: FinancialAssistantLayout,
  },
  {
    id: "37",
    path: "/financial-assistant/annual-reports/:id",
    page: AnnualReportsScreenPage,
    layout: FinancialAssistantLayout,
  },
  {
    id: "38",
    path: "/schedule-post",
    page: SchedulePostPage,
    layout: LoggedLayout,
  },
  {
    id: "39",
    path: "/schedule-post/:id",
    page: SchedulePostDetailPage,
    layout: LoggedLayout,
  },
];
