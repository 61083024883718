import { Box, Typography } from "@mui/material";

const VendorAssessment = () => {
  return (
    <Box
      component="div"
      sx={{
        height: "100%",
      }}
    >
      <Box
        component="div"
        sx={{}}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: 1,
          }}
        >
          Vendor Proposals Assistant
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Use this tool to upload and evaluate vendor proposals (including PDF's, documents, videos, and audio files)
          based on specified criteria.
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Upload one or multiple vendor proposals (in pdf, docs, docx, videos, audios), and ask questions.
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "100",
            mt: 1,
          }}
        >
          Sample questions for reviewing vendor proposals:
        </Typography>

        <Box
          component="ul"
          mb={2}
          mt={1}
          padding={0}
          paddingLeft={2}
        >
          <Box
            component="li"
            sx={{
              fontSize: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Cost Breakdown: "Give me a detailed breakdown of all costs involved offered by vendor Y? This includes any
              additional fees that might be incurred during implementation or post-implementation”
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Risk and Resilience: "Does Vendor X's proposal include any strategies for supply chain risk management and
              resilience?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Price volatility: "What are the policies of Vendor Z regarding price volatility?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Meeting requirements: "What details has Vendor Y provided to justify that their proposal meets the
              technical requirement [insert specific requirement here]?"
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Data Security and Compliance: "What measures does vendor Z take to ensure data security and privacy?”
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Termination Clauses: "What are the terms for vendor Z contract termination? Are there any penalties or
              costs involved?”
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              mb: 0.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
              }}
            >
              Innovation and Future Roadmap: "Give me insights on vendor Y’s investment in innovation and their product
              roadmap for the next few years?"
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VendorAssessment;
