import { useState, forwardRef } from "react";
import { Box, Button, Container } from "@mui/material";
import { FastField, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import toast from "react-hot-toast";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import styled from "styled-components";

import { Input } from "src/components/common";
import { confirmForgotPassword, forgotPassword } from "src/libs/axios/api/auth";

const SubText = styled(Typography)`
  font-size: 0.875em;
  color: #6c757d;
`;

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const PasswordResetPage = () => {
  const navigate = useNavigate();

  // state
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    resetEmail: "",
    code: "",
    newPassword: "",
  });

  const sendForgotPassword = async (email: string) => {
    const res = await forgotPassword(email);
    if (res) {
      setData((prev) => ({
        ...prev,
        resetEmail: email,
      }));
      setOpen(true);
    }
  };

  const handleSubmitCode = async () => {
    const res = await confirmForgotPassword(data.resetEmail, data.code, data.newPassword);
    if (res) {
      toast.success(res);
      navigate("/login");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        marginBottom: "200px",
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Typography
          variant="h4"
          mb={1}
        >
          Password Reset
        </Typography>
        <Typography
          variant="subtitle1"
          mb={3}
        >
          Enter your e-mail address below, and we'll send you a code to reset your password
        </Typography>
        <Box component="div">
          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values) => {
              sendForgotPassword(values?.email);
            }}
          >
            {(formikProps) => {
              return (
                <Form>
                  <FastField
                    name="email"
                    component={Input}
                    type="text"
                    placeHolder="Email Address"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 1, textTransform: "capitalize" }}
                    disabled={!formikProps?.values?.email}
                  >
                    Send
                  </Button>
                </Form>
              );
            }}
          </Formik>
          <SubText>Please contact us if you have any trouble resetting your password.</SubText>
        </Box>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirm code sent to your email"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            label="Input code"
            name="code"
            autoFocus
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                code: e.target.value,
              }));
            }}
            sx={{
              width: "500px",
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="newPassword"
            label="Input New Password"
            name="newPassword"
            type="password"
            autoFocus
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                newPassword: e.target.value,
              }));
            }}
            sx={{
              width: "500px",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmitCode}
            variant="contained"
            disabled={!data.code || !data.newPassword}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpen((prev) => !prev)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PasswordResetPage;
