import { Button, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import { SubscriptionIcon } from "src/assest/icons";
import { UpgradePlansModal } from "src/components/Header";
import PaymentModal from "src/components/Payment/PaymentModal";
import SubscriptionModal from "src/components/Subscription/SubscriptionModal";
import { BodyText } from "src/libs/AppStyle";
import { getPaymentByTeam } from "src/libs/axios/api/payment";
import { checkPermissionTeam } from "src/store/features/permission";

interface SubscriptionItemProps {
  pathName: string;
}

const SubscriptionItem: React.FC<SubscriptionItemProps> = ({ pathName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [teamId, setTeamId] = useState<null | number>(null);
  const [payment, setPayment] = useState(null);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [planId, setPlanId] = useState(null);
  const [upgradePlansModal, setUpgradePlansModal] = useState(false);

  // function
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, url: string) => {
    if (!pathName?.includes("/workstation")) {
      navigate(url);
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const getTeamPayment = async () => {
    if (!teamId) return;
    const res: any = await getPaymentByTeam(teamId.toString());
    setPayment(res);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddPayMethod = async () => {
    if (!teamId) return;
    const res: any = await getPaymentByTeam(teamId.toString());
    if (res) {
      setPayment(res);
      setOpenSubscribeModal(true);
    }
  };

  const handleUpgrade = () => {
    setUpgradePlansModal(true);
    const currentProject = localStorage.getItem("current_project");
    if (currentProject) {
      const project = JSON.parse(currentProject);
      setTeamId(project?.teamId);
    }
  };

  const handleSetPlan = (planId) => {
    setPlanId(planId);
  };

  const handleCallback = () => {
    toast.success("Upgrade successful!", {
      position: "bottom-right",
    });
    dispatch(checkPermissionTeam(true));
    navigate(location.pathname);
    setOpenSubscribeModal(false);
    setUpgradePlansModal(false);
  };

  useEffect(() => {
    if (teamId) {
      getTeamPayment();
    }
  }, [teamId]);

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="View and manage team subscriptions">
        <Button
          id="my-subscription"
          aria-controls={open ? "subscription-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => handleClick(e, "/subscription")}
          sx={{
            padding: 0,
            paddingX: "5px",
            color: `${pathName === "/subscription" ? "#1672c9" : "black"}`,
            "&:hover": {
              backgroundColor: "transparent",
              color: "#1672c9",
              "& svg": {
                color: "#1672c9",
              },
            },
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
          >
            <SubscriptionIcon className={pathName === "/subscription" ? "activate-svg" : "default-svg"} />
            <BodyText
              variant="caption"
              sx={{
                fontSize: "12px !important",
                textTransform: "capitalize",
                color: "inherit !important",
              }}
            >
              Subscription
            </BodyText>
          </Stack>
        </Button>
      </Tooltip>
      <Menu
        id="team-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Button
            sx={{
              textTransform: "none",
              color: "black",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={handleUpgrade}
          >
            Upgrade
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            sx={{
              textTransform: "none",
              color: "black",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              navigate("/subscription");
            }}
          >
            View subscription
          </Button>
        </MenuItem>
      </Menu>
      <PaymentModal
        onClick={() => {}}
        teamId={teamId ? teamId.toString() : ""}
        open={openPaymentModal}
        setOpen={setOpenPaymentModal}
        callback={handleAddPayMethod}
      />

      <UpgradePlansModal
        open={upgradePlansModal}
        setOpen={setUpgradePlansModal}
        teamId={teamId ? teamId.toString() : ""}
        setTeamId={setTeamId}
        setPlanId={handleSetPlan}
        setOpenPaymentModal={setOpenPaymentModal}
        setOpenSubscribeModal={setOpenSubscribeModal}
      />

      <SubscriptionModal
        planId={planId}
        teamId={teamId ? teamId.toString() : ""}
        payment_channel={payment ? payment.payment_channel : ""}
        payment_data={payment ? payment.payment_data : ""}
        open={openSubscribeModal}
        setOpen={setOpenSubscribeModal}
        callback={handleCallback}
      />
    </>
  );
};

export default SubscriptionItem;
