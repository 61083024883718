import { useState } from "react";
import { Button, Stack, Tooltip } from "@mui/material";

import { FolderIcon } from "src/assest/icons";
import { SelectModel } from "src/libs/models";
import { BodyText } from "src/libs/AppStyle";

import UploadFileModal from "src/components/Workstation/UploadFileModal";

const FolderItem = () => {
  // state
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [currentProject, setCurrentProject] = useState<SelectModel | null>(null);

  // function
  const handleClick = () => {
    const currentProjectJson = localStorage.getItem("current_project");
    if (currentProjectJson) {
      const current = JSON.parse(currentProjectJson);
      setCurrentProject((prev) => ({
        ...(prev ? prev : {}),
        ...current,
      }));
      setUploadFileModal(true);
    }
  };

  return (
    <>
      <Tooltip title="Upload and manage project files">
        <Button
          sx={{
            padding: 0,
            paddingX: "5px",
            color: "#737373",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#1672c9",
              "& svg": {
                color: "#1672c9",
              },
            },
          }}
          onClick={handleClick}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
          >
            <FolderIcon />
            <BodyText
              variant="caption"
              sx={{
                fontSize: "12px !important",
                textTransform: "capitalize",
                color: "black",
                "&:hover": {
                  color: "#1672c9",
                },
              }}
            >
              Folder
            </BodyText>
          </Stack>
        </Button>
      </Tooltip>
      {currentProject?.id && (
        <UploadFileModal
          open={uploadFileModal}
          setOpen={setUploadFileModal}
          currentProject={currentProject}
        />
      )}
    </>
  );
};

export default FolderItem;
