import toast from "react-hot-toast";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

import { deleteFileOrFolder } from "src/libs/axios/api/uploadFile";
import { IStruct } from "src/libs/models";
import { convertByte, renderPathFile, renderPathFileToDelete } from "src/libs/hooks";
import { IconWrapper } from "src/components/common";

interface FileCardProps {
  files: IStruct[];
  teamId: number;
  projectId: number;
  rootPath: string;
  callback: Function;
}

const FileCard = ({ files, teamId, projectId, callback, rootPath }: FileCardProps) => {
  const handleDeleteFile = async (filePath: string) => {
    const pathDelete = renderPathFileToDelete(filePath, rootPath);

    const res = await deleteFileOrFolder(teamId, projectId, pathDelete);
    if (res) {
      toast.success(`${res?.message || "Delete File Successful"}`);
      callback();
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "none",
        height: "100%",
      }}
    >
      <TableContainer sx={{ maxHeight: "70vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  textTransform: "capitalize",
                  fontSize: "14px",
                }}
              >
                name
              </TableCell>
              <TableCell
                sx={{
                  textTransform: "capitalize",
                  fontSize: "14px",
                }}
              >
                type
              </TableCell>
              <TableCell
                sx={{
                  textTransform: "capitalize",
                  fontSize: "14px",
                }}
              >
                size
              </TableCell>
              <TableCell
                sx={{
                  textTransform: "capitalize",
                  fontSize: "14px",
                }}
              >
                action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file, index) => (
              <TableRow key={`list-file-${index}`}>
                <TableCell
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {renderPathFile(file.path).name}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {renderPathFile(file.path).type}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {convertByte(file.size).toFixed(2)}MB
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  <Tooltip title="Delete file">
                    <Button
                      sx={{
                        padding: 0,
                      }}
                      onClick={() => handleDeleteFile(file.path)}
                    >
                      <IconWrapper>
                        <DeleteIcon
                          fontSize="small"
                          color="error"
                        />
                      </IconWrapper>
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default FileCard;
