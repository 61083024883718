import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CustomInputFile, Modal, ModalNoAction, TreeViewElement } from "src/components/common";
import { createFolderFile, getAllFolder, uploadFileByFolder } from "src/libs/axios/api/uploadFile";
import { IStruct, SelectModel } from "src/libs/models";
import { RootState } from "src/store/store";
import FileCard from "./FileCard";

interface UploadFileModalProps {
  open: boolean;
  currentProject: SelectModel;
  setOpen: Function;
}

const UploadFileModal = ({ open, setOpen, currentProject }: UploadFileModalProps) => {
  const isDisableFunc = useSelector((state: RootState) => state.permission.is_permission);

  // state
  const [createFolderModal, setCreateFolderModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [folders, setFolders] = useState<IStruct>({
    path: "",
    type: "",
  });
  const [currentFiles, setCurrentFiles] = useState<IStruct[]>();
  const [currentPath, setCurrentPath] = useState("");
  const [rootPath, setRootPath] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const bodyCreateFolder = (
    <Box
      p={3}
      minWidth="300px"
    >
      <Typography
        mb={3}
        variant="h5"
        sx={{
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        New Folder
      </Typography>
      <TextField
        id="name_folder"
        value={newFolderName}
        label="Enter folder name"
        type="text"
        fullWidth
        margin="normal"
        onChange={(e) => setNewFolderName(e.target.value)}
        sx={{
          marginBottom: "0",
          marginTop: "0",
          "& .MuiInputBase-input": {
            padding: "12px 14px",
            fontSize: "14px",
          },
          "& label": {
            fontSize: "14px",
            top: "-4px",
          },
          "& label.Mui-focused": {
            lineHeight: "2.4375em",
            left: "5px",
          },
          "& label.MuiInputLabel-shrink": {
            lineHeight: "2.4375em",
          },
          "& .MuiInputBase-root": {
            "& textarea": {
              padding: 0,
            },
          },
          fontSize: "14px",
        }}
        name="name_folder"
        aria-invalid
        autoFocus
        required
      />
    </Box>
  );

  // function
  const uploadFile = async (files: File[]) => {
    setIsLoading(true);
    const res = await uploadFileByFolder(currentProject?.projectId, currentProject?.teamId, files, currentPath);
    setIsLoading(false);
    if (res) {
      getListFolder();
    }
  };

  const getListFolder = async () => {
    setIsLoading(true);
    const res = await getAllFolder(currentProject?.teamId, currentProject?.projectId);
    setIsLoading(false);
    if (res?.children?.length > 0) {
      setFolders((prev) => ({
        ...prev,
        ...res,
      }));
      setRootPath(res.path);
    } else {
      setFolders((prev) => ({
        ...prev,
        path: "",
        type: "",
        children: [],
      }));
    }
  };

  const handleCreateNewFolder = () => {
    createNewFolder(newFolderName);
  };

  const createNewFolder = async (folderName: string) => {
    const res = await createFolderFile(
      currentProject?.projectId,
      currentProject?.teamId,
      `${currentPath}/${folderName}`,
    );
    if (res) {
      setCreateFolderModal(false);
      getListFolder();
    }
  };

  // effect
  useEffect(() => {
    if (open && currentProject?.teamId) {
      getListFolder();
    }
  }, [open, currentProject]);

  return (
    <>
      <ModalNoAction
        open={open}
        setOpen={setOpen}
      >
        <Box
          sx={{
            width: "85vw",
            height: "85vh",
            marginRight: "20px",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={4}
            >
              <Stack
                flexDirection="column"
                spacing={2}
              >
                <Typography
                  component="h3"
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  <strong>Team:</strong> {currentProject?.teamName}
                </Typography>
                <Typography
                  component="h3"
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  <strong>Project:</strong> {currentProject?.projectName}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={8}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  component="div"
                  sx={{
                    width: "115px",
                    position: "relative",
                  }}
                >
                  <CustomInputFile handleUploadFile={uploadFile} />
                  {!isDisableFunc && (
                    <Box
                      component="div"
                      sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#f2f2f2",
                        opacity: 0.5,
                        top: 0,
                      }}
                    ></Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            mt={2}
            height="90%"
          >
            <Grid
              item
              xs={4}
              pr={2}
              borderRight="1px solid #a3cdf5"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                component="div"
                sx={{
                  width: "100%",
                }}
              >
                {folders.path && (
                  <TreeViewElement
                    teamId={currentProject?.teamId}
                    projectId={currentProject?.projectId}
                    data={folders}
                    setFiles={setCurrentFiles}
                    setPath={setCurrentPath}
                    callback={getListFolder}
                  />
                )}
              </Box>
              <Box
                component="div"
                sx={{
                  width: "100%",
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "14px",
                    width: "35%",
                  }}
                  onClick={() => setCreateFolderModal(true)}
                >
                  Create Folder
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              px="16px"
            >
              <Box
                component="div"
                sx={{
                  height: "100%",
                  position: "relative",
                }}
              >
                {currentFiles && currentFiles?.length > 0 && (
                  <FileCard
                    files={currentFiles}
                    teamId={currentProject.teamId}
                    projectId={currentProject.projectId}
                    rootPath={rootPath}
                    callback={getListFolder}
                  />
                )}

                {isLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#d9d9d9",
                      opacity: 0.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ModalNoAction>
      <Modal
        open={createFolderModal}
        setOpen={setCreateFolderModal}
        onClick={handleCreateNewFolder}
        body={bodyCreateFolder}
        isDisabled={!newFolderName}
        labelConfirm="Add"
      />
    </>
  );
};

export default UploadFileModal;
