import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, memo } from "react";

import toast from "react-hot-toast";

import { getTeamQuotasStatus } from "src/libs/axios/api/subscription";
import { SelectModel } from "src/libs/models";
import { UpgradePlansModal } from "src/components/Header";

import PaymentModal from "src/components/Payment/PaymentModal";
import SubscriptionModal from "src/components/Subscription/SubscriptionModal";
import { getPaymentByTeam } from "src/libs/axios/api/payment";

interface UpdateInSessionProps {
  currentProject: SelectModel | null;
}

const UpdateInSession: React.FC<UpdateInSessionProps> = ({ currentProject }) => {
  const navigate = useNavigate();

  // state
  const [teamId, setTeamId] = useState<number | null>(null);
  const [planId, setPlanId] = useState(null);
  const [payment, setPayment] = useState(null);
  const [teamQuotaStatus, setTeamQuotaStatus] = useState<any>(null);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [upgradePlansModal, setUpgradePlansModal] = useState(false);
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);

  // functions
  const fetchTeamQuotaStatus = async (team: number) => {
    const res = await getTeamQuotasStatus(team);
    if (res) {
      setTeamQuotaStatus(res);
    }
  };

  const handleAddPayMethod = async () => {
    if (!teamId) return;
    const res: any = await getPaymentByTeam(teamId.toString());
    if (res) {
      setPayment(res);
      setOpenSubscribeModal(true);
    }
  };

  const handleSetPlan = (planId) => {
    setPlanId(planId);
  };

  const handleCallback = () => {
    toast.success("Upgrade successful!");
    setOpenSubscribeModal(false);
    setUpgradePlansModal(false);

    navigate(0);
  };

  const handleUpgrade = () => {
    setUpgradePlansModal(true);
    const currentProject = localStorage.getItem("current_project");
    if (currentProject) {
      const project = JSON.parse(currentProject);
      setTeamId(project?.teamId);
    }
  };

  // useEffect
  useEffect(() => {
    let isMounted = true;
    if (!currentProject?.id) return;

    if (isMounted) {
      setTeamId(currentProject?.teamId);
      fetchTeamQuotaStatus(currentProject?.teamId);
    }

    return () => {
      isMounted = false;
    };
  }, [currentProject?.id]);

  return (
    <React.Fragment>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 400,
          fontSize: "14px",
          marginBottom: "5px",
        }}
      >
        Current plan: <strong>{teamQuotaStatus?.subscription_plan?.type || "Free Trial"}</strong>
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: "14px",
        }}
      >
        Remaining credit: {((teamQuotaStatus?.team_quota?.characters || 0) as number).toLocaleString()} chars
      </Typography>
      <Button
        sx={{
          backgroundColor:
            teamQuotaStatus?.team_quota?.characters > 40000
              ? "#1672c9"
              : teamQuotaStatus?.team_quota?.characters === 0
              ? "#ff0000"
              : "#ffc000",
          marginTop: 1.5,
          textTransform: "capitalize",
          fontSize: "14px",
          color: "#ffffff",
          width: "100%",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor:
              teamQuotaStatus?.team_quota?.characters > 40000
                ? "#1672c9"
                : teamQuotaStatus?.team_quota?.characters === 0
                ? "#ff0000"
                : "#ffc000",
          },
        }}
        onClick={handleUpgrade}
      >
        Upgrade
      </Button>

      <PaymentModal
        onClick={() => {}}
        teamId={teamId ? teamId.toString() : ""}
        open={openPaymentModal}
        setOpen={setOpenPaymentModal}
        callback={handleAddPayMethod}
      />

      <UpgradePlansModal
        open={upgradePlansModal}
        setOpen={setUpgradePlansModal}
        teamId={teamId ? teamId.toString() : ""}
        setTeamId={setTeamId}
        setPlanId={handleSetPlan}
        setOpenPaymentModal={setOpenPaymentModal}
        setOpenSubscribeModal={setOpenSubscribeModal}
      />

      <SubscriptionModal
        planId={planId}
        teamId={teamId ? teamId.toString() : ""}
        payment_channel={payment ? payment.payment_channel : ""}
        payment_data={payment ? payment.payment_data : ""}
        open={openSubscribeModal}
        setOpen={setOpenSubscribeModal}
        callback={handleCallback}
      />
    </React.Fragment>
  );
};

export default memo(UpdateInSession);
