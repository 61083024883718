import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("access_token");

  React.useEffect(() => {
    if (!token) {
      navigate(`/login${location?.search}`);
    }
  }, [token]);

  return <>{children}</>;
};

export default PrivateRoute;
