import * as React from "react";
import { loginLinkedIn } from "src/libs/axios/api/auth";

const CustomLinkedinLogin = ({ Login, invitationId }) => {
  const linkedInClientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;

  const handleLoginLinkedIn = () => {
    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const params = `width=${width},height=${height},left=${left},top=${top}`;

    let url = new URL("https://www.linkedin.com/oauth/v2/authorization");
    url.searchParams.append("response_type", "code");
    url.searchParams.append("client_id", linkedInClientId);
    url.searchParams.append("redirect_uri", `${window.location.origin}/linkedin-callback`);
    url.searchParams.append("scope", "r_liteprofile r_emailaddress");

    window.open(url, "LinkedIn Login", params);
  };

  React.useEffect(() => {
    const handleInvalidToken = async (e) => {
      if (e.key === "linkedin_login_code") {
        const code = e.newValue;
        if (code) {
          const res = await loginLinkedIn(code, `${window.location.origin}/linkedin-callback`, invitationId);
          localStorage.removeItem("linkedin_login_code");
          Login(res);
        }
      }
    };
    window.addEventListener("storage", handleInvalidToken);

    return () => {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  return (
    <button
      className="gsi-material-button"
      onClick={() => handleLoginLinkedIn()}
      style={{
        width: "325px",
      }}
      type="button"
    >
      <div className="gsi-material-button-state"></div>
      <div className="gsi-material-button-content-wrapper">
        <div className="gsi-material-button-icon">
          <svg
            width="24"
            height="24"
            viewBox="-3 -7 58 58"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Icons"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Color-"
                transform="translate(-702.000000, -265.000000)"
                fill="#007EBB"
              >
                <path
                  d="M746,305 L736.2754,305 L736.2754,290.9384 C736.2754,287.257796 734.754233,284.74515 731.409219,284.74515 C728.850659,284.74515 727.427799,286.440738 726.765522,288.074854 C726.517168,288.661395 726.555974,289.478453 726.555974,290.295511 L726.555974,305 L716.921919,305 C716.921919,305 717.046096,280.091247 716.921919,277.827047 L726.555974,277.827047 L726.555974,282.091631 C727.125118,280.226996 730.203669,277.565794 735.116416,277.565794 C741.21143,277.565794 746,281.474355 746,289.890824 L746,305 L746,305 Z M707.17921,274.428187 L707.117121,274.428187 C704.0127,274.428187 702,272.350964 702,269.717936 C702,267.033681 704.072201,265 707.238711,265 C710.402634,265 712.348071,267.028559 712.41016,269.710252 C712.41016,272.34328 710.402634,274.428187 707.17921,274.428187 L707.17921,274.428187 L707.17921,274.428187 Z M703.109831,277.827047 L711.685795,277.827047 L711.685795,305 L703.109831,305 L703.109831,277.827047 L703.109831,277.827047 Z"
                  id="LinkedIn"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <span className="gsi-material-button-contents">Continue with LinkedIn</span>
        <span
          style={{
            display: "none",
          }}
        >
          Continue with LinkedIn
        </span>
      </div>
    </button>
  );
};

export default CustomLinkedinLogin;
