import * as React from "react";
import { FastField, Form, Formik } from "formik";
import { Avatar, Button, Grid, IconButton, Typography } from "@mui/material";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import Stack from "@mui/material/Stack";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import CircularProgress from "@mui/material/CircularProgress";

import { Input } from "src/components/common";
import { IUser } from "src/libs/models";
import { getMyProfile, updateProfile } from "src/libs/axios/api/user";

import MyCompany from "src/components/Profile/MyCompany";
import ChangePassword from "src/components/Profile/ChangePassword";

const AccountTab = () => {
  const [user, setUser] = React.useState<IUser>({
    email: "",
    first_name: "",
    last_name: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const myInfor = async () => {
    const res = await getMyProfile();
    if (res) {
      setUser((prev) => ({
        ...prev,
        ...res,
      }));
    }
  };

  const update = async (payload: IUser) => {
    setIsLoading(true);
    const res = await updateProfile(payload);
    setIsLoading(false);
    if (res) {
      toast.success(res?.detail, {
        position: "bottom-right",
      });
    }
  };

  // useEffect
  React.useEffect(() => {
    myInfor();
  }, []);

  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        alignItems="left"
      >
        <Grid
          item
          xs={4}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <IconButton>
              <Avatar
                src="https://www.gravatar.com/avatar/628db51f33cf30041403bb0df79d9103?s=128&d=identicon"
                style={{
                  margin: "10px",
                  width: "128px",
                  height: "128px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </IconButton>
            <Button component="label">
              Change Picture
              <input
                type="file"
                hidden
              />
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              maxWidth: "400px",
            }}
          >
            <Typography variant="h5">Account Details</Typography>
            <Formik
              initialValues={user}
              onSubmit={(values) => {
                update(values);
              }}
              enableReinitialize
            >
              {() => {
                return (
                  <Form>
                    <Stack
                      gap={2}
                      mt={3}
                    >
                      <FastField
                        name="first_name"
                        component={Input}
                        type="text"
                        placeHolder="First & Last Name"
                        isFocus={true}
                      />
                      <Box
                        mb={3}
                        mt={2}
                      >
                        <FastField
                          name="email"
                          component={Input}
                          type="text"
                          placeHolder="Email *"
                          disabled={true}
                        />
                      </Box>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 1, textTransform: "capitalize" }}
                        disabled={isLoading}
                        startIcon={
                          !isLoading ? (
                            ""
                          ) : (
                            <CircularProgress
                              color="inherit"
                              size="15px"
                            />
                          )
                        }
                      >
                        Save
                      </Button>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ProfilePage = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: "80%", typography: "body1", margin: "0 auto" }}
      maxWidth="md"
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="Profile Tab"
          >
            <Tab
              label="Account"
              value="1"
              sx={{
                textTransform: "capitalize",
              }}
            />
            <Tab
              label="My Company"
              value="2"
              sx={{
                textTransform: "capitalize",
              }}
            />
            <Tab
              label="Change Password"
              value="3"
              sx={{
                textTransform: "capitalize",
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <AccountTab />
        </TabPanel>
        <TabPanel value="2">
          <MyCompany />
        </TabPanel>
        <TabPanel value="3">
          <ChangePassword />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ProfilePage;
