import { Box } from "@mui/material";
import { Rnd } from "react-rnd";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { INewSession } from "src/libs/models/assistant";
import { getMyProfile } from "src/libs/axios/api/user";
import { LeftArrowIcon } from "src/assest/icons";
import { setCurrentTeam } from "src/store/features/current-project";
import { checkPermission } from "src/libs/hooks";
import { getListProjectByTeam } from "src/libs/axios/api/project";
import { ISelectBox, SelectModel } from "src/libs/models";
import { getListTeamByPage, getTeamById } from "src/libs/axios/api/team";

import Header from "../SidebarLayout/Header";
import Footer from "src/components/Footer";
import LoadingCircle from "src/components/common/LoadingCircle";
import SampleQuestion from "src/components/Workstation/SampleQuestion";
import WarningSubscription from "src/components/Workstation/WarningSubscription";
import TopMarketingAssistant from "src/components/Workstation/New/MarketingAssistant/TopMarketingAssistant";
import ListSessionMarketingAssistant from "src/components/Workstation/New/MarketingAssistant/ListSessionMarketingAssistant";

interface MarketingAssistantLayoutProps {
  children: React.ReactNode;
}

const MarketingAssistantLayout: React.FC<MarketingAssistantLayoutProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const mobileScreen = useMediaQuery("(max-width:768px)");

  // state
  const [openSideBar, setOpenSideBar] = useState(true);
  const [isPermission, setIsPermission] = useState<boolean>(false);
  const [currentProject, setCurrentProject] = useState<SelectModel | null>(null);
  const [currentSession, setCurrentSession] = useState<INewSession | null>(null);
  const [listGroupProjectByTeam, setListGroupProjectByTeam] = useState<ISelectBox[]>([]);
  const [sizeSession, setSizeSession] = useState({
    width: "300px",
    height: "100%",
  });

  // functions
  const getUerInfo = async () => {
    const res = await getMyProfile();
    if (res) {
      const { first_name, email, id } = res;
      localStorage.setItem("userInfo", JSON.stringify({ name: first_name, email, id }));
    }
  };

  const getTeamID = async () => {
    const res = await getTeamById(currentProject?.teamId);
    if (res) {
      const condition = checkPermission(res);
      setIsPermission(condition);
    }
  };

  const getCurrentProjectByAPI = async (id: number, type: string) => {
    if (type === "new_team") {
      const resTeams = await getListTeamByPage();

      if (resTeams?.results?.length >= 0) {
        const listTeamPromise = resTeams.results.map((team) => getListProjectByTeam(1, team.id));

        Promise.all(listTeamPromise).then((results) => {
          const resProjects = results.map((r) => r?.results);
          const groupProjectsByTeam: ISelectBox[] = [];

          resTeams.results.forEach((team) => {
            const projects = resProjects.find((r) => r[0]?.team === team.id);
            const groupProject: ISelectBox = {
              teamId: team.id,
              teamName: team.name,
              projects: projects || [],
              members: team?.members || [],
            };
            groupProjectsByTeam.push(groupProject);
          });

          setListGroupProjectByTeam(groupProjectsByTeam);

          const currentTeam = groupProjectsByTeam.find((team) => team.teamId === id);
          if (currentTeam) {
            const currentProject = currentTeam.projects[0];
            if (currentProject) {
              const current = {
                id: `${currentTeam.teamId}-${currentProject.id}`,
                name: `${currentTeam.teamName}-${currentProject.name}`,
                teamId: currentTeam.teamId,
                projectId: currentProject.id,
                about: currentProject.objective,
                projectName: currentProject.name,
                teamName: currentTeam.teamName,
                members: currentTeam?.members,
              };
              setCurrentProject({ ...current });
              localStorage.setItem("current_project", JSON.stringify(current));
              // getListSession(currentTeam.teamId, currentProject.id);
            }
          }
        });
      }
    } else if (type === "new_project") {
      const resProjects = await getListProjectByTeam(1, currentProject?.teamId);

      if (resProjects?.results?.length >= 0) {
        const groupProject: ISelectBox = {
          teamId: currentProject?.teamId,
          teamName: currentProject?.teamName,
          projects: resProjects?.results || [],
          members: currentProject?.members || [],
        };

        setListGroupProjectByTeam((prev) => {
          return prev.map((team) => {
            if (team.teamId === currentProject?.teamId) {
              return { ...groupProject };
            }
            return team;
          });
        });

        const newProject = resProjects.results.find((p) => p.id === id);

        if (newProject) {
          const current = {
            id: `${currentProject?.teamId}-${newProject.id}`,
            name: `${currentProject?.teamName}-${newProject.name}`,
            teamId: currentProject?.teamId,
            projectId: newProject.id,
            about: newProject.objective,
            projectName: newProject.name,
            teamName: currentProject?.teamName,
            members: currentProject?.members,
          };
          setCurrentProject({ ...current });
          localStorage.setItem("current_project", JSON.stringify(current));
          // getListSession(currentProject?.teamId, newProject.id);
        }
      }
    }
  };

  const refreshGetListGroupProject = (id: number, type: "new_team" | "new_project") => {
    getCurrentProjectByAPI(id, type);
  };

  // Clone children and pass additionalProp to each child
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child as React.ReactElement, { currentProject, currentSession, isPermission }),
  );

  // useEffect
  useEffect(() => {
    getUerInfo();

    const currentProjectJson = localStorage.getItem("current_project");
    if (currentProjectJson) {
      setCurrentProject(JSON.parse(currentProjectJson));
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (currentProject && isMounted) {
      getTeamID();
      dispatch(setCurrentTeam({ teamId: currentProject?.teamId, projectId: currentProject?.projectId }));
    }

    return () => {
      isMounted = false;
    };
  }, [currentProject]);

  useEffect(() => {
    localStorage.setItem("previous_url", JSON.stringify(location.pathname));
    setOpenSideBar(true);
  }, [location]);

  return (
    <Box
      component="div"
      sx={{
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        component="div"
        sx={{
          height: "60px",
          borderBottom: "1px solid #bdc1c6",
        }}
      >
        <Header refreshGetListGroupProject={refreshGetListGroupProject} />
      </Box>
      <Box
        component="div"
        sx={{
          flexGrow: 1,
          position: "relative",
          zIndex: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TopMarketingAssistant
          defaultProjectWidth={sizeSession.width}
          currentProject={currentProject}
          currenSession={currentSession}
          setCurrentProject={setCurrentProject}
          listGroupProjectByTeam={listGroupProjectByTeam}
          setListGroupProjectByTeam={setListGroupProjectByTeam}
        />
        <Box
          component="div"
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
          className={`${mobileScreen ? "relative" : ""}`}
        >
          {!mobileScreen && (
            <Rnd
              className={`${openSideBar ? "transition-1000" : "hide-document-session transition-1000"}`}
              size={sizeSession}
              // eslint-disable-next-line no-unused-vars
              onResizeStop={(e, direction, ref, delta, position) => {
                setSizeSession((prev) => ({
                  ...prev,
                  width: ref.style.width,
                  height: "100%",
                }));
              }}
              dragAxis="x"
              disableDragging
              style={{
                position: "relative",
                transform: "translate(0, 0)",
                boxSizing: "border-box",
              }}
            >
              <ListSessionMarketingAssistant
                currentProject={currentProject}
                currentSession={currentSession}
                setCurrentSession={setCurrentSession}
              />
            </Rnd>
          )}

          {mobileScreen && (
            <Box
              component="div"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100vw",
                backgroundColor: "#d9d9d9",
                zIndex: openSideBar ? -1 : 99999,
                transform: `${openSideBar ? "translateX(-100%)" : "translateX(0)"}`,
              }}
              className="transition-all"
            >
              <Box
                component="div"
                sx={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#fff",
                  position: "relative",
                }}
              >
                <ListSessionMarketingAssistant
                  currentProject={currentProject}
                  currentSession={currentSession}
                  setCurrentSession={setCurrentSession}
                />

                <Box
                  component="div"
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 30,
                    zIndex: 50,
                    borderRadius: "100%",
                    color: "#737373",
                    backgroundColor: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#1976d2",
                      color: "#ffffff",
                    },
                    width: "25px",
                    height: "25px",
                    transform: `translate(50%, 0)`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #737373",
                  }}
                  onClick={() => setOpenSideBar(!openSideBar)}
                >
                  <LeftArrowIcon className={`${!openSideBar ? "transition-all" : "transition-all rotate-180"}`} />
                </Box>
              </Box>
            </Box>
          )}

          <Box
            component="div"
            sx={{
              width: "1px",
              height: "100%",
              backgroundColor: "#bdc1c6",
              flexShrink: 0,
              position: "relative",
              marginLeft: `${openSideBar ? "0" : "20px"}`,
            }}
          >
            <Box
              component="div"
              sx={{
                position: "absolute",
                top: 20,
                right: 0,
                zIndex: 50,
                borderRadius: "100%",
                color: "#737373",
                backgroundColor: "#ffffff",
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: "#ffffff",
                },
                width: "25px",
                height: "25px",
                transform: `translate(50%, 0)`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #737373",
              }}
              onClick={() => setOpenSideBar(!openSideBar)}
            >
              <LeftArrowIcon
                className={`${openSideBar && !mobileScreen ? "transition-all" : "transition-all rotate-180"}`}
              />
            </Box>
          </Box>
          <Box
            component="div"
            sx={{
              backgroundColor: "#f5f5f5",
              width: "100%",
              paddingX: 2,
            }}
          >
            {childrenWithProps}
          </Box>
          <SampleQuestion currentAssistant="marketing_assistant" />
        </Box>

        <LoadingCircle />
      </Box>
      <WarningSubscription />
      <Box
        component="div"
        sx={{
          height: "30px",
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default MarketingAssistantLayout;
