import { Scrollbars } from "react-custom-scrollbars-2";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState, useEffect, useRef, useMemo } from "react";
import { Box, Tooltip, Stack, Button, TextField } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

import { BodyText } from "src/libs/AppStyle";
import { SelectModel } from "src/libs/models";
import { INewSession } from "src/libs/models/assistant";
import { AddPlusSessionIcon } from "src/assest/icons";
import { EVENTS_SESSION, SUPPLY_CHAIN_ASSISTANT_TOO } from "src/data";
import { addNewSessionByAssistantID, fetchListSessionByAssistantID } from "src/libs/axios/api/chat";
import { findParentObjectByConversationId, renderURLByRoleToolAssistant } from "src/libs/hooks";

import UpdateInSession from "../ListSession/UpdateInSession";
import DeleteIconAssistant from "../Assistant/DeleteIconAssistant";
import UpdateIconAssistant from "../Assistant/UpdateIconAssistant";

interface ListSessionSupplyChainAssistantProps {
  currentProject: SelectModel | null;
  currentSession: INewSession | null;
  setCurrentSession: React.Dispatch<React.SetStateAction<INewSession | null>>;
}

const ListSessionSupplyChainAssistant: React.FC<ListSessionSupplyChainAssistantProps> = ({
  currentProject,
  currentSession,
  setCurrentSession,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const scrollbarsRef = useRef<Scrollbars>(null);
  const sessionWS = useRef<WebSocket | null>(null);

  // state
  const [value, setValue] = useState("");
  const [sessions, setSessions] = useState<INewSession[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const getListSession = async () => {
    const res = await fetchListSessionByAssistantID(
      currentProject?.teamId,
      currentProject?.projectId,
      SUPPLY_CHAIN_ASSISTANT_TOO.ASSISTANT_ID,
    );
    if (res?.length >= 0) {
      setSessions(res);

      const usingSession = findParentObjectByConversationId(res, id);

      if (usingSession) {
        setCurrentSession({ ...usingSession });
      } else {
        setCurrentSession(null);
      }
    } else {
      setSessions([]);
    }
  };

  const handleChangeSession = (value: any) => {
    setCurrentSession({ ...value });

    const URL = renderURLByRoleToolAssistant(location.pathname, value?.agents);
    navigate(URL);
  };

  const handleAddNewSession = async () => {
    setIsLoading(true);
    const res = await addNewSessionByAssistantID(currentProject?.teamId, currentProject?.projectId, {
      id: 0,
      name: "New session",
      topic: "General",
      objective: "",
      content: "",
      assistant_id: SUPPLY_CHAIN_ASSISTANT_TOO.ASSISTANT_ID,
    });
    setIsLoading(false);
    if (res) {
      const URL = renderURLByRoleToolAssistant(location.pathname, res.agents);
      navigate(URL);
    }
  };

  const connectToSessionSocket = () => {
    sessionWS.current = new WebSocket(
      `wss://${process.env.REACT_APP_BASE_DOMAIN_BE}/ws/chat/team/${currentProject?.teamId}/project/${currentProject?.projectId}/assistant/${SUPPLY_CHAIN_ASSISTANT_TOO.ASSISTANT_ID}/`,
    );

    sessionWS.current.onopen = () => {
      console.info("connected");
    };

    sessionWS.current.onmessage = (e) => {
      try {
        const dataWS = JSON.parse(e.data);
        if (EVENTS_SESSION.includes(dataWS?.message?.type)) {
          getListSession();
        }
      } catch (error) {
        console.error(error);
      }
    };
  };

  // useEffect
  useEffect(() => {
    if (currentProject?.projectId) {
      getListSession();
      connectToSessionSocket();
    }
  }, [currentProject?.projectId, id]);

  // memo
  const listSessionFilter = useMemo(() => {
    if (value) {
      return sessions.filter((s) => s.name?.toLowerCase().includes(value.toLowerCase()));
    }
    return sessions;
  }, [value, sessions]);

  return (
    <Box
      component="div"
      p="10px 5px"
      sx={{
        height: "calc(100vh - 150px)",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{
          gap: "8px",
          flexShrink: 0,
          marginBottom: 1,
        }}
        flexShrink={0}
      >
        <Tooltip title="Add new session">
          <Button
            sx={{
              padding: 0,
              minWidth: "auto",
              color: "#737373",
              "&:hover": {
                color: "#1672c9",
                backgroundColor: "transparent",
              },
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? 0.5 : 1,
              transform: "translateX(-2px)",
            }}
            onClick={() => {
              handleAddNewSession();
            }}
          >
            <AddPlusSessionIcon />
            <BodyText
              variant="h3"
              sx={{
                fontWeight: "500 !important",
                marginRight: 1,
                cursor: isLoading ? "not-allowed" : "pointer",
                opacity: isLoading ? 0.5 : 1,
                textTransform: "capitalize",
                marginLeft: 1,
                "&:hover": {
                  color: "#1672c9",
                },
              }}
            >
              New session
            </BodyText>
          </Button>
        </Tooltip>

        {isLoading && (
          <CircularProgress
            color="inherit"
            size="15px"
          />
        )}
      </Stack>
      {sessions?.length >= 20 && (
        <Box
          flexShrink={0}
          marginY={1}
        >
          <TextField
            id="search-session"
            value={value}
            label="Search previous session"
            type="text"
            fullWidth
            margin="normal"
            sx={{
              marginBottom: "0",
              marginTop: "0",
              "& .MuiInputBase-input": {
                padding: "10px 14px",
                fontSize: "14px",
              },
              "& label": {
                fontSize: "12px",
                top: "-5px",
                fontStyle: "italic",
              },
              "& label.Mui-focused": {
                lineHeight: "2.4375em",
                left: "5px",
              },
              "& label.MuiInputLabel-shrink": {
                lineHeight: "2.4375em",
              },
              backgroundColor: "white",
            }}
            name="search-session"
            aria-invalid
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
          />
        </Box>
      )}
      <Box
        component="div"
        sx={{
          flexGrow: 1,
        }}
        className="bottom-shadow-header"
      >
        <Scrollbars
          ref={scrollbarsRef}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          {listSessionFilter?.map((s, index) => (
            <Box
              key={`list-session-${s?.id}-${index}`}
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                backgroundColor: `${s?.id === currentSession?.id && s?.id ? "#d9d9d9" : ""}`,
                padding: "5px",
                borderRadius: "5px",
                transition: "all 0.2s",
                marginRight: "15px",
              }}
            >
              <Box
                component="div"
                sx={{
                  flexGrow: 1,
                  paddingY: "5px",
                }}
                onClick={() => handleChangeSession(s)}
              >
                <BodyText
                  variant="caption"
                  sx={{
                    fontWeight: `${s?.id === currentSession?.id ? "600 !important" : "400 !important"}`,
                    transition: "all 0.2s",
                  }}
                  className="line-clamp-1"
                >
                  {s?.name ? s.name : `New Session ${s?.id}`}
                </BodyText>
              </Box>
              <Stack>
                <Stack
                  flexDirection="row"
                  gap={1}
                >
                  {/* <EditSession
                    sessionId={s?.id}
                    projectId={currentProject?.projectId}
                    teamId={currentProject?.teamId}
                    callback={getListSession}
                  /> */}
                  <UpdateIconAssistant
                    sessionId={s?.id}
                    currentProject={currentProject}
                    callback={getListSession}
                  />
                  <DeleteIconAssistant
                    sessionId={s?.id}
                    projectId={currentProject?.projectId}
                    teamId={currentProject?.teamId}
                    currentSession={currentSession}
                    setCurrentSession={setCurrentSession}
                    callback={getListSession}
                  />
                </Stack>
              </Stack>
            </Box>
          ))}
        </Scrollbars>
      </Box>
      <Box
        component="div"
        sx={{
          flexShrink: 0,
          paddingTop: "15px",
          marginTop: 0,
          height: "120px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <UpdateInSession currentProject={currentProject} />
      </Box>
    </Box>
  );
};

export default ListSessionSupplyChainAssistant;
