import { ChatDocumentIcon, VisualizationIcon, FlowChartIcon, GennerateIcon, FileFromS3Icon, WebsiteLinkIcon, YoutubeLinkIcon, ChatWebIcon } from "src/assest/icons"

import { ISelectModel, RulePassword } from "src/libs/models";

export const rulePassword: RulePassword[] = [
  {
    code: "LENGTH",
    message: "minium 8 characters",
    pattern: /^.{8,}$/,
    init: true,
  },
  {
    code: "NUMBER",
    message: "at least 1 number letter",
    pattern: /\d/,
    init: true,
  },
  {
    code: "LOWERCASE",
    message: "at least 1 lowercase letter",
    pattern: /[a-z]/,
    init: true,
  },
  {
    code: "UPPERCASE",
    message: "at least 1 uppercase letter",
    pattern: /[A-Z]/,
    init: true,
  },
  {
    code: "SPECIALCHAR",
    message: "at least 1 special character",
    pattern: /[!@#$%^&*()]/,
    init: true,
  },
];

export const ROLES: ISelectModel[] = [
  {
    id: "admin",
    name: "admin",
  },
  {
    id: "member",
    name: "member",
  },
];

export const LIST_TOOL_BUSINESS = [
  {
    id: 1,
    icon: ChatDocumentIcon,
    isActivate: true,
    alt: "Chat with documents",
    title: "Chat2Doc",
    hover: "Chat2Doc: Chat with documents in pdf, docx, doc, ppt or csv.",
    route: "chat-document"
  },
  {
    id: 5,
    icon: ChatWebIcon,
    isActivate: false,
    alt: "Chat with websites",
    title: "Chat2Web",
    hover: "Chat2Web: Chat with websites or Youtube videos.",
    route: "chat-web"
  },
  {
    id: 2,
    icon: VisualizationIcon,
    isActivate: false,
    alt: "Data analytics and visualization",
    title: "Analytics",
    hover: "Visualize ideas in flowcharts and diagrams.",
    route: "chat-visualization"
  },
  {
    id: 3,
    icon: FlowChartIcon,
    isActivate: false,
    alt: "Create flowcharts and diagrams",
    title: "Diagrams",
    hover: "Flowchart: Create flowcharts (Process, Mindmap, Sequence Diagram).",
    route: "chat-flow"
  },
  {
    id: 4,
    icon: GennerateIcon,
    isActivate: false,
    alt: "Generate ideas",
    title: "Idea gen",
    hover: "Generate ideas and create content.",
    route: "chat-ideal"
  }
]

export const LIST_TOPIC = [
  {
    id: "General",
    value: "general",
    name: "General"
  },
  {
    id: "Human resource",
    value: "human_resource",
    name: "Human resource"
  },
  {
    id: "Operations",
    value: "operations",
    name: "Operations"
  },
  {
    id: "Marketing & sales",
    value: "marketing_sales",
    name: "Marketing & sales"
  },
  {
    id: "Procurement & supply chain",
    value: "procurement_supply_chain",
    name: "Procurement & supply chain"
  },
  {
    id: "Legal",
    value: "legal",
    name: "Legal"
  },
  {
    id: "Financials",
    value: "financials",
    name: "Financials"
  },
  {
    id: "Business improvement",
    value: "business_improvement",
    name: "Business improvement"
  },
]

export const UploadFileIcons = [
  {
    id: 2,
    icon: FileFromS3Icon,
    title: "From Platform"
  },
  {
    id: 3,
    icon: WebsiteLinkIcon,
    title: "Website"
  },
  {
    id: 4,
    icon: YoutubeLinkIcon,
    title: "Youtube links"
  }
]

export const QUERY_END_POINT = ["stream_query_conversation_document", "visualization_summarize", "visualization_query", "flowchart_query", "query_conversation_idea"]

export const HR_ASSISTANT_TOOL_ID = {
  ASSISTANT_ID: 2,
  CHAT_HIRING_TOOL: 1,
  CHAT_HIRING_ROLE: "hiring",
  TALENT_SELECTION_TOLL: 6,
  TALENT_SELECTION_ROLE: "talent-selection",
  PERFORMANCE_ANALYTICS_TOOL: 7,
  PERFORMANCE_ANALYTICS_ROLE: "analytic",
  INTERACTIVE_LEARNING: 8,
  INTERACTIVE_LEARNING_ROLE: "learning",
}

export const MARKETING_ASSISTANT_TOOL = {
  ASSISTANT_ID: 1,
  MARKETING_INTELLIGENCE_TOOL: {
    id: "2",
    role: "intelligence"
  },
  MARKETING_ANALYTICS_TOOL: {
    id: "4",
    role: "analytic"
  },
  CONTENT_CREATION_TOOL: {
    id: "3",
    role: "content-creation"
  }
}

export const SUPPLY_CHAIN_ASSISTANT_TOO = {
  ASSISTANT_ID: 3,
  VENDOR_ASSESSMENT_TOOL: {
    id: "9",
    role: "vendor"
  },
  CONTRACT_ANALYSIS_TOOL: {
    id: "10",
    role: "contract"
  },
  DATA_ANALYTICS_TOOL: {
    id: "11",
    role: "data-analytic"
  },
  STRATEGY_ASSISTANT_TOOL: {
    id: "12",
    role: "strategy"
  }
}

export const FINANCIAL_ASSISTANT_TOOL = {
  ASSISTANT_ID: 4,
  FINANCIAL_ANALYTICS_TOOL: {
    id: "13",
    role: "analytic"
  },
  ANNUAL_REPORTS_TOOL: {
    id: "14",
    role: "report"
  },
}

export const EVENTS_SESSION = ["EVENT_CREATE_NEW_CHAT", "EVENT_UPDATE_CHAT", "EVENT_RENAME_CHAT", "EVENT_DELETE_CHAT"]