import { INotificationModel } from "src/libs/models";
import api from "../api";

export const getListNotification = async (): Promise<INotificationModel[] | undefined> => {
  try {
    const res = await api.get("/users/notifications/");
    return res.data;
  } catch (error) {
    return undefined;
  }
};

export const markAsReadNotification = async (id: number) => {
  try {
    const res = await api.post("/users/notifications/mark-read", { notification_id: id });
    return res.data;
  } catch (error) {}
};
