import { IUser } from "src/libs/models"
import api from "../api"
import { handleError } from "src/libs/hooks"

export const getMyProfile = async (): Promise<IUser | undefined> => {
  try {
    const res = await api.get("/users/profile")
    return res.data
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const updateProfile = async (payload: IUser) => {
  const { first_name, last_name } = payload
  try {
    const res = await api.post(`/users/profile`, { first_name, last_name })
    return res.data
  } catch (error) {
    console.warn(error)
    handleError(error)
    return undefined
  }
}