import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Stack,
  Tab,
} from "@mui/material";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { IMember, ITeam } from "src/libs/models/team";
import { deleteTeamById, getInvitationsPending } from "src/libs/axios/api/team";
import { StyledTableRow, StyledTableCell } from "src/libs/AppStyle";
import { Modal } from "src/components/common";
import { deleteMembershipByTeamSlug } from "src/libs/axios/api/membership";
import { getTeamSubscription } from "src/libs/axios/api/subscription";
import { deleteInvitationById, resendInvitation } from "src/libs/axios/api/invitations";
import { EditIcon, DeleteIcon, AddPlusIcon, ResendIcon } from "src/assest/icons";
import { normalizeStr } from "src/libs/hooks";
import TeamTemplate from "src/components/Team/TeamTemplate";
import MembershipTemplate from "src/components/Team/MembershipTemplate";
import EditMemberModal from "src/components/Team/EditMemberModal";

const STATUS_INACTIVE = ["Inactive", "Free Trial Expired", "No Credit", "Free Trial"];

const TeamMembersTable = ({ team, userId, callback }: { team: ITeam; userId: number; callback: Function }) => {
  const navigate = useNavigate();

  const body = (
    <Box
      sx={{
        minWidth: "400px",
        padding: "40px",
      }}
    >
      <Typography>Do you want to delete this team?</Typography>
    </Box>
  );

  const bodyDeleteMember = (
    <Box
      sx={{
        minWidth: "400px",
        padding: "40px",
      }}
    >
      <Typography>Do you want to delete this membership from team?</Typography>
    </Box>
  );

  const bodyDeleteInvitation = (
    <Box
      sx={{
        minWidth: "400px",
        padding: "40px",
      }}
    >
      <Typography>Do you want to delete this invitation?</Typography>
    </Box>
  );

  // state
  const [value, setValue] = useState("1");
  const [memberId, setMemberId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [memberTemplate, setMemberTemplate] = useState(false);
  const [openEditMemberModal, setOpenEditMemberModal] = useState(false);
  const [teamSubscription, setTeamSubscription] = useState<any>(null);
  const [listPending, setListPending] = useState([]);
  const [openDeleteMemberModal, setOpenDeleteMemberModal] = useState(false);
  const [invitationPendingId, setInvitationPendingId] = useState<string | null>(null);
  const [deletePendingModal, setDeletePendingModal] = useState(false);

  // function
  const confirmDeleteTeam = async () => {
    const res = await deleteTeamById(team.id);
    if (res) {
      toast.success(res);
      setOpenDeleteModal(false);
      callback();
    }
  };

  const confirmDeleteMember = async () => {
    const res = await deleteMembershipByTeamSlug(memberId, team.id);
    if (res) {
      toast.success(res);
      setOpenDeleteMemberModal(false);
      callback();
    }
  };

  const handleOpenConfirmDeleteTeamModal = () => {
    setOpenDeleteModal(true);
  };

  const handleOpenDeleteMemberModal = (id: number) => {
    setOpenDeleteMemberModal(true);
    setMemberId(id);
  };

  const fetchInvitationsPending = async () => {
    const res = await getInvitationsPending(team.id);
    if (res) {
      setListPending(res);
    }
  };

  const fetchTeamSubscription = async () => {
    const res = await getTeamSubscription(`${team?.id}`);
    setTeamSubscription(res);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setMemberTemplate(false);
  };

  const handleNavigate = (link: string) => {
    if (link === "Subscribed") return;
    navigate(link);
  };

  const handleDeleteInvitation = async () => {
    if (!invitationPendingId) return;
    const res = await deleteInvitationById(invitationPendingId);
    if (res) {
      fetchInvitationsPending();
      setDeletePendingModal(false);
    }
  };

  const handleResendInvitation = async (row: IMember) => {
    const res = await resendInvitation(row);
    if (res) {
      toast.success(res?.detail, {
        position: "bottom-right",
      });
    }
  };

  const teamStatus = useMemo(() => {
    const status = normalizeStr(team?.status);
    let name = "";
    let label = "";
    let link = "";

    if (!status)
      return {
        name,
        label,
        link,
      };
    switch (status) {
      case "free_trial":
        name = "Free Trial Plan";
        label = "Upgrade";
        link = `/subscription/${team.id}/plans`;
        break;

      case "inactive":
        name = "No subscribed plan - Subscribe to start using the platform";
        label = "Subscribe";
        link = `/subscription/${team.id}/plans`;
        break;

      case "subscribed":
        name = teamSubscription?.subscription_plan?.name || "Free Trial Plan";
        label = "Upgrade";
        link = `/subscription/${team.id}/plans`;
        break;

      case "free_trial_expired":
        name = "Free Trial Expired - Upgrade to continue using";
        label = "Upgrade";
        link = `/subscription/${team.id}/plans`;
        break;

      case "no_credit":
        name = `${teamSubscription?.subscription_plan?.name || "Free Trial Plan"} - Exceed Credit limits`;
        label = "Upgrade";
        link = `/subscription/${team.id}/plans`;
        break;

      case "suspened":
        name = `${teamSubscription?.subscription_plan?.name || "Free Trial Plan"} - Invalid payment method`;
        label = "Update Payment Method";
        link = `/payment/${team.id}/method`;
        break;

      default:
        break;
    }

    return {
      name,
      label,
      link,
    };
  }, [team, teamSubscription]);

  const showAddMember = useMemo(() => {
    if (STATUS_INACTIVE.includes(team?.status)) return false;

    return true;
  }, [team]);

  useEffect(() => {
    if (team?.id) {
      fetchInvitationsPending();
      fetchTeamSubscription();
    }
  }, [team]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          marginTop: "8px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="600"
            mr={1}
          >
            Team: {team.name}
          </Typography>

          {team.is_owner && (
            <Stack
              flexDirection="row"
              gap={1}
            >
              <Tooltip title="Edit Team">
                <Button
                  sx={{
                    minWidth: "25px",
                    padding: "0",
                    color: "black",
                  }}
                  onClick={() => {
                    setOpenTeamModal(true);
                  }}
                >
                  <EditIcon
                    width="20"
                    height="20"
                  />
                </Button>
              </Tooltip>
              {!team?.is_default && (
                <Tooltip title="Delete Team">
                  <Button
                    sx={{
                      minWidth: "25px",
                      padding: "0",
                      color: "black",
                    }}
                    onClick={() => handleOpenConfirmDeleteTeamModal()}
                  >
                    <DeleteIcon
                      width="20"
                      height="20"
                    />
                  </Button>
                </Tooltip>
              )}
            </Stack>
          )}
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="600"
            mr={1}
          >
            {teamStatus.name}
          </Typography>
          <Button
            variant="contained"
            sx={{
              minWidth: "auto",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            onClick={() => handleNavigate(teamStatus.link)}
          >
            {teamStatus.label}
          </Button>
        </Box>
      </Box>
      <Box component="div">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="Profile Tab"
            >
              <Tab
                label="In team"
                value="1"
                sx={{
                  textTransform: "capitalize",
                  padding: 0,
                  fontSize: "14px",
                }}
              />
              <Tab
                label="Pending accept"
                value="2"
                sx={{
                  textTransform: "capitalize",
                  padding: 0,
                  fontSize: "14px",
                }}
              />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            sx={{
              paddingX: 0,
              paddingBottom: 0,
            }}
          >
            <TableContainer
              sx={{
                border: "1px solid rgba(224, 224, 224, 1)",
                borderRadius: "4px",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                aria-label="table"
              >
                <TableHead
                  sx={{
                    height: "46px",
                  }}
                >
                  <TableRow>
                    <StyledTableCell
                      align="left"
                      sx={{
                        backgroundColor: "#d1e6fa",
                        fontWeight: "600",
                      }}
                    >
                      Team Members
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        backgroundColor: "#d1e6fa",
                        fontWeight: "600",
                      }}
                    >
                      Role
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        backgroundColor: "#d1e6fa",
                        fontWeight: "600",
                      }}
                    >
                      Email
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        backgroundColor: "#d1e6fa",
                        fontWeight: "600",
                      }}
                    >
                      Status
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        backgroundColor: "#d1e6fa",
                        fontWeight: "600",
                      }}
                    >
                      Actions
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {team.members.map((row: IMember) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell align="left">{row.first_name}</StyledTableCell>
                      <StyledTableCell align="left">{row.role}</StyledTableCell>
                      <StyledTableCell align="left">{row.email}</StyledTableCell>
                      <StyledTableCell align="left">Active</StyledTableCell>
                      <StyledTableCell align="left">
                        {(team.is_admin || team.is_owner) && (
                          <Stack
                            flexDirection="row"
                            gap={1}
                          >
                            <Tooltip title="Edit">
                              <Button
                                sx={{
                                  minWidth: "25px",
                                  padding: "0",
                                  color: "black",
                                }}
                                onClick={() => {
                                  setMemberId(row.id);
                                  setOpenEditMemberModal(true);
                                }}
                              >
                                <EditIcon
                                  width="20"
                                  height="20"
                                />
                              </Button>
                            </Tooltip>
                            {row.role !== "owner" && (
                              <Tooltip title="Delete">
                                <Button
                                  sx={{
                                    minWidth: "25px",
                                    padding: "0",
                                    color: "black",
                                  }}
                                  onClick={() => handleOpenDeleteMemberModal(row.id)}
                                >
                                  <DeleteIcon
                                    width="20"
                                    height="20"
                                  />
                                </Button>
                              </Tooltip>
                            )}
                          </Stack>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

                  {showAddMember && (
                    <StyledTableRow>
                      <StyledTableCell align="left">Add A New Member</StyledTableCell>
                      <StyledTableCell align="left"></StyledTableCell>
                      <StyledTableCell align="left"></StyledTableCell>
                      <StyledTableCell align="left"></StyledTableCell>
                      <StyledTableCell align="left">
                        <Button
                          sx={{
                            minWidth: "25px",
                            padding: "0",
                            color: "#737373",
                            "&:hover": {
                              color: "#1672c9",
                            },
                          }}
                          onClick={() => {
                            setMemberTemplate((prev) => !prev);
                          }}
                        >
                          <AddPlusIcon
                            width="35"
                            height="35"
                          />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              paddingX: 0,
            }}
          >
            <TableContainer
              sx={{
                border: "1px solid rgba(224, 224, 224, 1)",
                borderRadius: "4px",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                aria-label="table"
              >
                <TableHead
                  sx={{
                    height: "46px",
                  }}
                >
                  <TableRow>
                    <StyledTableCell
                      align="left"
                      sx={{
                        backgroundColor: "#d1e6fa",
                        fontWeight: "600",
                      }}
                    >
                      Email
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        backgroundColor: "#d1e6fa",
                        fontWeight: "600",
                      }}
                    >
                      Role
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        backgroundColor: "#d1e6fa",
                        fontWeight: "600",
                      }}
                    >
                      Status
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        backgroundColor: "#d1e6fa",
                        fontWeight: "600",
                      }}
                    >
                      Actions
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listPending &&
                    listPending.map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell align="left">{row?.email}</StyledTableCell>
                        <StyledTableCell align="left">{row?.role}</StyledTableCell>
                        <StyledTableCell align="left">Pending</StyledTableCell>
                        <StyledTableCell align="left">
                          <Tooltip title="Cancel invitations">
                            <Button
                              sx={{
                                minWidth: "25px",
                                padding: "0",
                                color: "black",
                              }}
                              onClick={() => {
                                setDeletePendingModal(true);
                                setInvitationPendingId(row?.id);
                              }}
                            >
                              <DeleteIcon
                                width="20"
                                height="20"
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Resend invitations">
                            <Button
                              sx={{
                                minWidth: "25px",
                                padding: "0",
                                color: "black",
                              }}
                              onClick={() => {
                                handleResendInvitation(row);
                              }}
                            >
                              <ResendIcon
                                width="20"
                                height="20"
                              />
                            </Button>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabContext>
      </Box>
      {memberTemplate && (
        <MembershipTemplate
          teamId={team.id}
          userId={userId}
          onClick={setMemberTemplate}
          callback={callback}
        />
      )}
      <Modal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        body={body}
        onClick={confirmDeleteTeam}
      />
      <Modal
        open={openDeleteMemberModal}
        setOpen={setOpenDeleteMemberModal}
        body={bodyDeleteMember}
        onClick={confirmDeleteMember}
      />
      <Modal
        open={deletePendingModal}
        setOpen={setDeletePendingModal}
        body={bodyDeleteInvitation}
        onClick={handleDeleteInvitation}
      />
      <TeamTemplate
        open={openTeamModal}
        setOpen={setOpenTeamModal}
        teamId={team.id}
        callback={callback}
      />
      <EditMemberModal
        open={openEditMemberModal}
        memberId={memberId}
        teamId={team.id}
        setOpen={setOpenEditMemberModal}
        callback={callback}
      />
    </Box>
  );
};

export default TeamMembersTable;
